import React, { useState, useEffect } from 'react';
import { Modal, Form } from 'antd';
import PropTypes from 'prop-types';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import Button from 'components/Button';
import HTMLFormatter from '../../components/HTMLComponent/HTMLFormatter';
import { scopeStylesInHTML } from 'utils/html';

import './HtmlEditModal.scss';

const HtmlEditModal = ({ htmlEdit, visible, onCancel, onConfirm, savingRichEditorModal }) => {
  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const [htmlValidationError, setHtmlValidationError] = useState('');
  const [htmlCode, setHtmlCode] = useState(htmlEdit?.v || '');
  const [isValidHtml, setIsValidHtml] = useState(true);

  const [form] = Form.useForm();

  const checkHTML = function (html) {
    var doc = document.createElement('div');
    doc.innerHTML = html;
    return doc.innerHTML;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!isValidHtml) {
          setHtmlValidationError('Invalid HTML');
          return;
        }
        const html = scopeStylesInHTML(values.htmlCode, '.section-wrapper');
        onConfirm(html);
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Modal
        wrapClassName="simple-info-modal add-html-modal"
        visible={showPropModal}
        closeIcon={<CloseIcon className="close-icon" />}
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={!savingRichEditorModal}
        footer={[
          <Button key="cancel" text="Cancel" className="large-btn grey-btn" onClick={onCancel} />,
          <Button
            key="confirm"
            className="large-btn secondary-btn"
            text={savingRichEditorModal ? 'SAVING' : htmlEdit?.save ? 'SAVE HTML' : 'ADD HTML'}
            onClick={handleSubmit}
            disabled={!isValidHtml || savingRichEditorModal}
            loading={savingRichEditorModal}
          />,
        ]}>
        <h3 className="title">Add Your HTML</h3>
        <div className="divider" />
        <div className="body">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              htmlCode: htmlCode,
            }}>
            <Form.Item name="htmlCode">
              <HTMLFormatter
                htmlCode={htmlCode}
                onChange={(value) => {
                  setHtmlValidationError('');
                  setHtmlCode(value);
                  setIsValidHtml(checkHTML(value));
                }}
                onSyntaxCheck={(isValid) => setIsValidHtml(isValid)}
              />
            </Form.Item>
            {htmlValidationError && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">{htmlValidationError}</div>
              </div>
            )}
          </Form>
        </div>
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
            }}
            onConfirm={onCancel}
            footer={null}
          />
        )}
      </>
    </>
  );
};

HtmlEditModal.defaultProps = {
  visible: false,
  savingRichEditorModal: false,
};

HtmlEditModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
  htmlEdit: PropTypes.instanceOf(Object).isRequired,
};

export default HtmlEditModal;
