import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Modal, Button, Upload, message, Progress, Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

import { resizeImageForS3 } from 'utils/uploadToS3';
import Popconfirm from 'components/PopConfirm';
import commonUtils from 'utils/utils';
import Uploader from 'components/Uploader';
import UploadCloudIcon from 'components/Icons/UploadCloudIcon';
import ArrowTop from 'components/Icons/ArrowTop';
import CloseIcon from 'components/Icons/CloseIcon';
import helpers from 'helpers';

import './UploadProposal.scss';

const { Dragger } = Upload;

const UploadProposal = ({ onCancelUpload, showUploadModal, onSuccessUpload }) => {
  const PDFJS = window['pdfjs-dist/build/pdf'];
  /* eslint-disable prefer-destructuring */
  const mammoth = window['mammoth'];

  const htmlNode = document.createElement('div');
  htmlNode.setAttribute('id', 'html-node');
  let pdfDocumentRendering = '';

  const canvas = document.createElement('canvas'),
    ctx = canvas.getContext('2d'),
    pages = useRef([]),
    uploadStopped = useRef(false),
    uploadPaused = useRef(false),
    tempPdf = useRef(''),
    currentPage = useRef(1),
    proposalFileType = useRef('');

  const [upload, uploadStatus] = Uploader();

  const [docHtmlData, setDocHtmlData] = useState('');
  const [dataImages, setDataImages] = useState([]);
  const [countUploadPages, setCountUploadPages] = useState(0);
  const [uploadPage, setUploadPage] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [docxFileUploaded, setDocxFileUploaded] = useState(0);

  const handleKeyCommand = useCallback(
    (e) => {
      if (e.key === 'Escape') {
        const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
        if (isNotCombinedKey && showUploadModal) {
          uploadPaused.current = true;
          setShowCancelModal(true);
        }
      }
    },
    [showUploadModal]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyCommand, true);
    return () => window.removeEventListener('keydown', handleKeyCommand);
  }, [handleKeyCommand]);

  useEffect(() => {
    if (proposalFileType.current === 'pdf' && dataImages.length > 0 && !uploadPaused.current) {
      setUploadPage(uploadPage + 1);
      onUpload(dataImages, true);
      pages.current = [];
    }
    // eslint-disable-next-line
  }, [dataImages, showCancelModal, uploadPaused.current]);

  useEffect(() => {
    if (proposalFileType.current === 'docx' && docHtmlData) {
      onSuccessUpload({ docHtmlData, proposalFileType: proposalFileType.current });
      setDocHtmlData('');
      setUploadProgress(false);
    }
  }, [docHtmlData, onSuccessUpload, proposalFileType]);

  const onCancelUploadProposalModal = useCallback(() => {
    setSelectedFile('');
    setDataImages([]);
    setUploadProgress(false);
    setShowCancelModal(false);
    onCancelUpload();
    setUploadPage(0);
    proposalFileType.current = '';
    uploadPaused.current = false;
    tempPdf.current = '';
    uploadStopped.current = false;
    currentPage.current = 1;
    pages.current = [];
  }, [onCancelUpload]);

  const onUpload = useCallback(
    async (files, isConverted) => {
      const size = { width: 965 * 2, height: 1150 * 2 };
      let filteredAndResizedFiles;
      try {
        filteredAndResizedFiles = await Promise.all(
          files.flatMap(async (filex) => {
            let file;
            if (isConverted) {
              file = dataURItoFile(filex, 'proposal.jpeg');
            } else {
              file = filex;
            }
            return await resizeImageForS3({ file, size, path: 'externalproposals' });
          })
        );
      } catch (e) {
        return message.error(e.message);
      }

      if (!filteredAndResizedFiles.length) return;
      const urls = await upload(filteredAndResizedFiles, 'externalproposals');

      if (onSuccessUpload) {
        onSuccessUpload({ urls, proposalFileType: proposalFileType.current });
        onCancelUploadProposalModal(false);
      }
    },
    [onCancelUploadProposalModal, onSuccessUpload, upload, proposalFileType]
  );

  const dataURItoFile = (fileUrl, filename) => {
    const splitedFile = fileUrl.split(','),
      mimeType = splitedFile[0].match(/:(.*?);/)[1],
      fileBuffer = atob(splitedFile[1]);
    let fileBufferLength = fileBuffer.length,
      fileUnit8Array = new Uint8Array(fileBufferLength);

    while (fileBufferLength--) {
      fileUnit8Array[fileBufferLength] = fileBuffer.charCodeAt(fileBufferLength);
    }
    return new File([fileUnit8Array], filename, { type: mimeType });
  };

  const handlePDFConverter = (pdfFile) => {
    const pdfUrl = URL.createObjectURL(pdfFile);
    pdfDocumentRendering = PDFJS.getDocument(pdfUrl);
    pdfDocumentRendering.promise.then(iterate);
  };

  const getPage = useCallback(
    (pdf) => {
      if (uploadPaused.current) {
        return (tempPdf.current = pdf);
      } else if (!uploadPaused.current && tempPdf.current) {
        tempPdf.current = '';
      }

      pdf.getPage(currentPage.current).then((page) => {
        if (uploadStopped.current) {
          setUploadProgress(false);
          setCountUploadPages(0);
          proposalFileType.current = '';
          uploadStopped.current = false;
          pdfDocumentRendering.destroy();
          return;
        }

        const scale = 5.0;
        const viewport = page.getViewport({ scale: scale });

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        canvas.style.width = '100%';
        canvas.style.height = '100%';

        if (window.devicePixelRatio > 1) {
          const canvasWidth = canvas.width;
          const canvasHeight = canvas.height;

          canvas.width = canvasWidth * window.devicePixelRatio;
          canvas.height = canvasHeight * window.devicePixelRatio;
          canvas.style.width = canvasWidth + 'px';
          canvas.style.height = canvasHeight + 'px';
          canvas.style.fontSize = '12px';

          ctx.scale(window.devicePixelRatio, window.devicePixelRatio);
        }

        const renderContext = {
          canvasContext: ctx,
          viewport: viewport,
        };

        page.render(renderContext).promise.then(() => {
          pages.current.push(canvas.toDataURL('image/jpeg'));
          if (currentPage.current < pdf.numPages) {
            setUploadPage(currentPage.current);
            currentPage.current = currentPage.current + 1;
            getPage(pdf);
          } else {
            setDataImages(pages.current);
          }
        });
      });
    },
    [canvas, ctx, currentPage, pdfDocumentRendering]
  );

  const iterate = (pdf) => {
    if (pdf.numPages > 30) {
      message.error('File page count should not be more than 30 pages');
      return;
    }

    setUploadProgress(true);
    setCountUploadPages(pdf.numPages);

    if (currentPage.current <= pdf.numPages) {
      getPage(pdf);
    }
  };

  const cancelDocxUpload = () => {
    setUploadProgress(false);
    setDocxFileUploaded(0);
    proposalFileType.current = '';
    uploadStopped.current = false;
  };

  const handleWordDocxConverter = (docxFile) => {
    const reader = new FileReader();

    setUploadProgress(true);
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      mammoth
        .convertToHtml(
          { arrayBuffer: arrayBuffer },
          {
            convertImage: mammoth.images.inline((element) => {
              return element.read('base64').then(async (imageBuffer) => {
                setDocxFileUploaded((value) => value + 1);
                if (uploadStopped.current || !proposalFileType.current) {
                  return cancelDocxUpload();
                }
                const imageDataURI = `data:image/jpeg;base64,${imageBuffer}`;
                const imageType = element.contentType.split('/').pop();
                const { url } = await upload(
                  dataURItoFile(imageDataURI, `image.${imageType}`),
                  `externalproposals`
                );
                return {
                  src: url,
                };
              });
            }),
          }
        )
        .then((result) => {
          if (uploadStopped.current || !proposalFileType.current) {
            return cancelDocxUpload();
          }
          htmlNode.innerHTML = result.value;
          setDocHtmlData(htmlNode.children || []);
        });
    };
    reader.readAsArrayBuffer(docxFile);
  };

  const handleUpload = () => {
    const fileExtension = commonUtils.getFileExtension(selectedFile?.name).toLowerCase();
    proposalFileType.current = fileExtension;
    if (fileExtension === 'pdf') {
      handlePDFConverter(selectedFile);
    } else if (fileExtension === 'docx') {
      handleWordDocxConverter(selectedFile);
    } else {
      onUpload([selectedFile], false);
    }
  };

  const onCancelModal = () => {
    if (uploadPaused.current) {
      uploadPaused.current = false;
      if (tempPdf.current) {
        getPage(tempPdf.current);
      }
    }
    setShowCancelModal(false);
  };

  const draggerProps = {
    multiple: false,
    onRemove: () => {
      setSelectedFile('');
    },
    beforeUpload: (file) => {
      const fileSizeLimit = file.size / 1024 / 1024 < 10;
      const allowedFileExtension = ['pdf', 'png', 'jpeg', 'jpg', 'docx'];
      if (!fileSizeLimit) {
        message.error('The file is too large. You can upload proposal up to 10MB.');
      } else if (
        !allowedFileExtension.includes(commonUtils.getFileExtension(file.name).toLowerCase())
      ) {
        message.error(
          'File not supported. Supported extensions - .pdf, .docx, .png, .jpeg and .jpg'
        );
      } else {
        setSelectedFile(file);
      }
      return false;
    },
    showUploadList: true,
    fileList: selectedFile ? [selectedFile] : [],
  };

  return (
    <>
      <Helmet>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.3.200/pdf.js" defer />
        <script src="https://cdn.jsdelivr.net/npm/mammoth@1.4.8/mammoth.browser.min.js" defer />
      </Helmet>
      <Modal
        className="confirm-modal upload-proposal-item-modal"
        footer={null}
        visible={showUploadModal}
        onCancel={() => (!selectedFile ? setShowCancelModal(true) : onCancelUploadProposalModal)}
        width={720}
        closeIcon={
          uploadStatus.status === 'uploading' || uploadProgress ? null : (
            <CloseIcon className="close-icon" onClick={onCancelUploadProposalModal} />
          )
        }
        closable={uploadStatus.status === 'uploading' || uploadProgress ? false : true}
        centered>
        {uploadStatus.status === 'uploading' || uploadProgress ? (
          proposalFileType.current === 'pdf' ? (
            proposalFileType.current === 'pdf' && countUploadPages === 0 ? (
              <h3 style={{ fontSize: '24px', fontWight: 600, textAlign: 'center' }}>
                Converting Pages
              </h3>
            ) : (
              <>
                <h3 style={{ fontSize: '24px', fontWight: 600, textAlign: 'center' }}>
                  {uploadStatus.status === 'uploading'
                    ? `Uploading ${uploadStatus.percent}%`
                    : `Importing ${
                        uploadPage === 0 ? 1 : uploadPage
                      } out of ${countUploadPages} Page${countUploadPages > 1 ? 's' : ''}`}
                </h3>
                <Progress
                  percent={
                    uploadStatus.status === 'uploading'
                      ? uploadStatus.percent
                      : ((uploadPage === 0 ? 1 : uploadPage) / countUploadPages) * 100
                  }
                  status="active"
                  showInfo={false}
                  strokeWidth={3}
                  strokeColor="#F03F3B"
                />
              </>
            )
          ) : (
            <>
              <h3 style={{ fontSize: '24px', fontWight: 600, textAlign: 'center' }}>
                Uploading{' '}
                {uploadStatus.status === 'uploading' && proposalFileType.current === 'docx'
                  ? `(${helpers.getNumberWithOrdinal(docxFileUploaded)} Docx Image)`
                  : ''}{' '}
                {proposalFileType.current !== 'docx' ? `${uploadStatus.percent}%` : ''}
              </h3>
              <Progress
                percent={uploadStatus.percent}
                status="active"
                showInfo={false}
                strokeWidth={3}
                strokeColor="#F03F3B"
              />
            </>
          )
        ) : (
          !uploadProgress && (
            <div className="select-image">
              <h3 className="title">UPLOAD YOUR PROPOSAL</h3>
              <Dragger
                accept={'image/x-png, image/png, image/jpeg, .jpg, .jpeg, .png, .pdf, .docx'}
                {...draggerProps}>
                <Col className="select-image-uploader">
                  <UploadCloudIcon />
                  <p className="select-image-title">
                    Recommended Proposal type should be 900px width
                  </p>
                  <p className="select-image-info">
                    Drag &amp; drop your file or upload from your computer
                  </p>
                  <p className="select-image-details">
                    Supported extensions - .pdf, .docx, .png, .jpeg and .jpg
                  </p>
                  <p className="select-image-details">Maximum 10MB</p>
                  <Row className="select-image-upload-text">
                    <ArrowTop className="select-image-upload-icon" />
                    <h3>UPLOAD PROPOSAL</h3>
                  </Row>
                </Col>
              </Dragger>

              <div className="button-wrapper">
                <Button
                  key="back"
                  className={!selectedFile ? 'button confirm fade' : 'button confirm'}
                  type="primary"
                  onClick={handleUpload}
                  loading={uploadStatus.status === 'uploading'}
                  disabled={!selectedFile}>
                  {uploadStatus.status === 'uploading' ? 'Uploading' : 'Start Upload'}
                </Button>
                <Button className="button cancel" onClick={onCancelUploadProposalModal}>
                  CANCEL
                </Button>
              </div>
            </div>
          )
        )}
      </Modal>

      {showCancelModal && selectedFile && (
        <Popconfirm
          visible={showCancelModal}
          title={'Do you want to exit?'}
          closable={false}
          cancelText={'Continue'}
          confirmText={'Yes, Exit'}
          onCancel={onCancelModal}
          onConfirm={onCancelUploadProposalModal}
          footer={null}
        />
      )}
    </>
  );
};

UploadProposal.defaultProps = {
  showUploadModal: false,
};

UploadProposal.propTypes = {
  onCancelUpload: PropTypes.func.isRequired,
  showUploadModal: PropTypes.bool,
  onSuccessUpload: PropTypes.func.isRequired,
};

export default UploadProposal;
