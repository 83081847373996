import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input } from 'antd';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import Button from 'components/Button';

import './VideoModal.scss';

const VideoModal = ({
  videoOembedError,
  setVideoOembedError,
  visible,
  onCancel,
  onConfirm,
  savingRichEditorModal,
}) => {
  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const [form] = Form.useForm();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onConfirm(values);
        form.resetFields();
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  return (
    <>
      <Modal
        wrapClassName="simple-info-modal add-video-modal"
        visible={showPropModal}
        centered
        closeIcon={<CloseIcon className="close-icon" />}
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={!savingRichEditorModal}
        footer={[
          <Button key="cancel" text="Cancel" className="button cancel" onClick={onCancel} />,
          <Button
            key="confirm"
            className="button confirm"
            text={savingRichEditorModal ? 'SAVING' : 'ADD Video'}
            type="primary"
            onClick={handleSubmit}
            disabled={savingRichEditorModal}
            loading={savingRichEditorModal}
          />,
        ]}>
        <h3 className="title">Add Video Link</h3>
        <div className="divider" />
        <div className="body">
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{
              modifier: 'public',
            }}>
            <Form.Item name="videoLink" label="Add video link here">
              <Input onChange={() => setVideoOembedError('')} />
            </Form.Item>
            {videoOembedError && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                <div role="alert">Please insert a valid URL</div>
              </div>
            )}
          </Form>
        </div>
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
            }}
            onConfirm={onCancel}
            footer={null}
          />
        )}
      </>
    </>
  );
};

VideoModal.defaultProps = {
  visible: false,
  videoOembedError: '',
  savingRichEditorModal: false,
};

VideoModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  videoOembedError: PropTypes.string,
  setVideoOembedError: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
};

export default VideoModal;
