import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row, Button } from 'antd';

import {
  AddIcon,
  TeamActivityLog,
  TeamRoleIcon,
  TeamDashboard,
  TeamMembersIcon,
  TadaIcon,
} from 'components/Icons';

import './EmptyUser.scss';

const EmptyUser = ({ addOreditUser, planType }) => {
  return (
    <Card className="empty-user">
      <Row justify="left" className="invite-row">
        <Col className="invite-image">
          <TeamMembersIcon />
        </Col>
        <Col className="invite-col">
          <h3>
            Invite Your Team to the Party <TadaIcon />
          </h3>
          <p>
            Close even more deals with them for only $
            {planType === 'new' ? 10 : planType === '2025' ? 19 : 8} a month.
          </p>
          <Button
            className="large-btn secondary-btn icon-right"
            onClick={() => addOreditUser({ actionType: 'add' })}
            icon={<AddIcon />}>
            ADD NEW USER
          </Button>
        </Col>
      </Row>
      <Row justify="center" className="builtin-row">
        <Col className="builtin-col">
          <h4>BUILT-IN FOR TEAMWORK </h4>
          <h3>Team features for success</h3>
        </Col>
      </Row>

      <Row justify="center" className="features">
        <Col lg={8}>
          <TeamActivityLog />
          <h4 className="sub-header">Activity Log</h4>
          <p className="sub-header-value">
            Easily keep track of your team's proposal related activity, keep tabs on how they are
            doing with the proposals.
          </p>
        </Col>
        <Col lg={8}>
          <TeamRoleIcon />
          <h4 className="sub-header">Permissions and Roles</h4>
          <p className="sub-header-value">
            Assign different roles to members of your team to manage, control and collaborate
            effortlessly within your team.
          </p>
        </Col>
        <Col lg={8}>
          <TeamDashboard />
          <h4 className="sub-header">Individualized Dashboard</h4>
          <p className="sub-header-value">
            Each members getting their own content library, dashboard &amp; credentials, no need to
            share password or compromise security.
          </p>
        </Col>
      </Row>
    </Card>
  );
};

EmptyUser.propTypes = {
  addOreditUser: PropTypes.func.isRequired,
  planType: PropTypes.string,
};

export default EmptyUser;
