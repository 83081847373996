import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';
import Path from 'routes/path';

import CloseIcon from 'components/Icons/CloseIcon';
import './SimpleModalInfo.scss';
import { useProposalContext } from 'context/proposal';

const ApprovedProposalModal = ({
  approvedDateValue,
  wixEditor,
  templateWixEditor,
  openPublishView,
}) => {
  const history = useHistory();
  const { downloadPdf } = useProposalContext();
  return (
    <Modal
      className="simple-info-modal"
      centered
      visible
      footer={null}
      maskClosable={false}
      onCancel={() =>
        wixEditor || templateWixEditor
          ? window?.proposalApi?.navigateToProposals()
          : history.push(Path.DASHBOARD)
      }
      closeIcon={<CloseIcon className="close-icon" />}>
      <h3 className="title">Approved Proposals are Sealed</h3>
      <div className="divider" />

      <p className="body">
        This proposal was approved {approvedDateValue} and cannot be edited. You can download a PDF
        copy or preview it
      </p>

      <div className="button-wrapper">
        <Button className="large-btn secondary-btn" onClick={downloadPdf}>
          PDF
        </Button>
        <Button className="large-btn grey-btn" onClick={() => openPublishView(true)}>
          PREVIEW
        </Button>
        <Button
          className="large-btn grey-btn"
          onClick={() =>
            wixEditor || templateWixEditor
              ? window?.proposalApi?.navigateToProposals()
              : history.push(Path.DASHBOARD)
          }>
          BACK
        </Button>
      </div>
    </Modal>
  );
};

ApprovedProposalModal.propTypes = {
  approvedDateValue: PropTypes.string.isRequired,
  openPublishView: PropTypes.func,
  wixEditor: PropTypes.bool,
  templateWixEditor: PropTypes.bool,
};

export default ApprovedProposalModal;
