import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

import CircleTickIcon from 'components/Icons/CircleTickIcon';

const PaymentStatusModal = ({ paymentStatus, onCancel }) => {
  return (
    <Modal
      className="simple-info-modal payment-status-modal"
      centered
      visible
      footer={null}
      closeIcon={null}
      onCancel={onCancel}>
      {paymentStatus === 'success' ? <CircleTickIcon /> : null}
      <h3 className="title">
        Proposal Payment {paymentStatus === 'success' ? 'Successfull' : 'Failed'}
      </h3>
      <div className="divider" />

      {/* <p className="error">Your payment didnt go through, please double-check your details and try again.</p>
      <p className="body">Amount: {amount}</p> */}

      <div className="button-wrapper">
        <Button className="button cancel" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

PaymentStatusModal.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default PaymentStatusModal;
