import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import useWindowDimensions from 'hooks/useWindowDimensions';

const SMALLER_WIDTH = 992;

function ToolbarTooltipItem({ title, className, children }) {
  const { width } = useWindowDimensions();

  return width < SMALLER_WIDTH ? (
    children
  ) : (
    <Tooltip placement="top" title={title} className={className}>
      {children}
    </Tooltip>
  );
}

ToolbarTooltipItem.defaultProps = {
  title: '',
  className: '',
  currentStyle: {},
};

ToolbarTooltipItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ToolbarTooltipItem;
