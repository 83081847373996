import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg
    width="13"
    height="20.5"
    viewBox="0 0 13 20.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.1,0L1.9,0C0.8,0,0,0.9,0,1.9v16.7c0,1,0.8,1.9,1.9,1.9h9.3c1,0,1.9-0.8,1.9-1.9V1.9C13,0.9,12.2,0,11.1,0zM11.1,16.8H1.9v-13h9.3V16.8z"
      fill="#05034D"
    />
  </svg>
);

const MobileIcon = (props) => <Icon component={svg} {...props} />;

export default MobileIcon;
