import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { useMutation } from '@apollo/client';
import { Button, Row, Select, Col, Modal, Checkbox } from 'antd';

import { CREATE_XERO_AUTH_URL, DISCONNECT_XERO } from 'graphql/mutations/integrationMutation';
import { userVar } from 'graphql/cache';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';

const { Option } = Select;

const XeroIntegration = ({ data, isModalItem, userRole, refetchUser, user }) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [loading, setLoading] = useState();
  const [organisations, setOrganisations] = useState([]);
  const [removeOrg, setRemoveOrg] = useState([]);
  const [removeLoader, setRemoveLoader] = useState(false);

  const xeroConnectionStatus = utils.getQueryStringValue('xero');

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
      setOrganisations(data?.organisations || []);
    }
  }, [data]);

  useEffect(() => {
    async function checkUser() {
      if (
        xeroConnectionStatus === 'connected' &&
        (!user?.xero || Object.keys(user?.xero).length === 0)
      ) {
        const userData = await refetchUser();
        if (userData) {
          userVar({ ...user, ...userData.data.fetchUser });
          setIntegrationData(userData.data.fetchUser.xero);
          setLoading(Object.keys(userData?.data?.fetchUser?.xero).length ? 'connected' : '');
        } else {
          setIntegrationData(user.xero);
          setLoading(Object.keys(user.xero).length ? 'connected' : '');
        }
      } else if (xeroConnectionStatus === 'failed') {
        setLoading('');
      }
    }
    checkUser();
  }, [xeroConnectionStatus, refetchUser, user, data]);

  const [createXeroAuthUrl] = useMutation(CREATE_XERO_AUTH_URL, {
    onCompleted: async (data) => {
      const authData = data.createXeroAuthUrl;
      setLoading('connecting');
      await utils.refetchUserOnClosingTab(authData.url, authData.tab);
      const userData = await refetchUser();
      if (userData) {
        if (userData._id) {
          userVar({ ...user, ...userData });
          setIntegrationData(userData.xero);
          setLoading(Object.keys(userData?.xero).length ? 'connected' : '');
        } else {
          userVar({ ...user, ...userData.data.fetchUser });
          setIntegrationData(userData.data.fetchUser.xero);
          setLoading(Object.keys(userData?.data?.fetchUser?.xero).length ? 'connected' : '');
        }
      } else {
        setIntegrationData(user.xero);
        setLoading(Object.keys(user.xero).length ? 'connected' : '');
      }
    },
    onError: () => {
      notification.error({
        message: 'Xero Create Auth URL Failed',
        description: 'Something went wrong',
      });
      setLoading('');
    },
  });

  const handleConnect = () => {
    const height = window.outerHeight;
    const width = window.outerWidth;

    createXeroAuthUrl({
      variables: {
        height,
        width,
      },
    });
  };

  const handleClick = () => {
    if (!integrationData || !Object.keys(integrationData).length) {
      setLoading('connecting');
      return handleConnect();
    }
    setShowDisconnectModal(true);
    setLoading('disconnecting');
  };

  const [disconnectXero] = useMutation(DISCONNECT_XERO, {
    onCompleted: (disconnectedOrgs) => {
      refetchUser();
      setShowDisconnectModal(false);
      setRemoveLoader(false);
      setLoading('');
      if (disconnectedOrgs.disconnectXero.disconnectedOrgs.length === 1) {
        notification.success({
          message: 'Organisation Disconnected',
          description: (
            <div>
              Your Xero organization <b>{disconnectedOrgs.disconnectXero.disconnectedOrgs[0]}</b> is
              now disconnected from Prospero.
            </div>
          ),
          className: 'xero-disconnect-success',
          style: {
            b: {
              color: '#1890ff',
            },
          },
        });
      } else {
        notification.success({
          message: 'Organisation Disconnected',
          description: 'Your Xero organizations are now disconnected from Prospero.',
        });
      }
    },
    onError: () => {
      setRemoveLoader(false);
      notification.error({
        message: 'Xero Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  const connectMoreOrganisations = (instance) => {
    if (instance === 'connect-new-xero-account') {
      handleConnect();
    }
  };

  const logoSubtitle = () => {
    return organisations?.length ? (
      <Select
        value={organisations[0]?.tenantName}
        suffixIcon={<DropdownIcon />}
        onChange={connectMoreOrganisations}
        dropdownClassName="wix-connect-select-dropdown">
        {organisations?.map((organisation, index) => (
          <Option
            key={index}
            value={organisation.instanceId}
            className="item-brand-name-select-option">
            <Row className="item-select-row">
              <Col>
                <span className="item-name"> {organisation.tenantName}</span>
              </Col>
              <Col>
                <CircleTickIcon />
              </Col>
            </Row>
          </Option>
        ))}

        <Option
          key="connect-new-xero-account"
          value="connect-new-xero-account"
          className="item-brand-name-select-option-new">
          <Row className="item-select-row">
            <Button className="primary">Add More</Button>
          </Row>
        </Option>
      </Select>
    ) : (
      ''
    );
  };

  const handleDisconnectModalClick = (type) => {
    if (type === 'remove') {
      if (removeOrg.length) {
        setRemoveLoader(true);
        setLoading('disconnecting');
        disconnectXero({
          variables: {
            connectionIds: removeOrg,
          },
        });
      } else {
        setShowDisconnectModal(false);
      }
    } else {
      setRemoveOrg([]);
      setShowDisconnectModal(false);
    }
  };

  const containsOrg = (orgId) => {
    return removeOrg.find((org) => org.id === orgId);
  };

  return (
    <>
      <IntegrationItem
        type="xero"
        name="xero"
        description="Import contacts & generate invoices with a click"
        integrationLink="https://support.goprospero.com/article/show/148253-how-to-integrate-xero-with-prospero"
        status={
          loading || // this can be connecting or disconnecting
          (integrationData && Object.keys(integrationData).length ? 'connected' : 'disconnected')
        }
        image={images.XERO_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        userRole={userRole}
        logoSubtitle={logoSubtitle()}
      />
      {showDisconnectModal && organisations.length && (
        <Modal
          zIndex={1009}
          className={'confirm-modal wix-disconnect-modal'}
          centered
          visible={showDisconnectModal}
          onCancel={() => setShowDisconnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <div className="title">Select Organisations to Remove</div>

          <div className="divider" />

          <div className="body">
            <Checkbox.Group style={{ width: '100%' }} onChange={setRemoveOrg}>
              <Row>
                {organisations?.map((organisation, orgIndex) => (
                  <Col span={16} key={organisation.id + orgIndex}>
                    <Checkbox checked={containsOrg(organisation.id)} value={organisation.id}>
                      {organisation.tenantName}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>

          <div className="button-wrapper">
            <Button
              className="button confirm"
              type="primary"
              onClick={() => handleDisconnectModalClick('remove')}
              loading={removeLoader}
              disabled={!removeOrg.length}>
              REMOVE
            </Button>
            <Button className="button cancel" onClick={() => handleDisconnectModalClick('cancel')}>
              CANCEL
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

XeroIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  userRole: '',
  refetchUser: () => {},
};

XeroIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  userRole: PropTypes.string,
  refetchUser: PropTypes.func,
};

export default XeroIntegration;
