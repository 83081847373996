import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import ResetIcon from 'components/Icons/ResetIcon';

import './ResetItem.scss';

const ResetItem = ({ onReset }) => (
  <div className="reset-item">
    <Button className="large-btn grey-btn" onClick={onReset} icon={<ResetIcon />}>
      Reset
    </Button>
  </div>
);

ResetItem.defaultProps = {
  onReset: () => {},
};

ResetItem.propTypes = {
  onReset: PropTypes.func,
};

export default ResetItem;
