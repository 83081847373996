import { gql } from '@apollo/client';

export const GET_TREEVIEW_DATA = gql`
  query ($type: String!) {
    fetchContentLibrary(type: $type) {
      _id
      name
      uid
      children {
        title
        uid
        children {
          title
          uid
          children {
            title
            uid
            children {
              title
              uid
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_COVERS_LIBRARIES = gql`
  query ($type: String!, $key: String) {
    fetchContentLibraryCoversItems(type: $type, key: $key) {
      _id
      name
      img
      alt
      uploadedBy
      starred
      uid
      auid
      type
      createdAt
    }
  }
`;

export const GET_ALL_MEDIAS_LIBRARIES = gql`
  query ($key: String, $allStarred: Boolean) {
    fetchContentLibraryMediasItems(key: $key, allStarred: $allStarred) {
      _id
      name
      src
      uploadedBy
      starred
      uid
      auid
      createdAt
      type
    }
  }
`;

export const GET_ALL_SECTIONS_LIBRARIES = gql`
  query ($key: String, $limit: Float, $offset: Float, $search: String) {
    fetchContentLibrarySectionsItems(key: $key, limit: $limit, offset: $offset, search: $search) {
      sections {
        _id
        uid
        auid
        raw
        raw1
        raw2
        raw3
        rawtitle
        subrawtitle
        title
        text
        subtitle
        starred
        thumbnail
        libraryTitle
        createdAt
        language
        image
        numberOfColumns
        typeOfColumns
        titlePosition
        spacing
        isHeader
        contact
        rawby
        rawcontact
        rawemail
        rawname
        rawsubtitle
        titleStyle
        clientname
        originalState
        hidecover
        headerConfig
        originalState
        cover
        logo
        by
        email
        name
        color
        sectionStyles
      }
      sectionsCount
    }
  }
`;

export const GET_ALL_TEMPLATES_LIBRARIES = gql`
  query (
    $key: String
    $limit: Float
    $offset: Float
    $search: String
    $filterItem: String
    $sort: String
  ) {
    fetchContentLibraryTemplatesItems(
      key: $key
      limit: $limit
      offset: $offset
      search: $search
      filterItem: $filterItem
      sort: $sort
    ) {
      templates {
        _id
        uid
        auid
        pid
        templateUrl
        templateName
        titletext
        starred
        language
        client {
          name
        }
        project {
          name
        }
        draft
        date
        isWixTemplate
      }
      templatesCount
    }
  }
`;

export const GET_TEMPLATE = gql`
  query ($_id: String, $type: String, $instanceId: String) {
    fetchTemplate(_id: $_id, type: $type, instanceId: $instanceId) {
      _id
      date
      pid
      uid
      cid
      auid
      name
      email
      fake
      version
      pricing
      language
      priceSeperator
      decimalPrecision
      contactButton
      sigOptions
      contactOptions {
        option
        value
        enabled
      }
      sigtype
      contactnumber
      callButton
      client {
        name
      }
      project {
        name
      }
      superPowers
      deliverables
      milestones
      state
      sawyou
      sawmilestones
      draft
      proptext
      signature {
        name
      }
      lastSeen
      seenCount
      seenTime
      titletext
      clientnametext
      disableSig
      curr
      currency
      redirectTo
      redirectAfter
      payedfor
      dateFormat
      published
      templateUrl
      templateName
      edited
      editEvent
      enableAITool
      expiryDate
      expiryMessage
      scripts
      chatwayScripts
      starred
      selectedOption
      payment {
        status
      }
      lastSelfDownload
      pdflink
      clientPayableCurrency
      clientPayableAmount
      clientPayableLabel
      datesent
      backup
      slug
      approvedmanually
      stripe
      templateOptions
      draft
      wixPricing {
        lineItems {
          quantity
          name
          description
          price
          pricesBreakdown {
            totalPriceAfterTax
            taxDetails {
              taxRate
              taxableAmount
              taxable
              totalTax
              taxName
              rateBreakdown {
                rate
                taxableAmount
                tax
              }
            }
            totalDiscount
            price
            priceBeforeDiscounts
            lineItemPrice
            fullPrice
          }
          itemType {
            preset
          }
        }
        appliedDiscounts {
          discountType
          lineItemIds
        }
        additionalFees {
          name
          priceBeforeTax
          taxDetails {
            taxRate
            taxableAmount
            taxable
            totalTax
            taxName
            rateBreakdown {
              rate
              taxableAmount
              tax
            }
          }
          price
        }
        calculatedTotals {
          priceSummary {
            total
            subtotal
            discount
            additionalFees
          }
        }
        paymentSettings {
          type
          singlePaymentSchedule {
            dueDate
            dueDateOffset
            issueDate
            deposit {
              percentage
              amount
            }
            paymentStatus
            amountPaid
          }
          multiplePaymentSchedule {
            items {
              dueDate
              dueDateOffset
              issueDate
              deposit {
                percentage
                amount
              }
              issueDateOffset
              title
              percentage
              amount
              paymentStatus
              amountPaid
            }
          }
        }
        currencyCode
        locale {
          country
          language
        }
      }
      isWixTemplate
      signatureEmails
      proposalRedirectTo
    }
  }
`;

export const GET_SECTION = gql`
  query ($_id: String!) {
    fetchLibrary(_id: $_id) {
      _id
      uid
      auid
      raw
      raw1
      raw2
      raw3
      rawtitle
      subrawtitle
      title
      text
      subtitle
      starred
      thumbnail
      libraryTitle
      createdAt
      language
      image
      isHeader
      by
      color
      contact
      cover
      email
      logo
      name
      rawby
      rawcontact
      rawemail
      rawname
      rawsubtitle
      titleStyle
      clientname
      originalState
      headerConfig
      hidecover
      numberOfColumns
      typeOfColumns
      titlePosition
      sectionStyles
      titleFont
      subTitleFont
      bodyFont
      spacing
    }
  }
`;
