import React, { useState, useEffect } from 'react';
import { Button, Tooltip, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import {
  SaveIcon,
  DownloadIcon,
  SavedIcon,
  SavingIcon,
  PreviewIcon,
  KeyboardButton,
  TocIcon,
  SettingsGearIcon,
  MoreSqaureIcon,
  HistoryIcon,
} from 'components/Icons';
import { SettingsModal, IntegrationModal } from './';

const DraftHeader = ({
  proposal,
  saveProposal,
  isSaving,
  openPublishView,
  downloadPdf,
  user,
  isSection,
  handleManualSave,
  disablePreview,
  configText,
  updateLoaderState,
  propRef,
  setProp,
  scrollToSection,
  path,
  setKeyboardModal,
  setCollapseContentTable,
  setCollapseSidemenu,
  openHistory,
}) => {
  const [showShortcut, setShowShortcut] = useState(false);
  const [collapseIconsMenu, setCollapseIconsMenu] = useState(true);

  useEffect(() => {
    // open the keyboard shortcut modal on ctrl + shift + s
    const handleKeyPress = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 83) {
        e.preventDefault();
        setKeyboardModal(true);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    // for ipad and smaller screen hide
    const isiPad = /iPad/i.test(navigator.userAgent);
    setShowShortcut(!isiPad && window.innerWidth > 700 && !!setKeyboardModal);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const settingsMenu = (
    <div>
      {proposal && !isSection && (
        <IntegrationModal
          user={user}
          proposal={proposal}
          saveProposal={saveProposal}
          isSaving={isSaving}
          setCollapseIconsMenu={setCollapseIconsMenu}
        />
      )}

      {proposal && !isSection && (
        <SettingsModal
          user={user}
          proposal={proposal}
          saveProposal={saveProposal}
          updateLoaderState={updateLoaderState}
          scrollToSection={scrollToSection}
          configText={configText}
          propRef={propRef}
          setProp={setProp}
          path={path}
          setCollapseIconsMenu={setCollapseIconsMenu}
        />
      )}

      {!isSection && (
        <Button
          className="medium-btn transparent-btn option-btn"
          icon={<DownloadIcon />}
          onClick={downloadPdf}
          aria-hidden="true">
          Download PDF
        </Button>
      )}
    </div>
  );

  return (
    <div className="main-wrapper draft">
      <div className="block block-1">
        <div>
          {showShortcut && (
            <Tooltip placement="bottom" title="Shortcuts" color="white">
              <Button
                className="medium-btn transparent-btn option-btn above-md"
                icon={<KeyboardButton />}
                onClick={() => setKeyboardModal(true)}
                aria-hidden="true"></Button>
            </Tooltip>
          )}
        </div>
        <div className="btn-wrapper editor-save-block above-md">
          <Tooltip title={isSaving ? 'Saving' : 'Saved'}>
            {isSaving ? (
              <SavingIcon className="saving-icon" />
            ) : (
              <SavedIcon className="saved-icon" />
            )}
          </Tooltip>

          <Tooltip title="Save" className="save-proposal" onClick={handleManualSave}>
            <SaveIcon />
          </Tooltip>
        </div>
      </div>

      {/* for smaller screen */}
      <div className="block block-2 icon-block below-lg">
        <Button
          className="large-btn grey-btn icon-btn toc-btn"
          onClick={() => {
            setCollapseIconsMenu(true);
            setCollapseSidemenu(true);
            setCollapseContentTable((prev) => !prev);
          }}>
          <TocIcon />
        </Button>

        <Dropdown
          overlay={settingsMenu}
          placement="bottomRight"
          overlayClassName="header-settings-dropdown below-lg"
          visible={!collapseIconsMenu}
          onVisibleChange={(visible) => {
            setCollapseIconsMenu(!visible);
            if (visible) {
              setCollapseContentTable(true);
              setCollapseSidemenu(true);
            }
          }}
          trigger="click">
          <Button
            className={`large-btn grey-btn icon-btn settings-btn ${
              !collapseIconsMenu ? 'active' : ''
            }`}
            onClick={() => {
              setCollapseIconsMenu((prev) => !prev);
            }}>
            <SettingsGearIcon />
          </Button>
        </Dropdown>

        <Button
          className="large-btn grey-btn icon-btn sidemenu-btn"
          onClick={() => {
            setCollapseContentTable(true);
            setCollapseIconsMenu(true);
            setCollapseSidemenu((prev) => !prev);
          }}>
          <MoreSqaureIcon />
        </Button>
      </div>

      {/* for bigger screen */}
      <div className="block block-2 option-block above-lg">
        {!isSection && (
          <Tooltip placement="bottom" title="Version History" color="white">
            <span>
              <Button
                className="medium-btn transparent-btn option-btn"
                icon={<HistoryIcon />}
                onClick={openHistory}
                disabled={isSaving}
                aria-hidden="true"></Button>
            </span>
          </Tooltip>
        )}

        {proposal && !isSection && (
          <IntegrationModal
            user={user}
            proposal={proposal}
            saveProposal={saveProposal}
            isSaving={isSaving}
          />
        )}

        {!isSection && (
          <>
            <Tooltip placement="bottom" title="Download PDF" color="white">
              <Button
                className="medium-btn transparent-btn option-btn"
                icon={<DownloadIcon />}
                onClick={downloadPdf}
                aria-hidden="true"></Button>
            </Tooltip>
          </>
        )}

        {proposal && !isSection && (
          <>
            <SettingsModal
              user={user}
              proposal={proposal}
              saveProposal={saveProposal}
              updateLoaderState={updateLoaderState}
              scrollToSection={scrollToSection}
              configText={configText}
              propRef={propRef}
              setProp={setProp}
              path={path}
            />
          </>
        )}
      </div>

      <div className="block block-last">
        <Button
          className="preview-button medium-btn secondary-btn"
          onClick={() => {
            if (disablePreview !== 'saving-layout' && openPublishView) {
              openPublishView();
            }
          }}
          style={{ marginLeft: '24px !important' }}
          disabled={isSaving}
          loading={isSaving}>
          <span>Preview</span>
          <span className="above-md">& Save</span>
          <PreviewIcon className="above-md" />
        </Button>
      </div>
    </div>
  );
};

DraftHeader.defaultProps = {
  path: '',
  proposal: {},
  saveProposal: () => {},
  isSaving: false,
  openPublishView: () => {},
  downloadPdf: () => {},
  user: '',
  isSection: false,
  disablePreview: false,
  configText: () => {},
  updateLoaderState: () => {},
  setProp: () => {},
  setKeyboardModal: () => {},
  scrollToSection: () => {},
  handleManualSave: () => {},
};

DraftHeader.propTypes = {
  proposal: PropTypes.instanceOf(Object),
  saveProposal: PropTypes.func,
  isSaving: PropTypes.bool,
  openPublishView: PropTypes.func,
  downloadPdf: PropTypes.func,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isSection: PropTypes.bool,
  handleManualSave: PropTypes.func,
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  configText: PropTypes.func,
  updateLoaderState: PropTypes.func,
  propRef: PropTypes.instanceOf(Object),
  setProp: PropTypes.func,
  scrollToSection: PropTypes.func,
  path: PropTypes.string,
  setKeyboardModal: PropTypes.func,
};
export default DraftHeader;
