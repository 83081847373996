/* eslint-disable  */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useMutation, useQuery, useReactiveVar, useLazyQuery } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { Dropdown, Empty, message, Tooltip, Button } from 'antd';

import NewProposalModal from 'pages/Dashboard/DashboardModals/NewProposalModal';

import Path from 'routes/path';
import Loader from 'components/Loader';
import useWindowDimensions from 'hooks/useWindowDimensions';
import ActivityNotificationIcon from 'components/Icons/ActivityNotificationIcon';
import AddIcon from 'components/Icons/AddIcon';

import { CREATE_PROPOSAL_MUTATION } from 'graphql/mutations/proposalMutation';
import { USER_DETAILS } from 'graphql/queries/userQueries';
import { FETCH_TEAM_MEMBERS } from 'graphql/queries/usersTeamQueries';
import { FETCH_USERS_ACTIVITY } from 'graphql/queries/usersActivityQueries';
import { PROPOSAL_SUBSCRIPTION_TOPIC, ACTIVITY_FILTER_ITEMS } from 'constants/index';
import { cache, getUserId, userVar, mondayBoardIdVar } from 'graphql/cache';
import { getUpdatedActivityList } from './helpers';
import { UserActivityHeader, UserActivityList } from './components';

import './UsersActivity.scss';

const findFilteredItems = ({ filterItems }) => {
  if (filterItems[0].checked === true) {
    return [];
  }
  return filterItems;
};

const UsersActivity = ({ isHeader, activeOption, setActiveOption, isLocked }) => {
  const history = useHistory();
  const { trackEvent } = useIntercom();

  const user = useReactiveVar(userVar);

  const [teamsActivity, setTeamsActivity] = useState([]);
  const [filterItems, setFilterItems] = useState(ACTIVITY_FILTER_ITEMS);
  const [selectedDate, setSelectedDate] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [usersTeam, setUsersTeam] = useState([]);
  const [offset, setOffset] = useState(0);
  const [activityCount, setActivityCount] = useState(0);
  const [showAddProposalModal, setShowAddProposalModal] = useState(false);

  useQuery(USER_DETAILS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user,
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      const { fetchUser } = res;
      userVar({ ...user, ...fetchUser });
    },
  });

  const [loadTeamMembers, { loading: isFetchingTeamMembers }] = useLazyQuery(FETCH_TEAM_MEMBERS, {
    variables: { id: getUserId() },
    skip: !getUserId() || user,
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ fetchTeamMembers }) => {
      setUsersTeam(fetchTeamMembers);
    },
  });

  const {
    loading: isFetchingTeamsActivity,
    refetch,
    fetchMore: fetchMoreTeamsActivity,
  } = useQuery(FETCH_USERS_ACTIVITY, {
    variables: {
      limit: 10,
      offset: 0,
      search: searchValue,
      teamList: teamList,
      selectedDate,
      filterItems: findFilteredItems({ filterItems }),
    },
    skip: !getUserId() || !user || !!teamsActivity.length,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const fetchActivities = data?.fetchActivities;
      setActivityCount(fetchActivities.activityCount);
      setTeamsActivity(fetchActivities.activities);
    },
  });

  const refetchTeamsActivity = useCallback(
    async (searchValue, teamList, filterItems, selectedDate) => {
      const refetchTeamsActivityData = await refetch({
        limit: 10,
        offset: 0,
        search: searchValue,
        teamList: teamList,
        selectedDate,
        filterItems: findFilteredItems({ filterItems }),
      });
      if (refetchTeamsActivityData?.data?.fetchActivities) {
        const fetchActivities = refetchTeamsActivityData?.data?.fetchActivities;
        setActivityCount(fetchActivities.activityCount);
        setTeamsActivity(fetchActivities.activities);
      }
    },
    [filterItems, searchValue, selectedDate, teamList, user]
  );

  const onClickDropdownAction = useCallback(async () => {
    const refetchTeamsActivityData = await refetch({
      limit: 10,
      offset: 0,
      teamList: teamList,
    });
    if (refetchTeamsActivityData?.data?.fetchActivities) {
      const fetchActivities = refetchTeamsActivityData?.data?.fetchActivities;
      setActivityCount(fetchActivities.activityCount);
      setTeamsActivity(fetchActivities.activities);
    }
  }, [teamList, user]);

  // useEffect(() => {
  //   if (refetch) {
  //     refetchTeamsActivity();
  //   }
  // }, [filterItems, searchValue, teamList, selectedDate, refetchTeamsActivity]);

  const teamsFilterList = () => {
    let tempTeamList = usersTeam.map((userTeam) => {
      return {
        id: userTeam._id,
        name: userTeam._id === user._id ? 'By Me' : userTeam.profile.name,
        checked: false,
      };
    });
    if (user.roles && user.roles.includes('editor')) {
      tempTeamList.push({
        id: 'sharedwithme',
        name: 'Shared with Me',
        checked: false,
      });
    }
    return setTeamList(tempTeamList);
  };

  useEffect(() => {
    if (user && !usersTeam.length && !isHeader) {
      !isHeader && loadTeamMembers();
    }
  }, [user, loadTeamMembers, usersTeam]);

  useEffect(() => {
    if (user && usersTeam.length && !teamList.length && !isHeader) {
      teamsFilterList();
    }
  }, [usersTeam]);

  useEffect(() => {
    if (!isHeader) {
      document.title = 'Prospero - Activity Log';
    }
  });

  const onChangeSearch = (e) => {
    if (e.target.value === '*') {
      return; // This prevents the asterisk from being entered.
    }
    setSearchValue(e.target.value);
    refetchTeamsActivity(e.target.value, teamList, filterItems, selectedDate);
  };

  const onChangeFilter = (selectedItem) => {
    const updatedFilterList = filterItems.map((item) => {
      return item.id === selectedItem.id
        ? { ...item, checked: !selectedItem.checked }
        : !selectedItem.checked && selectedItem.id !== 0 && item.id === 0
        ? { ...item, checked: false }
        : selectedItem.id === 0 && !selectedItem.checked && item.id !== 0
        ? { ...item, checked: false }
        : item;
    });

    const hasCheckedItems = updatedFilterList.some((item) => item.checked === true);
    if (hasCheckedItems) {
      setFilterItems(updatedFilterList);
    } else {
      updatedFilterList[0].checked = true;
      setFilterItems(updatedFilterList);
    }
    refetchTeamsActivity(searchValue, teamList, updatedFilterList, selectedDate);
  };

  const onChangeTeamsFilter = (selectedItem) => {
    const updatedFilterList = teamList.map((item) =>
      item.id === selectedItem.id ? { ...item, checked: !selectedItem.checked } : item
    );

    setTeamList(updatedFilterList);
    refetchTeamsActivity(searchValue, updatedFilterList, filterItems, selectedDate);
  };

  const onChangeDate = (date) => {
    if (date) {
      date[0] = date[0]?.startOf('day');
      date[1] = date[1]?.endOf('day');
    }
    setSelectedDate(date);
    refetchTeamsActivity(searchValue, teamList, filterItems, date);
  };

  const activitiesByDays = getUpdatedActivityList({
    searchValue,
    filterItems,
    teamsActivity,
    selectedDate,
    teamList,
  });

  const { allowedEditing } = useWindowDimensions();

  const [createProposal] = useMutation(CREATE_PROPOSAL_MUTATION);

  const createNewProposal = ({ proposalType }) => {
    setShowAddProposalModal(false);
    if (setActiveOption) {
      setActiveOption(false);
    }
    if (proposalType === 'template') {
      history.push(Path.CONTENT_LIBRARY);
    } else {
      if (!allowedEditing) {
        message.error('Proposals creation is only available on the desktop version.');
      } else {
        const pid = uuidv4();

        createProposal({
          variables: {
            pid,
            topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
            additionalInfo: {
              monday: {
                boardId: mondayBoardIdVar(),
              },
            },
          },
        })
          .then(({ data }) => {
            cache.evict('loadProposals');
            const {
              createProposal: { pid: newProposalId, _id },
            } = data;

            trackEvent('wizard-init', {
              id: _id,
              pid: newProposalId,
            });

            history.push(`/w/${newProposalId}`);
          })
          .catch((error) => {
            message.error('Proposal create failed');
          });
      }
    }
  };

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const handleInfiniteOnLoad = async () => {
    setLoading(true);

    if (teamsActivity.length > activityCount || teamsActivity.length === activityCount) {
      setLoading(false);
      setHasMore(false);
      return;
    }

    let page = offset;

    ++page;

    const data = await fetchMoreTeamsActivity({
      variables: {
        limit: 10,
        offset: page,
      },
    });

    const activities = data?.data?.fetchActivities?.activities;

    if (activities) {
      setTeamsActivity(teamsActivity.concat(activities));
      setOffset(page);
    }

    setLoading(false);
  };

  const renderActivityList = (
    <div className="activity-list">
      {(!isFetchingTeamMembers || !isFetchingTeamsActivity || fetchMoreTeamsActivity) &&
      teamsActivity.length ? (
        <UserActivityList
          user={user}
          offset={offset}
          setOffset={setOffset}
          activityCount={activityCount}
          fetchMoreTeamsActivity={fetchMoreTeamsActivity}
          teamsActivity={teamsActivity}
          activitiesByDays={activitiesByDays}
          isHeader={isHeader}
          setTeamsActivity={setTeamsActivity}
          handleInfiniteOnLoad={handleInfiniteOnLoad}
          usersTeam={usersTeam}
          hasMore={hasMore}
          loading={loading}
          setActiveOption={setActiveOption}
        />
      ) : (!isFetchingTeamMembers || !isFetchingTeamsActivity) && !teamsActivity.length ? (
        <div
          className={
            !isFetchingTeamsActivity ? 'fetch-activity-visible empty' : 'fetch-activity-hidden'
          }>
          {isLocked ? (
            <Empty description="No activity yet!" />
          ) : (
            <>
              <Empty description="No activity yet! Create your proposal now" />
              <Button
                className="large-btn secondary-btn icon-right"
                onClick={() => setShowAddProposalModal(true)}
                icon={<AddIcon />}>
                NEW PROPOSAL
              </Button>
            </>
          )}

          {showAddProposalModal && (
            <NewProposalModal
              visible={showAddProposalModal}
              onCancel={() => setShowAddProposalModal(false)}
              createNewProposal={createNewProposal}
            />
          )}
        </div>
      ) : null}
    </div>
  );

  return isHeader ? (
    <Dropdown
      overlay={renderActivityList}
      placement="topRight"
      overlayClassName="activity-menu-wrapper"
      trigger="click"
      visible={activeOption === 'user-activity'}
      onVisibleChange={(visible) => {
        setActiveOption(visible ? 'user-activity' : false);
      }}>
      <Tooltip placement="right" title="User Activity" color="white">
        <div
          className={`option-item ${activeOption === 'user-activity' ? 'active' : ''}`}
          onClick={onClickDropdownAction}>
          <ActivityNotificationIcon />
          <span className="text">User Activity</span>
        </div>
      </Tooltip>
    </Dropdown>
  ) : (
    <div className="container narrow-container">
      <div className="activity">
        <UserActivityHeader
          onChangeSearch={onChangeSearch}
          onChangeFilter={onChangeFilter}
          onChangeDate={onChangeDate}
          filterItems={filterItems}
          selectedDate={selectedDate}
          onChangeTeamsFilter={onChangeTeamsFilter}
          teamList={teamList}
          userPlanType={
            user?.planType
              ? user.planType
              : new Date(user.createdAt) > new Date(process.env.REACT_APP_PLAN_REVISE_DATE)
              ? 'new'
              : ''
          }
          isTeamMember={user?.teamId ? true : false}
          searchValue={searchValue}
        />
        {isFetchingTeamMembers || isFetchingTeamsActivity ? (
          <div className="loader-container">
            <Loader className="display-block" />
          </div>
        ) : (
          renderActivityList
        )}
      </div>
    </div>
  );
};

export default UsersActivity;
