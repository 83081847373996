import React, { useState, useEffect, Fragment } from 'react';
import { Divider, Dropdown, Menu, Button } from 'antd';
import { useIntercom } from 'react-use-intercom';
import { useReactiveVar } from '@apollo/client';
import PropTypes from 'prop-types';
import WebFont from 'webfontloader';
import * as _ from 'lodash';

import {
  fontListEnglish,
  fontListHebrew,
  fontSizes,
  defaultHebrewBodySettings,
  defaultBodySettings,
} from 'pages/Proposal/constants/constants';
import { fontLoadedVar } from 'graphql/cache';
import DropdownIcon from 'components/Icons/DropdownIcon';
import IncrementIcon from 'components/Icons/IncrementIcon';
import DecrementIcon from 'components/Icons/DecrementIcon';

/*
// this will be required when we merge font family and weight into single menu
const weightMaps = {
  100: 'Thin',
  200: 'Extra light',
  300: 'Light',
  400: 'Normal',
  500: 'Medium',
  600: 'Semi bold',
  700: 'Bold',
  '700italic': 'Bold italic',
  800: 'Black',
  '800italic': 'Black italic',
  900: 'Extra black',
  italic: 'Italic',
};
*/

const cleanString = (s) => s.replace(/^["']|["']$/g, '');

const FontTools = ({
  bodyFont,
  language,
  editorState,
  onToggleFontInlineStyle,
  user,
  isSmaller,
}) => {
  const fontLoaded = useReactiveVar(fontLoadedVar);
  const { trackEvent } = useIntercom();
  const selection = editorState.getSelection();
  const block = editorState.getCurrentContent().getBlockForKey(selection.getStartKey());
  const currentStyle = block ? editorState?.getCurrentInlineStyle() : '';

  const [fontFamilies] = useState(() => {
    const hebrewOrEnglish = language?.toLowerCase() === 'hebrew' ? fontListHebrew : fontListEnglish;
    return (user?.uploadedFonts || []).concat(hebrewOrEnglish);
  });

  const [selectedFontFamily, setSelectedFontFamily] = useState(
    fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
      fontFamilies[bodyFont?.family] ||
      (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings)
  );

  const [selectedWeight, setSelectedWeight] = useState(
    selectedFontFamily?.weights.find((weight) => currentStyle && currentStyle.has(`${weight}`)) ||
      bodyFont?.weight ||
      selectedFontFamily.weight ||
      400
  );

  const [selectedSize, setSelectedSize] = useState(
    fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px'
  );

  useEffect(() => {
    const activeEditor = document.querySelector('.rich-editor.active');
    const parentStyle = window.getComputedStyle(activeEditor.parentElement);
    const fontFamily = cleanString(parentStyle.getPropertyValue('font-family'));
    const fontSize = parentStyle.getPropertyValue('font-size');
    if (activeEditor && activeEditor.parentElement) {
      const matchedFontFamily =
        fontFamilies.find((font) => currentStyle && currentStyle.has(font.family)) ||
        fontFamilies.find((font) => cleanString(font.family) === fontFamily) ||
        fontFamilies[bodyFont?.family] ||
        (language?.toLowerCase() === 'hebrew' ? defaultHebrewBodySettings : defaultBodySettings);

      if (selectedFontFamily !== matchedFontFamily) {
        setSelectedFontFamily(matchedFontFamily);
      }
    }
    if (
      selectedWeight !==
        selectedFontFamily?.weights.find(
          (weight) => currentStyle && currentStyle.has(`${weight}`)
        ) ||
      bodyFont?.weight ||
      selectedFontFamily.weight ||
      400
    ) {
      setSelectedWeight(
        selectedFontFamily?.weights.find(
          (weight) => currentStyle && currentStyle.has(`${weight}`)
        ) ||
          bodyFont?.weight ||
          selectedFontFamily.weight ||
          400
      );
    }

    const matchedFontSize =
      fontSizes.find((size) => currentStyle && currentStyle.has(`${size}`)) ||
      `${fontSize}` ||
      bodyFont?.fontSize ||
      selectedFontFamily.fontSize ||
      '20px';

    if (selectedSize !== matchedFontSize) {
      setSelectedSize(matchedFontSize);
    }
  }, [
    bodyFont,
    currentStyle,
    fontFamilies,
    language,
    selectedFontFamily,
    selectedSize,
    selectedWeight,
  ]);

  const handleChange = (type, value, weight) => {
    if (type === 'family') {
      const selectedFont = fontFamilies.find((font) => font.family === value);
      setSelectedFontFamily(selectedFont);
    } else if (type === 'size') {
      setSelectedSize(value);
    } else if (type === 'weight') {
      setSelectedWeight(weight);
    }

    trackEvent('fonts-updated');

    onToggleFontInlineStyle({ inlineStyle: value, type, fontFamilies, weight });
  };

  const increaseFontSize = (e) => {
    e.preventDefault();
    const currentIndex = fontSizes.indexOf(selectedSize);
    const nextIndex = currentIndex < fontSizes.length - 1 ? currentIndex + 1 : currentIndex;
    const newSize = fontSizes[nextIndex];
    handleChange('size', newSize);
  };

  const decreaseFontSize = (e) => {
    e.preventDefault();
    const currentIndex = fontSizes.indexOf(selectedSize);
    const prevIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
    const newSize = fontSizes[prevIndex];
    handleChange('size', newSize);
  };

  useEffect(() => {
    if (!fontLoaded && fontFamilies.length) {
      fontLoadedVar(true);

      const fontsDefault = [];
      fontFamilies.forEach((f) => {
        f.weights.forEach((w) => {
          fontsDefault.push({
            family: `${f.family}:${w}${
              language?.toLowerCase() === 'hebrew' && f.family !== 'Arial' ? ':hebrew' : ''
            }`,
            source: f.source,
          });
        });
      });

      WebFont.load({
        google: {
          families: _.compact(fontsDefault.map((f) => f.source === 'Google' && f.family)),
        },
        custom: {
          families: ['Open Sans Hebrew'],
        },
      });
    }
  }, [fontFamilies, fontLoaded, language]);

  const fontFamiliesMenuList = (
    <Menu
      selectedKeys={[selectedFontFamily?.family || bodyFont.family]}
      className="rich-editor-list-option font-menu font-family">
      {
        // show selected font on top
        selectedFontFamily?.family && (
          <>
            <Menu.Item key={`selected-${selectedFontFamily.family}`}>
              <MenuItem handleChange={handleChange} fontFamily={selectedFontFamily} />
            </Menu.Item>
            <Divider />
          </>
        )
      }

      {fontFamilies.map((fontFamily) => (
        <Menu.Item key={fontFamily.family} style={{ fontFamily: fontFamily.family }}>
          <MenuItem handleChange={handleChange} fontFamily={fontFamily} />
        </Menu.Item>
      ))}
    </Menu>
  );

  const fontWightsMenuList = (
    <Menu
      selectedKeys={[selectedWeight]}
      className="rich-editor-list-option font-menu font-size font-weight">
      {selectedFontFamily?.weights.map((fontWeight) => (
        <Fragment key={fontWeight}>
          {typeof fontWeight === 'number' && (
            <Menu.Item
              key={fontWeight}
              onMouseDown={(e) => {
                e.preventDefault();
                handleChange('weight', selectedFontFamily?.family, parseInt(fontWeight));
              }}>
              <span
                className="menu-item"
                style={{ fontFamily: selectedFontFamily?.family, fontWeight }}>
                {fontWeight}
              </span>
            </Menu.Item>
          )}
        </Fragment>
      ))}
    </Menu>
  );

  const fontSizeMenuList = (
    <Menu selectedKeys={[selectedSize]} className="rich-editor-list-option font-menu font-size">
      {fontSizes.map((fontSize) => (
        <Menu.Item
          key={fontSize}
          onMouseDown={(e) => {
            e.preventDefault();
            handleChange('size', fontSize);
          }}>
          <span className="menu-item">{parseInt(fontSize)}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Dropdown
        placement={isSmaller ? 'topLeft' : 'bottomLeft'}
        overlay={fontFamiliesMenuList}
        trigger={['click']}
        className="rich-editor-list font-dropdown-btn font-family">
        <Button
          // className={
          //   (blockType && blockType.match(/font-family/)) || hoveredElement === 'font-family'
          //     ? 'toolbar-icon-active'
          //     : ''
          // }
          onMouseDown={(e) => e.preventDefault()}
          // onMouseEnter={() => toggleHover('font-family')}
          // onMouseLeave={() => toggleHover('')}
        >
          {selectedFontFamily?.family || bodyFont.family}
          <DropdownIcon className="rich-editor-dropdown-icon" />
        </Button>
      </Dropdown>

      <Dropdown
        placement={isSmaller ? 'topLeft' : 'bottomLeft'}
        overlay={fontWightsMenuList}
        trigger={['click']}
        className="rich-editor-list font-dropdown-btn font-size">
        <Button onMouseDown={(e) => e.preventDefault()}>
          {selectedWeight}
          <DropdownIcon className="rich-editor-dropdown-icon" />
        </Button>
      </Dropdown>

      <div className="button-group">
        <Dropdown
          placement={isSmaller ? 'topLeft' : 'bottomLeft'}
          overlay={fontSizeMenuList}
          trigger={['click']}
          className="rich-editor-list font-dropdown-btn font-size">
          <Button
            // className={
            //   (blockType && blockType.match(/font-size/)) || hoveredElement === 'font-size'
            //     ? 'toolbar-icon-active'
            //     : ''
            // }
            onMouseDown={(e) => e.preventDefault()}
            // onMouseEnter={() => toggleHover('font-size')}
            // onMouseLeave={() => toggleHover('')}
          >
            <span>{parseInt(selectedSize)}</span>
            <DropdownIcon className="rich-editor-dropdown-icon" />
          </Button>
        </Dropdown>

        <div className="increment-container" onMouseDown={(e) => e.preventDefault()}>
          <IncrementIcon onMouseDown={increaseFontSize} />
          <DecrementIcon onMouseDown={decreaseFontSize} />
        </div>
      </div>

      <Divider type="vertical" />
    </>
  );
};

FontTools.defaultProps = {
  bodyFont: '',
  isSmaller: false,
};

FontTools.propTypes = {
  language: PropTypes.string.isRequired,
  bodyFont: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  editorState: PropTypes.instanceOf(Object).isRequired,
  onToggleFontInlineStyle: PropTypes.func.isRequired,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  isSmaller: PropTypes.bool,
};

export default FontTools;

const MenuItem = ({ handleChange, fontFamily }) => {
  return (
    <span
      className="menu-item"
      onMouseDown={(e) => {
        e.preventDefault();
        console.log('loger 206-1');
        handleChange('family', fontFamily.family, 400);
      }}>
      {fontFamily?.family}
    </span>
  );
  /*
  // this will be required when we merge font family and weight into single menu
  return (
    <Fragment>
      {fontFamily?.weights.length > 1 ? (
        <Tooltip
          destroyTooltipOnHide={true}
          placement="rightTop"
          overlayClassName="menu-subitem-tooltip"
          title={fontFamily.weights.map((fontWeight) => (
            <Fragment key={fontWeight}>
              {typeof fontWeight === 'number' && (
                <li
                  onMouseDown={(e) => {
                    e.preventDefault();
                    console.log('loger 206-3');
                    handleChange('family', fontFamily.family, fontWeight);
                  }}
                  style={{ fontFamily: fontFamily.family, fontWeight }}>
                  {weightMaps[fontWeight] || fontWeight}
                </li>
              )}
            </Fragment>
          ))}>
          <span
            className="menu-item"
            onMouseDown={(e) => {
              e.preventDefault();
              console.log('loger 206-2');
              handleChange('family', fontFamily.family, 400);
            }}>
            <span className="title">{fontFamily.family}</span>
            <DropdownIcon className="arrow-icon" />
          </span>
        </Tooltip>
      ) : (
        <span
          className="menu-item"
          onMouseDown={(e) => {
            e.preventDefault();
            console.log('loger 206-1');
            handleChange('family', fontFamily.family, 400);
          }}>
          {fontFamily?.family}
        </span>
      )}
    </Fragment>
  );
  */
};
