import React, { useState, useCallback, useRef, useEffect } from 'react';
import { ContentState } from 'draft-js';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, Row, Divider, Popover, Button, Tooltip } from 'antd';
import { SketchPicker } from 'react-color';
import { useReactiveVar } from '@apollo/client';

import './Testimonial.scss';
import TestimonialItem from './TestimonialItem';

import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import utils from 'utils/utils';
import { testimonial } from 'constants/index';

import ExpandIcon from 'components/Icons/Expand';
import CollapseIcon from 'components/Icons/Collapse';
import DropdownIcon from 'components/Icons/DropdownIcon';
import DeleteIcon from 'components/Icons/DeleteIcon';

import CustomSelect from 'components/CustomSelect';
import {
  fontListEnglish,
  fontListHebrew,
  fontSizes,
  defaultBodySettings,
  defaultHebrewBodySettings,
} from 'pages/Proposal/constants/constants';
import { fontLoadedVar } from 'graphql/cache';
import { loadProposalFont } from 'helpers/proposal';
import { useMemo } from 'react';
import useOutsideClick from 'hooks/useOutsideClick';

const BACKGROUND = testimonial.background;
const COLOR = testimonial.color;

const TestimonialComponent = ({ block, blockProps }) => {
  const fontLoaded = useReactiveVar(fontLoadedVar);

  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);

  const { data } = entity;

  const isHebrew = useMemo(
    () => blockProps.language?.toLowerCase() === 'hebrew',
    [blockProps.language]
  );

  const bodySettings = useMemo(
    () => blockProps?.bodyFont || (isHebrew ? defaultHebrewBodySettings : defaultBodySettings),
    [isHebrew, blockProps?.bodyFont]
  );

  const [testimonials, setTestimonials] = useState(JSON.parse(JSON.stringify(data.data)));
  const [config, setConfig] = useState({
    backgroundColor: BACKGROUND,
    count: 3,
    horizontal: false,
    ...data.config,
  });
  const [textColor, setTextColor] = useState(COLOR);
  const [moreOptions, setMoreOptions] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getTextColor(config.backgroundColor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config?.backgroundColor]);

  useEffect(() => {
    if (!fontLoaded) {
      fontLoadedVar(true);
      loadProposalFont(
        isHebrew ? fontListHebrew : fontListEnglish,
        isHebrew ? 'hebrew' : 'english'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTextColor = useRef(
    _.throttle((backgroundColor) => {
      setTextColor(utils.getContrastColor(backgroundColor, '#ffffff', COLOR));
    }, 500)
  ).current;

  //eslint-disable-next-line
  const _save = (newData, newConfig) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      contentState.replaceEntityData(entityKey, {
        data: JSON.parse(JSON.stringify(newData)),
        config: JSON.parse(JSON.stringify(newConfig)),
      });
      blockProps.update(block);
      _finishEdit();
    }
  };

  const debouncedSave = useRef(
    _.debounce((newData, newConfig) => {
      _save(newData, newConfig);
      setLoading(false);
    }, 1000)
  ).current;

  const _finishEdit = () => blockProps.onFinishEdit(block.getKey());

  const handleFocus = useCallback(
    (e) => {
      blockProps.handleEditComponent(true);
      e.preventDefault();
      e.stopPropagation();
    },
    [blockProps]
  );

  const handleDuplicate = () => {
    if (loading) {
      return;
    }
    blockProps.handleDuplicateElement(
      { data: testimonials, config: data?.config || {} },
      'testimonial',
      block.getKey()
    );
    blockProps.handleEditComponent(false);
  };

  const handleRemove = () => {
    blockProps.setDeletedBlockKeys((p) => {
      // store all keys that are deleted, else only 1 block gets deleted
      let newState = [...p, block.getKey()];

      blockProps.handleEditComponent(false);
      blockProps.onRemove(newState);

      return newState;
    });
  };

  const onConfigChange = ({ type, value, debounce }) => {
    setConfig((prev) => {
      const newConfig = { ...prev, [type]: value };

      if (debounce) {
        setLoading(true);
        debouncedSave(testimonials, newConfig);
      } else {
        _save(testimonials, newConfig);
      }

      if (type === 'backgroundColor' && !config?.color) {
        getTextColor(value);
      }

      return newConfig;
    });
  };

  const handleEdit = (inputValue, index, type, debounce = true) => {
    setTestimonials((prev) => {
      const newTestimonials = [...prev];
      newTestimonials[index][type] = inputValue;

      if (debounce) {
        debouncedSave(newTestimonials, config);
      } else {
        _save(newTestimonials, config);
      }

      return newTestimonials;
    });
  };

  const handleDeleteVisibleChange = (visible) => {
    setDeleteVisible(visible);
  };

  const [tool, setTool] = useState(null);

  const backgroundColorRef = useRef(null);
  const colorRef = useRef(null);
  useOutsideClick(backgroundColorRef, () => (tool === 'backgroundColor' ? setTool(null) : null));
  useOutsideClick(colorRef, () => (tool === 'color' ? setTool(null) : null));

  const color = config?.color || textColor || COLOR;

  return (
    <ComponentWrapper
      showDrag
      isDraggable
      showTestimonialActions
      showActionButtons
      config={config}
      sectionName={blockProps.sectionName}
      setDraggingElement={blockProps.setDraggingElement}
      blockKey={block.getKey()}
      onFocus={handleFocus}
      onConfigChange={onConfigChange}
      handleEditComponent={blockProps.handleEditComponent}
      setDropDisabled={blockProps.setDropDisabled}
      componentType={'Testimonial'}
      toolbarClassName={`testimonial-toolbar ${tool ? 'active' : ''}`}
      toolbar={
        <>
          <Col>
            <span className="testimonial-count">
              <span className="label"># of testimonials:</span>
              {[1, 2, 3].map((count) => (
                <Button
                  key={count}
                  className={config?.count === count ? 'active' : ''}
                  onClick={() => onConfigChange({ type: 'count', value: count })}>
                  {count}
                </Button>
              ))}
            </span>
          </Col>

          <Col>
            <span
              onClick={() => onConfigChange({ type: 'horizontal', value: !config?.horizontal })}>
              <Tooltip
                title={
                  config?.horizontal ? 'Switch to paragraph view' : 'Switch to whole section view'
                }>
                {config?.horizontal ? (
                  <ExpandIcon className="column-icon" />
                ) : (
                  <CollapseIcon className="column-icon" />
                )}
              </Tooltip>
            </span>
          </Col>

          <Col>
            <span onClick={handleDuplicate} className={loading ? 'disabled' : ''}>
              Duplicate
            </span>
            <Popover
              content={
                <Col className="table-delete-popover">
                  <h3 className="ant-popover-title">Delete Testimonial?</h3>
                  <Divider />
                  <Row className="button-wrapper">
                    <Button className="medium-btn secondary-btn" onClick={handleRemove}>
                      Delete
                    </Button>
                    <Button
                      className="medium-btn grey-btn"
                      onClick={() => handleDeleteVisibleChange(false)}>
                      Cancel
                    </Button>
                  </Row>
                </Col>
              }
              placement="bottomRight"
              overlayClassName="Prosprich-editor-components-popover"
              trigger="click"
              visible={deleteVisible}
              onVisibleChange={handleDeleteVisibleChange}>
              <span>
                <Tooltip title="Delete">
                  <DeleteIcon className="delete-icon" />
                </Tooltip>
              </span>
            </Popover>
          </Col>

          <Col>
            <Tooltip placement="top" title="Toggle for more options">
              <span
                className={`more-options-icon ${moreOptions ? 'isactive' : ''}`}
                onClick={() => setMoreOptions(!moreOptions)}>
                <DropdownIcon />
              </span>
            </Tooltip>
          </Col>

          {moreOptions && (
            <Col>
              <CustomSelect
                className="font-family"
                list={(isHebrew ? fontListHebrew : fontListEnglish).map((item) => {
                  return {
                    label: item.family,
                    value: item.family,
                    style: { fontFamily: item.family },
                  };
                })}
                selected={config?.fontFamily || bodySettings.family}
                onChange={(value) => {
                  onConfigChange({
                    type: 'fontFamily',
                    value: value,
                  });
                }}
              />

              <CustomSelect
                className="font-size"
                list={fontSizes.slice(0, 15).map((fontSize) => {
                  return { label: fontSize, value: fontSize };
                })}
                selected={config?.fontSize || bodySettings.fontSize}
                onChange={(value) => onConfigChange({ value, type: 'fontSize' })}
              />

              <span ref={backgroundColorRef} className="tool-wrapper">
                <span className="tool" onClick={() => setTool(tool ? null : 'backgroundColor')}>
                  <span>Background</span>
                  <div
                    className="color-circle"
                    style={{ backgroundColor: config.backgroundColor }}
                  />
                </span>
                {tool === 'backgroundColor' && (
                  <>
                    <span className="sketch-picker-boundary" onClick={() => setTool(null)} />
                    <SketchPicker
                      color={config.backgroundColor || BACKGROUND}
                      onChange={(color) =>
                        onConfigChange({
                          type: 'backgroundColor',
                          value: color.hex,
                          debounce: true,
                        })
                      }
                    />
                  </>
                )}
              </span>

              <span ref={colorRef} className="tool-wrapper">
                <span className="tool" onClick={() => setTool(tool ? null : 'color')}>
                  <span>Color</span>
                  <div
                    className="color-circle"
                    style={{ backgroundColor: config?.color || textColor }}
                  />
                </span>
                {tool === 'color' && (
                  <>
                    <span className="sketch-picker-boundary" onClick={() => setTool(null)} />
                    <SketchPicker
                      color={config?.color || textColor}
                      onChange={(color) =>
                        onConfigChange({
                          type: 'color',
                          value: color.hex,
                          debounce: true,
                        })
                      }
                    />
                  </>
                )}
              </span>
            </Col>
          )}
        </>
      }>
      <div
        className={`rich-editor-testimonial testimonial-wrapper 
        ${textColor === '#ffffff' ? 'white-text' : ''}
        ${config?.horizontal ? 'horizontal' : 'vertical'}
        ${parseInt(config?.fontSize || bodySettings.fontSize) > 36 ? 'large-font' : ''}
        `}
        style={{
          backgroundColor: config?.backgroundColor || BACKGROUND,
          color,
          fontFamily: config?.fontFamily || bodySettings.family,
          fontSize: config?.fontSize || bodySettings.fontSize,
        }}>
        {testimonials.slice(0, config?.count || 3).map((item, i) => (
          <TestimonialItem
            key={`${i}-${item.avatar}`}
            avatar={item.avatar}
            name={item.name}
            text={item.text}
            index={i}
            handleEdit={handleEdit}
            uniqueId={blockProps?.uniqueId}
            textColor={color}
          />
        ))}
      </div>
    </ComponentWrapper>
  );
};

TestimonialComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default TestimonialComponent;
