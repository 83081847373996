import { Button, Input, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import {
  CopyIcon,
  ResetIcon,
  WhatsappIcon,
  MessageIcon,
  DropdownIcon,
  BackIcon,
} from 'components/Icons';
import DeviceToggler from 'components/DeviceToggler';
import utils from 'utils/utils';

const isTouchDevice = utils.isTouchSupported();

const PublishHeader = ({
  published,
  isPublishing,
  proposal,
  publishProposal,
  copyPropsalLink,
  publishedProposalLink,
  isTemplate,
  isSection,
  updateScreen,
  screen,
  setProposalPublished,
}) => {
  const history = useHistory();

  const handleBack = () => {
    if (proposal?.pid) {
      const pathSegment = window.location.pathname.split('/')?.[1];
      if (pathSegment?.length === 2) {
        /*
          pd -> d/pid
          pt -> t/pid
          ps -> s/pid
        */
        const uniqueCharacter = pathSegment.charAt(1);
        if (['t', 's'].includes(uniqueCharacter)) {
          return history.push(`/${uniqueCharacter}/${proposal._id}`);
        } else if (uniqueCharacter === 'd') {
          return history.push(`/d/${proposal.pid}`);
        }
      }
    }

    history.goBack();
  };

  const publishShareOptions = [
    {
      type: 'mail',
      title: 'Share Via Email',
      icon: <MessageIcon />,
      link: () => setProposalPublished(true),
    },
    {
      type: 'whatsapp',
      title: 'Share Via WhatsApp',
      icon: <WhatsappIcon />,
      link: () =>
        window.open(`https://web.whatsapp.com/send?text=${publishedProposalLink()}`, '_blank'),
    },
  ];

  const publishButtonMenu = (
    <Menu className="publish-button-menu">
      {publishShareOptions.map((publishShareOption, publishShareOptionIndex) => (
        <Menu.Item key={publishShareOptionIndex} onClick={publishShareOption.link}>
          {publishShareOption.icon}
          <span className="publish-button-menu-title">{publishShareOption.title}</span>
        </Menu.Item>
      ))}
      <Menu.Item
        key="unshortened"
        onClick={() => copyPropsalLink('unshortened')}
        disabled={proposal.state === 'deleted'}>
        <CopyOutlined className="copy-unshortened-link" />
        <span className="publish-button-menu-title">Copy Unshortened Link</span>
      </Menu.Item>
      <Menu.Item
        key="unpublish"
        className="unpublish-button"
        loading={isPublishing}
        onClick={() => publishProposal(false)}
        disabled={proposal.state === 'deleted'}>
        <ResetIcon />
        <span className="publish-button-menu-title">Unpublish</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="main-wrapper publish">
      <div className="block block-1">
        <Button className="medium-btn grey-btn" onClick={handleBack} icon={<BackIcon />}>
          <span className="back-text">BACK</span>
        </Button>

        <DeviceToggler screen={screen} updateScreen={updateScreen} />
      </div>

      <div className="block block-last">
        {published ? (
          <>
            <div className="proposal-link-container">
              <Input
                id="proposal-link-input"
                name="proposal-link-input"
                type="text"
                className="proposal-link-input"
                value={publishedProposalLink()}
                readOnly
              />
            </div>

            <Dropdown.Button
              className="copy-btn"
              onClick={copyPropsalLink}
              overlay={publishButtonMenu}
              trigger={isTouchDevice ? 'click' : 'hover'}
              icon={<DropdownIcon />}>
              <CopyIcon className="below-lg" />
              <span className="link-text above-lg">COPY LINK</span>
            </Dropdown.Button>
          </>
        ) : (
          !isTemplate &&
          !isSection && (
            <Button
              className="medium-btn secondary-btn publish-btn"
              loading={isPublishing}
              disabled={proposal.state === 'deleted'}
              onClick={() => publishProposal(true)}>
              <span className="below-lg">Publish</span>
              <span className="above-lg">PUBLISH & GENERATE SHARE LINK</span>
            </Button>
          )
        )}
      </div>
    </div>
  );
};

PublishHeader.defaultProps = {
  published: false,
  isPublishing: false,
  proposal: {},
  publishProposal: () => {},
  copyPropsalLink: () => {},
  publishedProposalLink: () => {},
  isTemplate: false,
  isSection: false,
  updateScreen: () => {},
  screen: '',
  setProposalPublished: () => {},
};

PublishHeader.propTypes = {
  published: PropTypes.bool,
  isPublishing: PropTypes.bool,
  proposal: PropTypes.instanceOf(Object),
  publishProposal: PropTypes.func,
  copyPropsalLink: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  updateScreen: PropTypes.func,
  screen: PropTypes.string,
  setProposalPublished: PropTypes.func,
};
export default PublishHeader;
