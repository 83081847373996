/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';
import DragIcon from 'components/Icons/DragIcon';

import { getSectionTitle } from 'helpers/proposal';
import { ReactSortable } from 'react-sortablejs';

import './ReOrderSectionModal.scss';

const ReOrderSectionModal = ({ onCancel, onConfirm, proposal }) => {
  const [sectionItems, setSectionItems] = useState([]);

  useEffect(() => {
    const list = _.compact(
      _.map(proposal.draft.sectionorder, (s, sectionIndex) => {
        if (!(proposal?.draft[s]?.text || proposal?.draft[s]?.raw || proposal?.draft[s]?.image)) {
          return null;
        }
        if (s === 'milestones') {
          // not allowing import of old milestones
          return null;
        }
        if (s === 'pricing') {
          // dont allow
          return null;
        }
        const stitle = getSectionTitle({
          proposal,
          sectionName: s,
          sectionIndex,
        });
        if (!stitle || !stitle.trim()) {
          return null;
        }

        return { id: s, v: s, t: stitle, c: [proposal?.language?.toLowerCase() || 'english'] };
      })
    );
    setSectionItems(list);
  }, []);

  return (
    <Modal
      className="reorder-section-modal"
      centered
      visible
      onCancel={onCancel}
      closeIcon={<CloseIcon className="close-icon" />}
      footer={null}>
      <h3 className="title">Reorder Sections</h3>
      <div className="divider" />
      <p className="body">Drag and drop to reorder sections</p>
      <ReactSortable list={sectionItems} setList={(newState) => setSectionItems(newState)}>
        {sectionItems.map((item) => (
          <div key={item.id} className="section-item">
            <DragIcon />
            <span className="item-name"> {item.t}</span>
          </div>
        ))}
      </ReactSortable>
      <div className="button-wrapper">
        <Button
          className="large-btn secondary-btn"
          onClick={() => onConfirm({ sectionOrder: sectionItems })}>
          APPLY
        </Button>
        <Button className="large-btn grey-btn" onClick={onCancel}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

ReOrderSectionModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  proposal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]).isRequired,
};

export default ReOrderSectionModal;
