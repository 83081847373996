import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Tooltip, Divider } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import { SIDE_MENU_ITEMS, LOCKED_PAGES } from 'constants/index';
import Path from 'routes/path';
import images from 'constants/images';
import HelpIcon from 'components/Icons/HelpIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import Upgrade from './component/Upgrade';
import NotificationItem from './component/NotificationItem';
import UsersActivity from 'pages/UsersActivity';
import AccountMenu from './component/AccountMenu';
import Branding from './component/Branding';

import './SideBar.scss';

const TRIAL_DAYS = +process.env.REACT_APP_DAYS_TRAIL || 14;

const { Sider } = Layout;

const Item = ({ itemId, title, active, path, icon: Icon, onItemClick, isDisabled }) => (
  <div
    className={`sidebar-item ${isDisabled ? 'disabled' : ''} ${active ? 'active' : ''}`}
    onClick={() => onItemClick({ selectedItemId: itemId, path })}>
    <Icon />
    <div className="title">{title}</div>
  </div>
);

const Sidebar = ({ isLocked, user }) => {
  const history = useHistory();
  const location = useLocation();
  const [items, setItems] = useState(SIDE_MENU_ITEMS);
  const [activeOption, setActiveOption] = useState(false);
  const [collapse, setCollapse] = useState(true);

  const updateSideMenu = useCallback(() => {
    const updatedItems = items.map((item) =>
      item.path === location.pathname ? { ...item, active: true } : { ...item, active: false }
    );
    setItems(updatedItems);
  }, [location]); //eslint-disable-line

  useEffect(() => {
    updateSideMenu();
  }, [location, updateSideMenu]);

  useEffect(() => {
    const initializeWidgetListeners = () => {
      if (window?.supportHeroWidget) {
        window.supportHeroWidget.onShow(() => {
          setActiveOption('help');
        });
        window.supportHeroWidget.onHide(() => {
          setActiveOption(false);
        });
      }
    };

    initializeWidgetListeners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window?.supportHeroWidget]);

  const onItemClick = ({ selectedItemId, path }) => {
    if (!collapse) {
      setCollapse(true);
    }

    if (isLocked && LOCKED_PAGES.includes(path)) {
      return;
    }

    if (path === '/help') {
      if (window?.supportHeroWidget?.isOpened()) {
        window?.supportHeroWidget?.hide();
      } else {
        window?.supportHeroWidget?.show();
      }
    } else {
      const updatedItems = items.map((item) =>
        item.id === selectedItemId ? { ...item, active: true } : { ...item, active: false }
      );
      setItems(updatedItems);

      if (window?.supportHeroWidget?.isOpened()) {
        window.supportHeroWidget.hide();
      }
      history.push(path);
    }
  };

  const trialTime = () => {
    if (!user) {
      return 666;
    }
    return TRIAL_DAYS - moment().diff(moment(user.stopperAnchor || user.createdAt), 'days');
  };

  let everPayed,
    didCancel = undefined;
  everPayed = user?.paymentStatus?.everPayed;
  didCancel = user?.paymentStatus?.didCancel;
  let shouldShow = true;
  if (
    user?.teamId ||
    (everPayed && !didCancel) ||
    (typeof user?.paymentStatus?.activeProfileId === 'undefined' &&
      typeof user?.paymentStatus?.activeProfileStartDate === 'undefined')
  ) {
    shouldShow = false;
  }

  const isNotActive = user?.paymentStatus?.canLock && !user?.teamId;
  const upgradeValue = trialTime();
  const showUpgrade = (upgradeValue > 0 && !isNotActive && shouldShow) || isNotActive;
  // const showUpgrade = false;

  return (
    <>
      <div className="sidebar-header">
        <Branding className="sidebar-logo" />

        <div className="right-side">
          <AccountMenu
            user={user}
            isLocked={isLocked}
            setCollapse={setCollapse}
            mobileVersion={true}
          />
          <Divider type="vertical" />
          <div className="sidebar-opener" onClick={() => setCollapse(false)}>
            <div className="d1" />
            <div className="d2" />
            <div className="d3" />
          </div>
        </div>
      </div>

      {!collapse && <div className="sidebar-closing-mask" onClick={() => setCollapse(true)} />}

      <div className="sidebar-gap" />

      {window.location.pathname !== Path.INVALID_CONNECTION ? (
        location.pathname !== Path.ADMIN ? (
          <Sider
            className={`sidebar ${showUpgrade ? 'show-upgrade' : ''} ${
              collapse ? 'sidebar-collapse' : 'sidebar-active'
            }`}
            width={80}>
            <div className="sidebar-wrapper">
              <div className="sidebar-upper">
                <Branding logo={images.LOGO} className="mobile-sidebar-logo" />
                <Branding className="sidebar-logo" />
                <CloseIcon className="close-icon" onClick={() => setCollapse(true)} />

                <div className="sidebar-items">
                  {items.map((item) => (
                    <Item
                      key={item.title}
                      itemId={item.id}
                      title={item.title}
                      active={item.active}
                      icon={item.icon}
                      onItemClick={onItemClick}
                      path={item.path}
                      isDisabled={isLocked && LOCKED_PAGES.includes(item.path)}
                    />
                  ))}
                </div>
              </div>

              <div className="sidebar-options">
                {window.location.pathname !== Path.INVALID_CONNECTION ? (
                  <>
                    <UsersActivity
                      isHeader={true}
                      activeOption={activeOption}
                      setActiveOption={setActiveOption}
                      isLocked={isLocked}
                    />

                    <Tooltip placement="right" title="Help Center" color="white">
                      <div
                        className={`option-item ${activeOption === 'help' ? 'active' : ''}`}
                        onClick={() => onItemClick({ selectedItemId: 5, path: '/help' })}>
                        <HelpIcon />
                        <span className="text">Help Center</span>
                      </div>
                    </Tooltip>

                    {user && (
                      <NotificationItem
                        activeOption={activeOption}
                        setActiveOption={setActiveOption}
                      />
                    )}

                    {showUpgrade && (
                      <Upgrade
                        upgradeValue={trialTime()}
                        everPayed={user?.paymentStatus?.everPayed}
                        setCollapse={setCollapse}
                      />
                    )}
                  </>
                ) : null}
              </div>
            </div>

            <AccountMenu user={user} isLocked={isLocked} setCollapse={setCollapse} />
          </Sider>
        ) : null
      ) : null}
    </>
  );
};

Item.defaultProps = {
  active: false,
};

Item.propTypes = {
  itemId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  icon: PropTypes.elementType.isRequired,
  active: PropTypes.bool,
  onItemClick: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};

export default Sidebar;
