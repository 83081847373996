import PropTypes from 'prop-types';
import { Radio } from 'antd';

import { DesktopIcon, MobileIcon, WixDesktopIcon, WixMobileIcon } from 'components/Icons';

import './DeviceToggler.scss';

const DeviceToggler = ({ isWix, className, screen, updateScreen }) => {
  return (
    <div
      className={`device-toggler ${className} ${
        isWix ? 'wixScreen-switcher' : 'toggle-mobile-desktop'
      }`}>
      <Radio.Group
        value={screen}
        onChange={() => updateScreen(screen === 'desktop' ? 'mobile' : 'desktop')}>
        <Radio.Button value="desktop" className="desktop">
          {isWix ? <WixDesktopIcon /> : <DesktopIcon />}
          <span className="text">Desktop</span>
        </Radio.Button>
        <Radio.Button value="mobile">
          {isWix ? <WixMobileIcon /> : <MobileIcon />}
          <span className="text">Mobile</span>
        </Radio.Button>
      </Radio.Group>
    </div>
  );
};

DeviceToggler.defaultProps = {
  isWix: false,
  className: '',
  screen: 'desktop',
  updateScreen: () => {},
};

DeviceToggler.propTypes = {
  isWix: PropTypes.bool,
  className: PropTypes.string,
  screen: PropTypes.string,
  updateScreen: PropTypes.func.isRequired,
};

export default DeviceToggler;
