import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal, Form, Steps, Layout, Row, Col } from 'antd';
import { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import { useLazyQuery, useReactiveVar, useMutation } from '@apollo/client';
import { fromAppVar, userProfileVar } from 'graphql/cache';
import { getUserId, userVar } from 'graphql/cache';
import { UPDATE_USER_INVOICING_DETAILS } from 'graphql/mutations/transactionMutation';
import { USER_PAYMENT_DETAILS } from 'graphql/queries/userQueries';
import { FETCH_TEAM_MEMBERS } from 'graphql/queries/usersTeamQueries';
import { UserInfo, ProjectInfo, Branding, Pricing, InvoicingInfo } from './components';
import SignupFinish from './components/SignupFinish/SignupFinish';
import SubUserInfo from './components/SubUserInfo/SubUserInfo';
import PerkSider from './components/Pricing/Perks';
import Button from 'components/Button';
import CanvasConfetti from 'components/CanvasConfetti';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import CurrentProgressIcon from 'components/Icons/CurrentProgressIcon';
import WaitProgressIcon from 'components/Icons/WaitProgressIcon';
import projectsType from 'constants/project-type';
import images from 'constants/images';
import utils from 'utils/utils';
import helpers from 'helpers';
import useCheckout from 'hooks/useCheckout';

import CountriesCode from 'constants/countries_code';

import './OnboardingWizard.scss';

const { Step } = Steps;
const { Sider, Content } = Layout;

const daystrial = process.env.REACT_APP_DAYS_TRAIL || 14;

const OnboardingWizard = ({ saveUserProfileInfo, createProposal, addUser, onCancel }) => {
  const [form] = Form.useForm();
  // Refs
  const addRemoveRef = useRef({ add: () => {}, remove: () => {} });

  // Reactive Variables & State
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const user = useReactiveVar(userVar) || {};
  const [isSaving, setIsSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(
    utils.getQueryStringValue('new') ? 4 : user?.profile?.onboardingState || 1
  );
  const [stripeLoading, setStripeLoading] = useState(false);
  const [usersToBeDeleted, setUsersToBeDeleted] = useState([]);
  const [usersTeam, setUsersTeam] = useState([]);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [stripe, setStripe] = useState('');
  const [clickedCheckbox, setClickedCheckbox] = useState(
    projectsType.map((_, index) => index < projectsType.length - 1)
  );
  const [colorValue, setColorValue] = useState(user?.branding?.buttonColor || '#005AFF');

  const trialTime = () => {
    if (!user) {
      return 666;
    }
    return daystrial - moment().diff(moment(user.stopperAnchor || user.createdAt), 'days');
  };

  const numberOfDaysLeft = daystrial - Math.ceil(trialTime());

  // Determine initial plan from user.paymentStatus
  const [plan, setPlan] = useState(
    (!user.paymentStatus.canLock && user.paymentStatus.subType) ||
      user.paymentStatus.subType ||
      'monthly'
  );

  // Form fields state
  const [formFields, setFormFields] = useState({
    proposalLanguage:
      user?.profile?.language ||
      window.sessionStorage.getItem('language.value')?.toLowerCase() ||
      'english',
    currency: user?.profile?.currency || '$',
    dateFormat: user?.profile?.dateFormat || 'MM/DD/YYYY',
    domain: user?.domain || '',
    companyname: user?.profile?.companyname || '',
    defaultProposalFont: user?.profile?.defaultProposalFont || 'Poppins',
    brandlogo: user?.branding?.companyLogo || '',
    password: '',
    fullname: user?.profile?.name || '',
    brandcolor: colorValue,
    termsValue: user?.profile?.terms || true,
    wixConnected: !!utils.getURLParam('wix'),
    where: user?.profile?.where,
    country: user?.profile?.country,
    address: user?.profile?.address,
    vatnumber: user?.profile?.vatnumber,
  });

  // Load user payment details (preserving refetchUser)
  const [loadUser, { refetch: refetchUser }] = useLazyQuery(USER_PAYMENT_DETAILS, {
    skip: user?.paymentStatus?.payments?.length || user?.teamId,
    variables: { id: getUserId() },
    fetchPolicy: 'network-only',
    onCompleted: ({ fetchUser }) => {
      import('@stripe/stripe-js')
        .then((stripeLib) => stripeLib.loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
        .then(setStripe);
      userVar({ ...user, ...fetchUser });
    },
  });

  // Load team members
  const [loadTeamMembers] = useLazyQuery(FETCH_TEAM_MEMBERS, {
    variables: { id: user?._id },
    skip: !user?._id,
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ fetchTeamMembers }) => {
      setUsersTeam(fetchTeamMembers);
    },
  });

  const [updateUserInvoicing] = useMutation(UPDATE_USER_INVOICING_DETAILS, {
    async onCompleted({ updateUserInvoicingDetails }) {
      userVar({
        ...user,
        profile: {
          ...user.profile,
          ...updateUserInvoicingDetails.profile,
        },
      });
      userProfileVar({
        ...user.profile,
        ...updateUserInvoicingDetails.profile,
      });
    },
  });

  // Effects
  useEffect(() => {
    loadUser();
    return () => setUsersTeam([]);
  }, [loadUser]);

  useEffect(() => {
    if (!usersTeam.length) {
      loadTeamMembers();
    }
  }, [user, loadTeamMembers, usersTeam.length]);

  useEffect(() => {
    const initialUsers = getInitialUsers();
    const currentUsers = form.getFieldValue('users') || [];

    if (initialUsers.length !== currentUsers.length) {
      if (initialUsers.length > currentUsers.length) {
        const newUsers = initialUsers.slice(currentUsers.length);
        const mergedUsers = [...currentUsers, ...newUsers];
        setFormFields((prev) => ({ ...prev, users: mergedUsers }));
        form.setFieldsValue({ users: mergedUsers });
      } else {
        setFormFields((prev) => ({ ...prev, users: initialUsers }));
        form.setFieldsValue({ users: initialUsers });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersTeam, newUsersCount]);

  useEffect(() => {
    if (fromAppVar() === 'monday' && (!user?.monday || Object.keys(user?.monday).length === 0)) {
      const authUrl = `https://auth.monday.com/oauth2/authorize?client_id=${process.env.REACT_APP_MONDAY_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_MONDAY_REDIRECT_URI}`;
      utils.refetchUserOnClosingTab(authUrl).then(() => refetchUser());
    }
  }, [user?.monday, refetchUser]);

  useEffect(() => {
    if (user?.paymentStatus?.payments) {
      const isUpgraded = utils.getURLParam('upgrade');

      if (isUpgraded === 'yes') {
        setCurrentStep(5);
        // handleNext();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Helper Functions
  const getInitialUsers = () => {
    const existingUsers = usersTeam.map((team) => ({
      _id: team._id,
      email: team.emails[0].address,
      role: team.roles && team.roles[0],
    }));

    const newUsers = Array.from({ length: newUsersCount }, () => ({
      _id: null,
      email: '',
      role: [],
    }));

    return [...existingUsers, ...newUsers];
  };

  const onColorChange = (selectedColor) => {
    const { r, g, b, a } = selectedColor.rgb;
    const hexa = helpers.rgbaToHexA(r, g, b, a);
    setColorValue(hexa);
    setFormFields((prev) => ({ ...prev, brandcolor: hexa }));
    form.setFieldsValue({ brandcolor: hexa });
  };

  const changeUserTeamCount = (val) => {
    const targetNewUsers = val - (usersTeam.length + 1);
    const currentNewUsers = form.getFieldValue('users')?.length || 0;
    if (targetNewUsers > currentNewUsers) {
      for (let i = currentNewUsers; i < targetNewUsers; i++) {
        addRemoveRef.current.add();
      }
    } else if (targetNewUsers < currentNewUsers) {
      for (let i = currentNewUsers; i > targetNewUsers; i--) {
        addRemoveRef.current.remove(i - 1);
      }
    }
    setNewUsersCount(targetNewUsers);
  };

  const onUpgradeNowClick = (values) => {
    const teamMembers = values.users?.filter(
      (usr) =>
        usersTeam.filter((teamMember) => teamMember.emails[0].address === usr.email).length === 0
    );

    const mutationInfo = {
      membersToBeAdded: teamMembers,
      membersToBeDeleted: usersToBeDeleted,
    };

    const checkoutData = {
      user,
      plan,
      trial: true,
      mutationInfo,
    };
    handleCheckout(checkoutData);
  };

  const saveInvoicingInfo = (values, infoUpdate) => {
    const { companyname, address, vatnumber, country } = values;

    let countryCode = country;

    Object.keys(CountriesCode).forEach((code) => {
      if (CountriesCode[code]?.name === country) countryCode = code;
    });

    const updateDetails = {
      companyname,
      address,
      vatnumber,
      infoUpdate,
      country,
      countryCode,
    };

    updateUserInvoicing({
      variables: {
        details: updateDetails,
      },
    });

    setIsSaving(true);
    setTimeout(() => {
      setIsSaving(false);
      onCancel();
    }, 1000);
  };

  const submitProfileInfo = (values) => {
    const isFinalStep = values.companyname && values.companyname.trim() !== '' && currentStep === 4;

    const updatedValues = {
      ...formFields,
      ...values,
      ...(isFinalStep && { onBoardComplete: true, onboardingState: null }),
    };

    setFormFields((prev) => ({ ...prev, ...values }));

    if (currentStep === 2) {
      const ptype = projectsType
        .filter((_, index) => clickedCheckbox[index])
        .map((project) => project.key)
        .join(' ');
      updatedValues.ptype = ptype;
    }

    if (!isFinalStep) {
      updatedValues.onboardingState =
        updatedValues.onboardingState != null ? updatedValues.onboardingState + 1 : currentStep + 1;
    }
    saveUserProfileInfo(updatedValues);

    if (isFinalStep) {
      setIsSaving(true);
      setTimeout(() => {
        setIsSaving(false);
        setCurrentStep((prev) => prev + 1);
      }, 1000);
    } else {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const didUpgrade =
    utils.getQueryStringValue('new') &&
    user &&
    (!!user.subscriptionId || !!user.paypalSubscriptionId);

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleNext = ({ skipUpgrade = false } = {}) => {
    if (currentStep === 2 && !clickedCheckbox.some(Boolean)) {
      return;
    }

    if (currentStep === 4) {
      if (!skipUpgrade && didUpgrade) {
        form
          .validateFields()
          .then((values) => saveInvoicingInfo(values))
          .catch((errorInfo) => {
            console.error('Validation failed:', errorInfo);
          });
      } else if (!didUpgrade && !skipUpgrade) {
        setStripeLoading(true);
        form
          .validateFields()
          .then((values) => onUpgradeNowClick(values))
          .catch((errorInfo) => {
            setStripeLoading(false);
            console.error('Validation failed:', errorInfo);
          });
      } else {
        submitProfileInfo(formFields);
      }
    } else {
      form
        .validateFields()
        .then((values) => submitProfileInfo(values))
        .catch((errorInfo) => {
          console.error('Validation failed:', errorInfo);
        });
    }
  };

  const { handleCheckout } = useCheckout({ stripe, setStripeLoading });

  // Steps configuration
  const steps = useMemo(
    () => [
      { title: 'Sign up', content: null },
      {
        title: 'Information',
        heading: 'Welcome 🥳',
        content: (
          <UserInfo
            fullname={formFields.fullname}
            setFullname={(value) => setFormFields((prev) => ({ ...prev, fullname: value }))}
            termsValue={formFields.termsValue}
            setTermsValue={(value) => setFormFields((prev) => ({ ...prev, termsValue: value }))}
            password={formFields.password}
            setPassword={(value) => setFormFields((prev) => ({ ...prev, password: value }))}
          />
        ),
      },
      {
        title: 'Account setup',
        heading: 'Set Up Your Account 📋',
        content: (
          <ProjectInfo
            user={user}
            form={form}
            projectsType={projectsType}
            setLanguage={(value) => setFormFields((prev) => ({ ...prev, proposalLanguage: value }))}
            setDateFormat={(value) => setFormFields((prev) => ({ ...prev, dateFormat: value }))}
            setCurrency={(value) => setFormFields((prev) => ({ ...prev, currency: value }))}
            clickedCheckbox={clickedCheckbox}
            setClickedCheckbox={setClickedCheckbox}
            currency={formFields.currency}
          />
        ),
      },
      {
        title: 'Branding',
        heading: 'Customize your branding 🎨',
        content: (
          <Branding
            user={user}
            form={form}
            language={formFields.proposalLanguage}
            companyname={formFields.companyname}
            setCompanyName={(value) => setFormFields((prev) => ({ ...prev, companyname: value }))}
            setDefaultProposalFont={(value) =>
              setFormFields((prev) => ({ ...prev, defaultProposalFont: value }))
            }
            setBrandLogo={(value) => setFormFields((prev) => ({ ...prev, brandlogo: value }))}
            brandlogo={formFields.brandlogo}
            brandcolor={formFields.brandcolor}
            onColorChange={onColorChange}
            colorValue={colorValue}
            domain={formFields.domain}
            setDomainPrefix={(value) => setFormFields((prev) => ({ ...prev, domain: value }))}
            formFields={formFields}
          />
        ),
      },
      ...(currentStep === 4
        ? [
            {
              title: 'Pricing',
              heading: didUpgrade ? 'Invoice Details' : 'Free 14-day trial',
              content: didUpgrade ? (
                <InvoicingInfo
                  country={formFields.country}
                  address={formFields.address}
                  companyname={formFields.companyname}
                  vatnumber={formFields.vatnumber}
                  CountriesCode={CountriesCode}
                />
              ) : (
                <Pricing
                  user={user}
                  form={form}
                  usersTeam={usersTeam}
                  setUsersTeam={setUsersTeam}
                  formFields={formFields}
                  changeUserTeamCount={changeUserTeamCount}
                  newUsersCount={newUsersCount}
                  setNewUsersCount={setNewUsersCount}
                  addRemoveRef={addRemoveRef}
                  usersToBeDeleted={usersToBeDeleted}
                  setUsersToBeDeleted={setUsersToBeDeleted}
                  plan={plan}
                  setPlan={setPlan}
                  daysLeft={daystrial - numberOfDaysLeft}
                />
              ),
            },
          ]
        : []),
    ], // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      user,
      formFields,
      clickedCheckbox,
      newUsersCount,
      usersToBeDeleted,
      plan,
      usersTeam,
      colorValue,
      form,
      currentStep,
    ]
  );

  // Custom dot for steps
  const customDot = (_, info) => {
    switch (info.status) {
      case 'finish':
        return <CircleTickIcon />;
      case 'process':
        return <CurrentProgressIcon />;
      case 'wait':
        return <WaitProgressIcon />;
      default:
        return null;
    }
  };

  // Render finish view
  const renderFinish = () => (
    <SignupFinish
      wixConnected={formFields.wixConnected}
      createNewProposal={createProposal}
      addUser={addUser}
      onCancel={onCancel}
      where={formFields.where}
    />
  );

  // If user is team member
  const renderTeamMemberOnboarding = () => (
    <SubUserInfo user={user} saveUserProfileInfo={saveUserProfileInfo} />
  );

  return (
    <>
      {(currentStep === steps.length || user?.profile?.onboardingState === 0) && (
        <CanvasConfetti event="ONBOARDED" delay={1000} />
      )}
      <Modal
        className={`simple-info-modal sign-up-info-modal ${
          currentStep === steps.length || currentStep === 5 || currentStep === 6 ? 'final' : ''
        } ${currentStep === 4 && !didUpgrade ? 'pricing-step' : ''} ${
          didUpgrade ? 'invoice-details' : ''
        }`}
        centered
        visible
        footer={null}
        closeIcon={null}
        closable={false}
        maskClosable={false}>
        <Content className="onboarding-wizard-content">
          <Layout className="account-onboard-layout">
            {currentStep !== steps.length &&
              currentStep !== 4 &&
              currentStep !== 5 &&
              currentStep !== 6 && (
                <Sider
                  width={200}
                  className="onboard-wizard-steps"
                  breakpoint="sm"
                  collapsedWidth="50">
                  <Row className="header-signup">
                    <Col className="logo">
                      <img
                        src={images.LOGO}
                        className="full-logo"
                        alt="prospero-logo"
                        height="16"
                      />
                      <img
                        src={images.LOGO_SMALL}
                        className="small-logo"
                        alt="prospero-logo"
                        height="25"
                      />
                    </Col>
                  </Row>
                  <Steps current={currentStep} progressDot={customDot} direction="vertical">
                    {steps.map((step) => (
                      <Step key={step.title} title={step.title} />
                    ))}
                  </Steps>
                </Sider>
              )}
            <Col
              className={`onboard-form ${currentStep === 4 && !didUpgrade ? 'upgrade-form' : ''}`}>
              {currentStep < steps.length ? (
                <Form layout="vertical" initialValues={formFields} form={form} autoComplete="off">
                  <div
                    className={`wizard-content-container ${
                      currentStep === 4 && !didUpgrade ? 'pricing-content' : ''
                    }`}>
                    <div className="heading-wrap">
                      <h1 className="heading">{steps[currentStep].heading}</h1>
                      <div className="color-divider" />
                    </div>
                    {steps[currentStep].content}
                  </div>
                  <div className={`button-wrapper ${currentStep === 0 ? 'welcome-form' : ''}`}>
                    {!didUpgrade && currentStep === 4 ? (
                      <Button
                        className="large-btn transparent-btn skip-btn"
                        onClick={() => handleNext({ skipUpgrade: true })}
                        text={
                          <>
                            <span className="above-md">Skip for now</span>
                            <span className="below-md">Skip</span>
                            <div className="divider" />
                          </>
                        }
                      />
                    ) : currentStep === 4 && didUpgrade ? (
                      <Button
                        className="large-btn grey-btn close"
                        onClick={() =>
                          saveInvoicingInfo({
                            companyname: formFields.company,
                            address: '',
                            vatnumber: '',
                          })
                        }
                        htmlType="button"
                        text="Close"
                      />
                    ) : currentStep > 1 ? (
                      <Button
                        className="large-btn grey-btn cancel"
                        onClick={handlePrev}
                        htmlType="button"
                        text=""
                        icon={
                          <div className="button-icon-right">
                            <LeftOutlined />
                          </div>
                        }
                      />
                    ) : (
                      <>&nbsp;</>
                    )}
                    {currentStep === 4 ? (
                      didUpgrade ? (
                        <Button
                          className="large-btn secondary-btn"
                          htmlType="button"
                          onClick={handleNext}
                          text="Continue"
                          loading={isSaving}
                        />
                      ) : (
                        <Button
                          type="primary"
                          htmlType="button"
                          onClick={handleNext}
                          disabled={stripeLoading}
                          text={
                            stripeLoading ? (
                              <LoadingOutlined />
                            ) : (
                              <>
                                <span>Upgrade Now</span>
                                {daystrial - numberOfDaysLeft < 1 ? null : (
                                  <span className="discount-text">
                                    (Charged in {daystrial - numberOfDaysLeft} Days)
                                  </span>
                                )}
                              </>
                            )
                          }
                          className="large-btn secondary-btn upgrade-button"
                        />
                      )
                    ) : (
                      <Button
                        className="large-btn secondary-btn"
                        onClick={handleNext}
                        htmlType="button"
                        text="Continue"
                        loading={isSaving}
                      />
                    )}
                  </div>
                </Form>
              ) : user?.profile?.where === 'teammember' ? (
                renderTeamMemberOnboarding()
              ) : (
                renderFinish()
              )}
            </Col>
            {!didUpgrade && currentStep === 4 && (
              <PerkSider user={user} usersTeam={usersTeam} newUsersCount={newUsersCount} />
            )}
          </Layout>
        </Content>
      </Modal>
    </>
  );
};

OnboardingWizard.propTypes = {
  user: PropTypes.object.isRequired,
  saveUserProfileInfo: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  createProposal: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  refetchUser: PropTypes.func.isRequired,
  saveInvoicingInfo: PropTypes.func,
  CountriesCode: PropTypes.instanceOf(Object),
};

export default OnboardingWizard;
