import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#05034D"
      d="M2.7,7.3C3,7.3,3.3,7,3.3,6.7V2c0-0.4-0.3-0.7-0.7-0.7S2,1.6,2,2v4.7C2,7,2.3,7.3,2.7,7.3z"
    />
    <path
      fill="#05034D"
      d="M8,7.3C7.6,7.3,7.3,7.6,7.3,8v6c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7V8C8.7,7.6,8.4,7.3,8,7.3z"
    />
    <path
      fill="#05034D"
      d="M13.3,8.7C13.7,8.7,14,8.4,14,8V2c0-0.4-0.3-0.7-0.7-0.7S12.7,1.6,12.7,2v6C12.7,8.4,13,8.7,13.3,8.7z"
    />
    <path
      fill="#05034D"
      d="M4.7,8.7h-4C0.3,8.7,0,9,0,9.3S0.3,10,0.7,10H2v4c0,0.4,0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7v-4h1.3C5,10,5.3,9.7,5.3,9.3S5,8.7,4.7,8.7z"
    />
    <path
      fill="#05034D"
      d="M10,4.7H8.7V2c0-0.4-0.3-0.7-0.7-0.7S7.3,1.6,7.3,2v2.7H6C5.6,4.7,5.3,5,5.3,5.3S5.6,6,6,6h4c0.4,0,0.7-0.3,0.7-0.7S10.4,4.7,10,4.7z"
    />
    <path
      fill="#05034D"
      d="M15.3,10h-4c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7h1.3V14c0,0.4,0.3,0.7,0.7,0.7S14,14.4,14,14v-2.7h1.3c0.4,0,0.7-0.3,0.7-0.7S15.7,10,15.3,10z"
    />
  </svg>
);

const ControlSwitchIcon = (props) => <Icon component={svg} {...props} />;

export default ControlSwitchIcon;
