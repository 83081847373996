/* eslint-disable */
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Input, Button } from 'antd';

import { ChevronRight, CloseIcon } from 'components/Icons';

const TitleEditModal = ({
  onClosePane,
  editColumnName,
  resetColumnName,
  chosen,
  stab,
  configText,
}) => {
  const getValue = (value, defaultValue) => {
    return typeof value === 'string' ? value : defaultValue;
  };

  return (
    <>
      <div className="tabList-drawer title-edit">
        <div className="ant-modal-mask" onClick={onClosePane}></div>
        <div className="content">
          <div className="drawer-header">
            <h2>Title names</h2>
            <Button className="medium-btn grey-btn tabList-drawer-close" onClick={onClosePane}>
              <CloseIcon />
            </Button>
          </div>

          <div className="body">
            <Input
              placeholder="Description"
              value={getValue(chosen[stab]?.columnName?.deliverable, configText('Deliverable'))}
              onChange={(e) => editColumnName('deliverable', e.target.value)}
            />

            <Input
              placeholder="Quantity"
              value={getValue(chosen[stab]?.columnName?.item, configText('Quantity'))}
              onChange={(e) => editColumnName('item', e.target.value)}
            />

            <Input
              placeholder="Line total"
              value={getValue(chosen[stab]?.columnName?.price, configText('Line Total'))}
              onChange={(e) => editColumnName('price', e.target.value)}
            />

            <Input
              placeholder="Price"
              value={getValue(chosen[stab]?.columnName?.rowprice, configText('Price'))}
              onChange={(e) => editColumnName('rowprice', e.target.value)}
            />
          </div>

          <div className="footer">
            <Button
              className="medium-btn transparent-btn hover"
              type="text"
              onClick={resetColumnName}>
              Reset
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

TitleEditModal.propTypes = {
  onClosePane: PropTypes.func,
};

export default TitleEditModal;
