import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      fill="#05034D"
      d="M17,6.7H8C7.6,6.7,7.3,6.4,7.3,6S7.6,5.3,8,5.3h9c0.4,0,0.7,0.3,0.7,0.7S17.4,6.7,17,6.7z"
    />
    <path
      fill="#05034D"
      d="M17,10.7H8c-0.4,0-0.7-0.3-0.7-0.7S7.6,9.3,8,9.3h9c0.4,0,0.7,0.3,0.7,0.7S17.4,10.7,17,10.7z"
    />
    <path
      fill="#05034D"
      d="M17,14.7H8c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h9c0.4,0,0.7,0.3,0.7,0.7S17.4,14.7,17,14.7z"
    />
    <path
      fill="#05034D"
      d="M4.5,6.7H4C3.6,6.7,3.3,6.4,3.3,6S3.6,5.3,4,5.3h0.5c0.4,0,0.7,0.3,0.7,0.7S4.9,6.7,4.5,6.7z"
    />
    <path
      fill="#05034D"
      d="M4.5,10.7H4c-0.4,0-0.7-0.3-0.7-0.7S3.6,9.3,4,9.3h0.5c0.4,0,0.7,0.3,0.7,0.7S4.9,10.7,4.5,10.7z"
    />
    <path
      fill="#05034D"
      d="M4.5,14.7H4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h0.5c0.4,0,0.7,0.3,0.7,0.7S4.9,14.7,4.5,14.7z"
    />
  </svg>
);

const TocIcon = (props) => <Icon component={svg} {...props} />;

export default TocIcon;
