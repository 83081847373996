import React from 'react';
import { Modal, Popover } from 'antd';
import PropTypes from 'prop-types';

import TotalIcon from 'components/Icons/TotalIcon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import PageHeader from 'components/PageHeader';
import './Totals.scss';

const Totals = ({
  dis,
  tax,
  showSubTotal,
  showSubTotalBeforeTax,
  setVisible,
  visible,
  totalText,
  subTotalElement,
  discountElement,
  subtotalBeforeTaxElement,
  taxElement,
  total,
  setDiscountVisible,
  discountVisible,
  taxVisible,
  setTaxVisible,
}) => {
  const { width } = useWindowDimensions();
  const clickHideButton = () => {
    setVisible(false);
    setDiscountVisible(false);
    setTaxVisible(false);
  };

  const isTotal = total();

  const popOverContent = (
    <>
      {isTotal ? (
        <div className="totals-container">
          <div className="totals-container-close">
            <button
              className="totals-container-close-button"
              type="button"
              onClick={clickHideButton}>
              Hide
            </button>
          </div>

          <div className="totals-content-wrapper">
            {showSubTotal && subTotalElement()}
            {dis && discountElement()}
            {dis && tax && showSubTotalBeforeTax && subtotalBeforeTaxElement()}
            {tax && taxElement()}
            {isTotal}
          </div>
        </div>
      ) : null}
    </>
  );

  if (width < 768) {
    return (
      <div className="totals-popover-container">
        <div onClick={() => setVisible(true)} className="total-btn">
          <div className="icon">
            <TotalIcon />
          </div>
          {totalText}
        </div>

        <Modal
          maskStyle={{ zIndex: 1009 }}
          className="totals-popover"
          centered
          visible={visible || discountVisible || taxVisible}
          onCancel={() => setVisible(false)}
          footer={null}
          title={<PageHeader pageTitle="Summary" />}>
          {popOverContent}
        </Modal>
      </div>
    );
  }

  return (
    <div className="totals-popover-container">
      <Popover
        overlayClassName={`totals-popover `}
        content={popOverContent}
        trigger="click"
        placement="topRight"
        visible={visible || discountVisible || taxVisible}
        onVisibleChange={(value) => value && setVisible(value)}>
        <div className="total-btn">
          <div className="icon">
            <TotalIcon />
          </div>
          {totalText}
        </div>
      </Popover>
    </div>
  );
};

Totals.defaultProps = {
  dis: '',
  tax: '',
};

Totals.propTypes = {
  dis: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  tax: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  multipleTotal: PropTypes.instanceOf(Object).isRequired,
  price: PropTypes.bool.isRequired,
};

export default Totals;
