import React, { useState, useEffect } from 'react';
import { Row, List, Divider, Button, Space, Popover, Input } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import UnlinedTick from 'components/Icons/UnlinedTick';
import DeleteIcon from 'components/Icons/DeleteIcon';
import EditIcon from 'components/Icons/EditIcon';
import AddBlockIcon from 'components/Icons/AddBlockIcon';

import './FormEditor.scss';

const FormEditor = ({
  form,
  saveForm,
  closeForm,
  deleteForm,
  handleFocus,
  configText,
  language,
}) => {
  const [formFields, setFormFields] = useState();
  const [selectedField, setSelectedField] = useState();
  const [itemKey, setItemKey] = useState();

  useEffect(() => {
    let formTemp = form;
    let index = 0;
    let temp = undefined;
    formTemp.forEach((elem, i) => {
      if (elem.selected) {
        if (
          i !== 0 &&
          (typeof formTemp[i - 1].selected === 'undefined' || !formTemp[i - 1].selected)
        ) {
          temp = elem;
          formTemp.splice(i, 1);
          formTemp.splice(index, 0, temp);
        }
        index++;
      }
    });
    const newForm = [...form, { key: 'Custom Field', type: 'add' }];
    setFormFields(newForm);
  }, [form]);

  const addField = (e) => {
    e.stopPropagation();
    let unq_keys = [...new Set(formFields.map((item) => item.key))];

    setFormFields(
      formFields.reduce(function (s, a, i) {
        if (i === formFields.length - 1) {
          let keyName = `${configText('New Field')} ${i}`;
          let j = 1;
          while (unq_keys.includes(keyName)) {
            keyName = `${configText('New Field')} ${i + j}`;
            j++;
          }
          s.push({ key: keyName, value: '', editable: true }, a);
        } else {
          s.push(a);
        }
        return s;
      }, [])
    );
  };

  const handleCancel = () => {
    const formFieldsCopy = [...formFields];
    formFieldsCopy.forEach((elem, i) => {
      if (elem.saved) {
        formFieldsCopy[i].selected = true;
      } else {
        formFieldsCopy[i].selected = false;
      }
    });
    setFormFields(formFieldsCopy);
    closeForm();
  };

  const deleteField = (e, key) => {
    e.stopPropagation();
    setFormFields(_.reject(formFields, (el) => el.key === key));
  };

  const handleFieldName = (index, value) => {
    if (value) {
      const formFieldsCopy = [...formFields];
      formFieldsCopy[index].key = value;

      setFormFields(formFieldsCopy);
      setItemKey('');
      setSelectedField('');
    } else {
      setSelectedField(index);
    }
  };

  const saveFields = () => {
    const formFieldsCopy = _.reject(formFields, (el) => el.key === 'Custom Field');
    formFieldsCopy.forEach((elem, i) => {
      if (elem.selected) {
        formFieldsCopy[i].saved = true;
      } else {
        formFieldsCopy[i].saved = false;
      }
    });
    saveForm({ formCopy: formFieldsCopy, editable: false });
  };

  const handleItem = (e, index, value, key) => {
    e.preventDefault();
    const formFieldsCopy = [...formFields];
    formFieldsCopy[index].selected = value;
    let lastIndex = 0;
    let temp = undefined;
    formFieldsCopy.forEach((elem, i) => {
      if (elem.selected) {
        if (
          i !== 0 &&
          (typeof formFieldsCopy[i - 1].selected === 'undefined' || !formFieldsCopy[i - 1].selected)
        ) {
          temp = elem;
          formFieldsCopy.splice(i, 1);
          formFieldsCopy.splice(lastIndex, 0, temp);
        }
        lastIndex++;
      }
    });
    setFormFields(formFieldsCopy);
  };

  return (
    <div className="form-editor">
      <Row className="form-editor-header">
        <h5>{configText('Which fields do you want to add')}?</h5>
        <DeleteIcon className="form-editor-delete" onClick={deleteForm} />
      </Row>
      <Row className="form-editor-fields-row">
        <List
          dataSource={formFields}
          renderItem={(item, index) =>
            !item.head && (
              <List.Item
                key={`${item.key}${index}`}
                id={`${index}`}
                onClick={(e) =>
                  item.type !== 'add' &&
                  handleItem(e, index, item.selected ? false : true, item.key)
                }
                className={item.selected ? 'selected' : ''}>
                <List.Item.Meta title={configText(item.key)} />

                {item.editable && (
                  <>
                    <Popover
                      onVisibleChange={() => handleFieldName(index)}
                      onClick={(e) => e.stopPropagation()}
                      trigger="click"
                      visible={selectedField === index}
                      content={
                        <div
                          className={`edit-container ${language.toLowerCase()}`}
                          onClick={(e) => e.stopPropagation()}>
                          <Input
                            autoFocus
                            onFocus={handleFocus}
                            defaultValue={item.key}
                            onClick={(e) => handleFocus(e)}
                            onChange={(e) => setItemKey(e.target.value)}
                          />

                          <div className="button-wrapper">
                            <Space>
                              <Button onClick={(e) => handleFieldName(index, itemKey)}>Save</Button>
                              <Button onClick={() => handleFieldName(false)}>Cancel</Button>
                            </Space>
                          </div>
                        </div>
                      }>
                      <EditIcon id="form-editor-edit-button" className="form-editor-edit-button" />
                    </Popover>

                    <Divider type="vertical" />
                    <DeleteIcon
                      id="form-editor-delete-button"
                      onClick={(e) => deleteField(e, item.key)}
                    />
                    <Divider type="vertical" />
                  </>
                )}

                {item.type === 'add' && (
                  <>
                    <AddBlockIcon className="form-editor-add-icon" onClick={(e) => addField(e)} />
                    <Divider type="vertical" />
                  </>
                )}

                {item.selected && <UnlinedTick />}
              </List.Item>
            )
          }
        />
      </Row>
      <Divider type="horizontal" />
      <Row className="form-editor-button-wrapper">
        <Button className="button confirm" type="primary" onClick={saveFields}>
          ADD FIELDS
        </Button>
        <Button className="button cancel" onClick={handleCancel}>
          CANCEL
        </Button>
      </Row>
    </div>
  );
};

FormEditor.propTypes = {
  form: PropTypes.instanceOf(Array).isRequired,
  saveForm: PropTypes.func.isRequired,
  deleteForm: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
};

export default FormEditor;
