import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import CloseIcon from 'components/Icons/CloseIcon';
import ResetIcon from 'components/Icons/ResetIcon';

import './RestoreProposalModal.scss';

const RetrieveProposalModal = ({
  title,
  body,
  visible,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  showIcon,
  className,
  isRestoring,
}) => (
  <Modal
    className={`confirm-modal ${className}`}
    centered
    visible={visible}
    onCancel={onCancel}
    closeIcon={<CloseIcon className="close-icon" />}
    showIcon={showIcon}
    footer={null}>
    <ResetIcon className="logo" />
    <h3 className="title">{title}</h3>
    <div className="divider" />

    <p className="body">{body}</p>

    <div className="button-wrapper">
      <Button
        className="large-btn confirm"
        type="primary"
        onClick={onConfirm}
        loading={isRestoring}>
        {confirmText}
      </Button>
      <Button className="large-btn cancel" onClick={onCancel} disabled={isRestoring}>
        {cancelText}
      </Button>
    </div>
  </Modal>
);

RetrieveProposalModal.defaultProps = {
  visible: false,
  showIcon: true,
};

RetrieveProposalModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default RetrieveProposalModal;
