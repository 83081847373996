import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox } from 'antd';

const UserInfo = ({ fullname, setFullname, termsValue, setTermsValue, password, setPassword }) => {
  const validatePassword = (rule, value, callback) => {
    const reDigit = /(?=.*[\d\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-])/; //eslint-disable-line
    const reCharacter = /(?=.*[a-zA-Z])/;

    if (value.length > 0 && value.trim().length === 0) {
      callback('Your password cannot just have spaces!');
    } else if (value.length > 0 && value.length < 8) {
      callback('Your password must be at least 8 characters long, please try again.');
    } else if (value.length > 0 && !reCharacter.test(value) && !reDigit.test(value)) {
      callback('At least 1 letter and 1 number/special character is required');
    } else if (value.length > 0 && !reCharacter.test(value)) {
      callback('At least 1 letter is required');
    } else if (value.length > 0 && !reDigit.test(value)) {
      callback('At least 1 number or special character is required');
    } else {
      callback();
    }
  };

  const validateFullname = (rule, value, callback) => {
    if (value.length > 0 && value.trim().length === 0) {
      callback('Your name cannot just have spaces!');
    } else {
      callback();
    }
  };

  return (
    <>
      <Form.Item
        label="Full name"
        name="fullname"
        labelCol={false}
        rules={[
          { required: true, message: 'Your name cannot be empty!' },
          { validator: validateFullname },
        ]}
        shouldUpdate>
        <Input value={fullname} onChange={(e) => setFullname(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        labelCol={false}
        rules={[
          { required: true, message: 'Your password cannot be empty!' },
          { validator: validatePassword },
        ]}
        shouldUpdate>
        <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
      </Form.Item>

      <Form.Item
        label="Where did you hear about Prospero?"
        name="where"
        rules={[{ max: 50, message: 'Max length exceeded 50 characters' }]}
        labelCol={false}>
        <Input />
      </Form.Item>

      <Form.Item name="terms" rules={[{ required: !termsValue, message: 'Required!' }]}>
        <Checkbox checked={termsValue} onChange={(e) => setTermsValue(!!e.target.checked)}>
          By creating an account you agree to our{' '}
          <a href="https://www.goprospero.com/terms" rel="noreferrer" target="_blank">
            Terms
          </a>{' '}
          &
          <a
            rel="noreferrer"
            href="https://www.iubenda.com/privacy-policy/50076459"
            target="_blank">
            {' '}
            Privacy Policy
          </a>
          .
        </Checkbox>
      </Form.Item>
    </>
  );
};

UserInfo.propTypes = {
  setFullname: PropTypes.func.isRequired,
  termsValue: PropTypes.string,
  setLangauge: PropTypes.func,
  setTermsValue: PropTypes.func.isRequired,
};

export default UserInfo;
