import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { Modal, Form, Input, AutoComplete, Button, message } from 'antd';
import { useQuery, useReactiveVar, useMutation } from '@apollo/client';
import { useIntercom } from 'react-use-intercom';
import { convertFromHTML, convertToRaw, ContentState } from 'draft-js';
import _, { keyBy } from 'lodash';
import PropTypes from 'prop-types';

import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import RestoreProposalModal from 'components/RestoreProposalModal/RestoreProposalModal';
import Popconfirm from 'components/PopConfirm';
import CreateDuplicateProposalModal from 'components/CreateDuplicateProposalModal';
import ShareWithModal from 'components/ShareWithModal';
import AddUser from 'pages/UsersTeam/components/AddUser';
import Path from 'routes/path';
import utils from 'utils/utils';

import GenerateInvoiceModal from './GenerateInvoiceModal';
import NewProposalModal from './NewProposalModal';
import OnboardingModal from './OnboardingModal';
import MoveLibraryModal from 'pages/ContentLibrary/ContentLibraryModals/MoveLibraryModal';

import { PROPOSAL_SUBSCRIPTION_TOPIC, PROPOSAL_FILTER_ITEMS } from 'constants/index';
import { MANAGE_TEAM_MEMBERS } from 'graphql/mutations/usersTeamMutation';
import { USER_UPDATE } from 'graphql/mutations/userMutations';
import {
  DELETE_PROPOSAL_MUTATION,
  CREATE_PROPOSAL_MUTATION,
  DELETE_PROPOSAL_FILES,
} from 'graphql/mutations/proposalMutation';
import {
  cache,
  userVar,
  userProfileVar,
  proposalsVar,
  getUserId,
  proposalsClientVar,
  mondayBoardIdVar,
} from 'graphql/cache';
import { GET_PROPOSALS_CLIENTS } from 'graphql/queries/proposalQueries';
import CloseIcon from 'components/Icons/CloseIcon';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { sanitizeObject } from 'utils/xss';

const { Option } = AutoComplete;

const daystrial = parseInt(process.env.REACT_APP_DAYS_TRAIL) || 14;

const DashboardModals = ({
  user,
  refetchUser,
  trash,
  proposals,
  teamMembers,
  selectedProposalId,
  updateProposal,
  popConfirmDetails,
  setPopConfirmDetails,
  showAddProposalModal,
  setShowAddProposalModal,
  confirmModal,
  showConfirmModal,
  showAddUserModal,
  setShowAddUserModal,
  duplicateModal,
  showDuplicateModal,
  setUserInfoModal,
  permissionErrorModal,
  showPermissionErrorModal,
  restoreModal,
  showRestoreModal,
  generateInvoiceModal,
  setGenerateInvoiceModal,
  userInfoModal,
  showShareProposalModal,
  setShowShareProposalModal,
  refetchProposal,
  moveModal,
  showMoveModal,
  fetchMoreProposal,
  disabledEditingModal,
  setDisabledEditingModal,
}) => {
  const history = useHistory();
  const proposalsClients = useReactiveVar(proposalsClientVar);
  const { trackEvent } = useIntercom();
  const { allowedEditing } = useWindowDimensions();

  const [isSaving, setIsSaving] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [plan, setPlan] = useState('monthly');
  const [showProspectFormModal, setShowProspectFormModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientNames, setClientNames] = useState(proposalsClients);
  const [newExternalProposalId, setNewExternalProposalId] = useState(null);
  const [externalProposalFile, setExternalProposalFile] = useState(null);
  let [externalProposalFileType, setExternalProposalFileType] = useState(null);

  const [form] = Form.useForm();

  useQuery(GET_PROPOSALS_CLIENTS, {
    skip: !getUserId() || proposalsClients.length,
    variables: {
      uid: getUserId(),
      fetchPolicy: 'cache-and-network',
    },
    onCompleted: ({ fetchProposals: { proposals } }) => {
      let freshbooksClients = [];
      if (user && user.freshbooks && user.freshbooks.clients && user.freshbooks.clients.clients) {
        freshbooksClients = user.freshbooks.clients.clients.map((x) => ({
          value: x.fname + (x.lname ? ` ${x.lname}` : ''),
          extra: { data: x, type: 'freshbooks' },
        }));
      }
      const freshbooksMap = keyBy(freshbooksClients, 'value');

      let tempClientNames = _.compact(
        _.map(
          proposals,
          (p) =>
            p.client &&
            p.client.contact &&
            !freshbooksMap[p.client.contact] && { value: p.client.contact }
        )
      );

      tempClientNames = _.uniqBy([...tempClientNames, ...freshbooksClients], 'value');

      setClientNames(tempClientNames);
      proposalsClientVar(tempClientNames);
    },
  });

  const onInputChange = (value) => {
    setClientName(value);
  };

  const onCreateNewFormClick = () => {
    form
      .validateFields()
      .then((values) => {
        createFromExternalProposal({ values, proposalPID: newExternalProposalId });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const [deleteProposalFiles] = useMutation(DELETE_PROPOSAL_FILES);

  const onDeleteProposalFiles = (tempExternalProposalFile, tempExternalProposalFileType) => {
    if (
      (externalProposalFile?.length > 0 && externalProposalFileType !== 'docx') ||
      (tempExternalProposalFile?.length > 0 && tempExternalProposalFileType !== 'docx')
    ) {
      const tempExternalProposalFiles = externalProposalFile || tempExternalProposalFile;

      deleteProposalFiles({
        variables: {
          urls: tempExternalProposalFiles.map(
            (tempExternalProposalFile) => tempExternalProposalFile.url
          ), //el.url,
        },
      })
        .then(({ data }) => {})
        .catch((error) => {
          console.error('External Proposal File deletion failed', error);
        });
    }
    setExternalProposalFile(null);
    setExternalProposalFileType(null);
    setShowProspectFormModal(false);
  };

  const parseElChildren = (docFile) => {
    let newsection = {
      title: '',
      text: '',
      raw: {
        entityMap: {},
        blocks: [],
      },
    };

    if (docFile.length > 0) {
      docFile.forEach(async (elm, idx) => {
        let elNodeName = elm.nodeName;

        if (elNodeName === 'TABLE') {
          let data = [];
          elm.children.forEach((eltbody, idt) => {
            eltbody.children.forEach((eltr, idb) => {
              data[idb] = {};
              data[idb].id = idb + 1;
              eltr.children.forEach((eltd, idd) => {
                data[idb][idd + 1] = eltd.innerText;
              });
            });
          });

          const keyValue = Object.keys(newsection.raw.entityMap).length;

          newsection.raw.blocks.push({
            key: _.times(4, () => _.random(35).toString(36)).join(''),
            text: ' ',
            type: 'atomic',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
              {
                offset: 0,
                length: 1,
                key: keyValue ? keyValue : 0,
              },
            ],
            data: {},
          });

          newsection.raw.entityMap = {
            ...newsection.raw.entityMap,
            [keyValue]: {
              type: 'table',
              mutability: 'MUTABLE',
              data: {
                data: data,
                config: {
                  topRowColor: 'lightgrey',
                  rowColor: 'white',
                },
              },
            },
          };
        } else if (elNodeName === 'UL' || elNodeName === 'OL') {
          elm.children.forEach((eltbody, idt) => {
            const blocksFromHTML = convertFromHTML(eltbody.innerHTML);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            const rawElement = convertToRaw(state);
            rawElement.blocks.forEach((block) => {
              newsection.raw.blocks.push({
                key: _.times(4, () => _.random(35).toString(36)).join(''),
                text: block.text,
                type: elNodeName === 'UL' ? 'unordered-list-item' : 'ordered-list-item',
                depth: 0,
                inlineStyleRanges: block.inlineStyleRanges,
                entityRanges: [],
                data: {},
              });
            });
          });
        } else {
          const innerHTMLs = elm.innerHTML.replaceAll('><', '>-new-<').split('-new-');
          innerHTMLs.forEach((innerHTML) => {
            const blocksFromHTML = convertFromHTML(innerHTML);
            const state = ContentState.createFromBlockArray(
              blocksFromHTML.contentBlocks,
              blocksFromHTML.entityMap
            );
            const rawElement = convertToRaw(state);
            const rawBlocks = rawElement.blocks.map((block) => {
              if (block.entityRanges.length) {
                const entityRanges = block.entityRanges.map((entityRange, entityIndex) => {
                  const keyValue = Object.keys(newsection.raw.entityMap).length;
                  newsection.raw.entityMap = {
                    ...newsection.raw.entityMap,
                    [keyValue]: rawElement.entityMap[entityIndex],
                  };
                  return { ...entityRange, key: keyValue };
                });
                return { ...block, type: 'atomic', text: ' ', entityRanges };
              }
              return block;
            });

            newsection.raw.blocks = newsection.raw.blocks.concat(rawBlocks);
          });
        }
      });
    }
    return newsection;
  };

  const createFromExternalProposal = ({ values, proposalPID }) => {
    setIsLoading(true);

    const { client_name: newClientName, project_name: newProjectName } = values;

    const sectionOrderIdArray = [];
    const sectionOrderObject = {};

    if (externalProposalFileType === 'docx') {
      const sectionId = uuidv4();
      sectionOrderIdArray.push(sectionId);
      sectionOrderObject[sectionId] = parseElChildren(externalProposalFile);

      const sectionPosition = {
        contact: 'block',
        cover: 'top',
        coverTextAlignment: 'left',
        position: 'none',
      };
      sectionOrderObject['titleFont'] = sectionPosition;
    } else if (externalProposalFileType === 'pdf') {
      let newsection = {
        image: '<img />',
        raw: {
          entityMap: {},
          blocks: [],
        },
      };

      externalProposalFile
        .sort((a, b) => a.uploadId - b.uploadId)
        .forEach((elm) => {
          const keyValue = Object.keys(newsection.raw.entityMap).length;

          newsection.raw.blocks.push({
            key: _.times(4, () => _.random(35).toString(36)).join(''),
            text: ' ',
            type: 'atomic',
            depth: 0,
            inlineStyleRanges: [],
            entityRanges: [
              {
                offset: 0,
                length: 1,
                key: keyValue ? keyValue : 0,
              },
            ],
            data: {},
          });

          newsection.raw.entityMap = {
            ...newsection.raw.entityMap,
            [keyValue]: {
              type: 'image',
              mutability: 'MUTABLE',
              data: {
                src: elm.url,
              },
            },
          };
        });

      sectionOrderIdArray.push('imported');
      sectionOrderObject['imported'] = newsection;
    } else {
      externalProposalFileType = utils.getFileExtension(externalProposalFile[0].url).toLowerCase();
      if (
        externalProposalFileType === 'png' ||
        externalProposalFileType === 'jpeg' ||
        externalProposalFileType === 'jpg'
      ) {
        let newsection = {
          image: '<img />',
          raw: {
            entityMap: {},
            blocks: [],
          },
        };

        const keyValue = Object.keys(newsection.raw.entityMap).length;

        newsection.raw.blocks.push({
          key: _.times(4, () => _.random(35).toString(36)).join(''),
          text: ' ',
          type: 'atomic',
          depth: 0,
          inlineStyleRanges: [],
          entityRanges: [
            {
              offset: 0,
              length: 1,
              key: keyValue ? keyValue : 0,
            },
          ],
          data: {},
        });

        newsection.raw.entityMap = {
          ...newsection.raw.entityMap,
          [keyValue]: {
            type: 'image',
            mutability: 'MUTABLE',
            data: {
              src: externalProposalFile[0].url,
            },
          },
        };

        sectionOrderIdArray.push('imported');
        sectionOrderObject['imported'] = newsection;
      }
    }

    const externalProposalDoc = {
      draftSectionOrderIds: sectionOrderIdArray,
      draftSectionElement: sectionOrderObject,
    };

    createProposal({
      variables: {
        pid: proposalPID,
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
        template: {
          newProjectName: newProjectName,
          newClientName: newClientName,
        },
        additionalInfo: {
          monday: {
            boardId: mondayBoardIdVar(),
          },
        },
        importedProposal:
          externalProposalFileType === 'docx' ||
          externalProposalFileType === 'pdf' ||
          externalProposalFileType === 'jpeg' ||
          externalProposalFileType === 'jpg' ||
          externalProposalFileType === 'png'
            ? externalProposalDoc
            : externalProposalFile,
      },
    })
      .then(({ data }) => {
        cache.evict('loadProposals');
        const {
          createProposal: { pid: newProposalId, _id },
        } = data;

        trackEvent('wizard-init', {
          id: _id,
          pid: newProposalId,
        });

        history.push(`/d/${newProposalId}`);
      })
      .catch((error) => {
        message.error('Proposal create failed');
      });
  };

  const handleManageTeamMembersResponse = (member, errorCallback) => {
    if (member.error) {
      message.error(
        `User ${member.user.emails[0].address} addtion failed: ${
          member.error.match(/E11000/) ? 'User already exists' : member.error
        }`
      );
      errorCallback();
    } else {
      message.success(`User ${member.user.emails[0].address} added successfully`);
    }
  };

  const [createProposal] = useMutation(CREATE_PROPOSAL_MUTATION);

  const [deleteProposal, { loading: isDeleting }] = useMutation(DELETE_PROPOSAL_MUTATION, {
    onCompleted(deleteProposalResponse) {
      cache.evict('loadProposals');
      const prevProposals = proposals.filter(
        (prop) => prop._id !== deleteProposalResponse.deleteProposal
      );
      let updatedProposals = [];
      updatedProposals = prevProposals.filter(
        (prop) => prop._id !== deleteProposalResponse.deleteProposal
      );
      proposalsVar(updatedProposals);
      message.success('Proposal deleted successfully');
      showConfirmModal(false);

      // fetch more if proposal count < limit
      fetchMoreProposal('delete');
    },
    onError(error) {
      message.error('Proposal delete failed');
    },
  });

  const [manageTeamMembers] = useMutation(MANAGE_TEAM_MEMBERS, {
    onCompleted: ({ manageTeamMembers }) => {
      let error = false;

      const errorCallback = () => {
        error = true;
      };

      manageTeamMembers?.add?.forEach((member) =>
        handleManageTeamMembersResponse(member, errorCallback)
      );
      manageTeamMembers?.delete?.forEach((member) =>
        handleManageTeamMembersResponse(member, errorCallback)
      );

      if (!error) {
        setShowAddUserModal(false);
      }
      setIsSaving(false);
    },
    onError: (error) => {
      setIsSaving(false);
      message.error(`User add failed: ${error.message}`);
    },
  });

  const [saveUser] = useMutation(USER_UPDATE, {
    async onCompleted({ updateUser }) {
      utils.loginredirect(user, history);

      userVar({ ...user, ...updateUser });
      userProfileVar({
        email: updateUser.emails[0].address,
        ...updateUser.profile,
      });
    },
  });

  const createNewProposal = ({ proposalType, proposalFile, proposalFileType }) => {
    setShowAddProposalModal(false);
    if (proposalType === 'template') {
      history.push(Path.CONTENT_LIBRARY);
    } else {
      if (!allowedEditing) {
        setDisabledEditingModal(true);
        // message.error('Proposals creation is only available on the desktop version.');
      } else {
        const pid = uuidv4();
        setNewExternalProposalId(pid);

        setExternalProposalFile(proposalFile);
        setExternalProposalFileType(proposalFileType);

        if (proposalType === 'external-proposal' || proposalType === 'external-proposal-doc') {
          setShowProspectFormModal(true);
        } else {
          createProposal({
            variables: {
              pid,
              topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
              additionalInfo: {
                monday: {
                  boardId: mondayBoardIdVar(),
                },
              },
            },
          })
            .then(({ data }) => {
              cache.evict('loadProposals');
              const {
                createProposal: { pid: newProposalId, _id },
              } = data;

              trackEvent('wizard-init', {
                id: _id,
                pid: newProposalId,
              });

              history.push(`/w/${newProposalId}`);
            })
            .catch((error) => {
              message.error('Proposal create failed');
            });
        }
      }
    }
  };

  const confirmDeleteProposal = () => {
    if (trash) {
      deleteProposal({
        variables: {
          pid: selectedProposalId,
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
          remove: true,
        },
      });
    } else {
      deleteProposal({
        variables: {
          pid: selectedProposalId,
          topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
        },
      });
    }
  };

  const duplicateProposal = ({ values, proposal }) => {
    const version = proposal.version || 0;
    if (version < 5) {
      return;
    }

    values = sanitizeObject(values);

    window.ga('send', 'event', 'prop-duplicated', proposal._id, window.location.href);
    trackEvent('dup-prop', {
      id: proposal._id,
      pid: proposal.pid,
      url: window.location.origin + '/pd/' + proposal._id,
    });

    const { client_name: newClientName, project_name: newProjectName } = values;
    setIsDuplicating(true);
    createProposal({
      variables: {
        pid: proposal.pid,
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${user?.teamId || user?._id}`,
        duplicate: {
          newProjectName: newProjectName,
          newClientName: newClientName,
        },
        additionalInfo: {
          monday: {
            boardId: mondayBoardIdVar(),
          },
        },
      },
    })
      .then((data) => {
        showDuplicateModal(false);
        if (data) {
          refetchProposal('', [], [], PROPOSAL_FILTER_ITEMS);
          setIsDuplicating(false);
          message.success('Proposal duplicated successfully');
        } else {
          message.error(`Problem duplicating proposal`);
        }
      })
      .catch((error) => {
        setIsDuplicating(false);
        message.error(`Problem duplicating proposal ${error.message}`);
      });
  };

  const retrieveProposal = async () => {
    const proposal = proposals.find((item) => item.pid === selectedProposalId);
    setIsRestoring(true);
    const retrieveProposalResponse = await updateProposal({
      variables: {
        topic: `${PROPOSAL_SUBSCRIPTION_TOPIC}_${
          proposal?.channel || proposal?.auid || proposal?.uid
        }`,
        updateType: 'restore',
        proposal: {
          id: proposal._id,
          _id: proposal._id,
          pid: proposal.pid,
          uid: proposal.uid,
          state: proposal.prevState,
          prevState: proposal.state,
          published: '',
        },
      },
    });
    const prevProposals = proposals.filter(
      (prop) => prop._id !== retrieveProposalResponse.data.updateProposal._id
    );
    let updatedProposals = [];
    updatedProposals = prevProposals.filter(
      (prop) => prop._id !== retrieveProposalResponse.data.updateProposal._id
    );
    proposalsVar(updatedProposals);
    message.success('Proposal restored successfully');
    setIsRestoring(false);
    showRestoreModal(false);
  };

  const createProposalSignup = () => {
    setUserInfoModal(false);
    setShowAddProposalModal(true);
  };

  const addUser = () => {
    setUserInfoModal(false);
    setShowAddUserModal(true);
  };

  const onAddUserCancel = () => {
    setShowAddUserModal(false);
  };

  const saveUserProfileInfo = (values) => {
    const {
      fullname,
      where,
      currency,
      password,
      dateFormat,
      defaultProposalFont,
      companyname,
      domain,
      ptype,
      otherptype,
      proposalLanguage,
      enableAITool,
      brandlogo,
      brandcolor,
      onBoardComplete,
      onboardingState,
      image,
    } = values;

    const profile = Object.assign(
      { ...user.profile },
      {
        name: fullname,
        where: where,
        language: proposalLanguage,
        terms: 'true',
        currency,
        enableAITool,
        defaultProposalFont,
        dateFormat,
        companyname,
        onBoardComplete,
        onboardingState,
        image,
      }
    );

    const branding = Object.assign(
      { ...user.branding },
      {
        buttonColor: brandcolor,
        companyLogo: brandlogo,
        font: defaultProposalFont,
      }
    );

    saveUser({
      variables: {
        user: {
          id: user._id,
          password: password,
          profile: profile,
          branding: branding,
          ptype: ptype || user.ptype,
          otherptype: otherptype || user.otherptype,
          domain,
        },
      },
    });
  };

  const getTrialTime = () => {
    if (!user) {
      return 666;
    }

    const timespan = new Date() - new Date(user.stopperAnchor || user.createdAt);
    const dayspan = timespan / 1000 / 60 / 60 / 24;

    return daystrial - dayspan;
  };

  return (
    <>
      {popConfirmDetails.show && (
        <Popconfirm
          title={popConfirmDetails.title}
          body={popConfirmDetails.body}
          visible={popConfirmDetails.show}
          cancelText={popConfirmDetails.cancelText}
          confirmText={popConfirmDetails.confirmText}
          modalClassName={popConfirmDetails.className}
          onCancel={() =>
            setPopConfirmDetails((prevState) => ({
              ...prevState,
              show: false,
            }))
          }
          onConfirm={popConfirmDetails.onConfirm}
        />
      )}

      {showAddProposalModal && (
        <NewProposalModal
          visible={showAddProposalModal}
          onCancel={() => setShowAddProposalModal(false)}
          createNewProposal={createNewProposal}
        />
      )}

      {showProspectFormModal && (
        <Modal
          className="simple-info-modal create-duplicate-modal"
          visible={showProspectFormModal}
          okText="CONTINUE"
          centered
          cancelText="CANCEL"
          closeIcon={<CloseIcon className="close-icon" />}
          footer={null}
          onCancel={() => setShowProspectFormModal(false)}
          maskClosable={false}>
          <h3 className="title">Create Proposal from File</h3>
          <div className="divider" />

          <div className="body">
            <Form
              form={form}
              layout="vertical"
              name="form_in_modal"
              className="form"
              initialValues={{
                modifier: 'public',
                project_name: 'Imported Proposal',
                client_name: clientName,
              }}>
              <Form.Item
                name="project_name"
                label="Project Name"
                rules={[
                  {
                    required: true,
                    message: 'Project name is required.',
                  },
                  {
                    whitespace: true,
                    message: 'Project name is required.',
                  },
                ]}>
                <Input />
              </Form.Item>
              <Form.Item
                name="client_name"
                label="Prospect Name"
                rules={[
                  {
                    required: true,
                    message: 'Prospect name is required',
                  },
                  {
                    whitespace: true,
                    message: 'Prospect name is required.',
                  },
                ]}>
                <AutoComplete
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  value={clientName}
                  onChange={(value) => onInputChange(!!value.trim() ? value : '')}>
                  {clientNames.map((client, clientIndex) => (
                    <Option key={`${client.value}${clientIndex}`} value={client.value}>
                      {client.value}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </Form>
          </div>

          <div className="button-wrapper">
            <Button
              className="large-btn secondary-btn"
              type="primary"
              loading={isLoading}
              onClick={onCreateNewFormClick}>
              Create
            </Button>
            <Button
              className="large-btn grey-btn"
              disabled={isLoading}
              onClick={onDeleteProposalFiles}>
              CANCEL
            </Button>
          </div>
        </Modal>
      )}

      {confirmModal && (
        <ConfirmModal
          title="Delete Proposal?"
          body={trash ? 'This action cannot be undone' : 'This proposal will be moved to trash.'}
          visible={confirmModal}
          cancelText="CANCEL"
          confirmText="DELETE"
          isSaving={isDeleting}
          onCancel={() => showConfirmModal(false)}
          onConfirm={confirmDeleteProposal}
        />
      )}

      {permissionErrorModal && (
        <ConfirmModal
          title="Cannot Delete Proposal."
          body={"You don't have permission to delete this proposal."}
          visible={permissionErrorModal}
          cancelText="Close"
          onCancel={() => showPermissionErrorModal(false)}
        />
      )}

      {duplicateModal && (
        <CreateDuplicateProposalModal
          modalType="Duplicate"
          user={user}
          visible={duplicateModal}
          onCancel={() => showDuplicateModal(false)}
          selectedProposal={proposals.find((item) => item.pid === selectedProposalId)}
          onConfirm={duplicateProposal}
          isLoading={isDuplicating}
        />
      )}

      {restoreModal && (
        <RestoreProposalModal
          modalType="Restore"
          title="Restore Proposal?"
          body="You are restoring deleted proposal"
          cancelText="CANCEL"
          confirmText="RESTORE"
          user={user}
          visible={restoreModal}
          onCancel={() => showRestoreModal(false)}
          selectedProposal={proposals.find((item) => item.pid === selectedProposalId)}
          isRestoring={isRestoring}
          onConfirm={retrieveProposal}
        />
      )}

      {generateInvoiceModal && (
        <GenerateInvoiceModal
          invoiceType={generateInvoiceModal}
          proposalId={selectedProposalId}
          organisations={user?.xero?.organisations || []}
          onCancel={() => setGenerateInvoiceModal(false)}
        />
      )}

      {userInfoModal && (
        <OnboardingModal
          user={user}
          refetchUser={refetchUser}
          saveUserProfileInfo={saveUserProfileInfo}
          createProposal={createProposalSignup}
          addUser={addUser}
          onCancel={() => setUserInfoModal(false)}
        />
      )}

      {showAddUserModal && (
        <AddUser
          user={user}
          plan={plan}
          usersTeam={teamMembers}
          visible={showAddUserModal}
          onCancel={onAddUserCancel}
          isSaving={isSaving}
          setPlan={setPlan}
          setShowAddUserModal={setShowAddUserModal}
          trial={getTrialTime() >= 0 ? true : false}
          onConfirm={(_, { membersToBeAdded, membersToBeDeleted }) => {
            setIsSaving(true);
            manageTeamMembers({
              variables: {
                membersToBeAdded,
                membersToBeDeleted,
              },
            });
          }}
        />
      )}

      {!!showShareProposalModal && (
        <ShareWithModal
          visible={!!showShareProposalModal}
          teamMembers={teamMembers.filter((teamMember) => teamMember.roles[0] === 'editor')}
          proposal={showShareProposalModal}
          onCancel={() => setShowShareProposalModal('')}
        />
      )}

      {moveModal && (
        <MoveLibraryModal
          visible={moveModal}
          onCancel={() => showMoveModal(false)}
          template={proposals.find((prop) => prop._id === selectedProposalId)}
          refetchTemplates={() => {}}
          isDashboard={true}
        />
      )}

      {disabledEditingModal && (
        <Popconfirm
          visible={true}
          title="Bigger Screen Recommended"
          body="We noticed you're using a small device. Please use a desktop for the best proposal creation and editing experience."
          closable={false}
          cancelText={'Close'}
          onCancel={() => setDisabledEditingModal(false)}
          footer={null}
        />
      )}
    </>
  );
};

DashboardModals.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  trash: PropTypes.bool,
  proposals: PropTypes.instanceOf(Array).isRequired,
  teamMembers: PropTypes.instanceOf(Array).isRequired,
  selectedProposalId: PropTypes.string,
  updateProposal: PropTypes.func.isRequired,
  popConfirmDetails: PropTypes.instanceOf(Object).isRequired,
  setPopConfirmDetails: PropTypes.func.isRequired,
  showAddProposalModal: PropTypes.bool,
  setShowAddProposalModal: PropTypes.func.isRequired,
  confirmModal: PropTypes.bool,
  showConfirmModal: PropTypes.func.isRequired,
  showAddUserModal: PropTypes.bool,
  setShowAddUserModal: PropTypes.func.isRequired,
  duplicateModal: PropTypes.bool,
  showDuplicateModal: PropTypes.func.isRequired,
  setUserInfoModal: PropTypes.func.isRequired,
  permissionErrorModal: PropTypes.bool,
  showPermissionErrorModal: PropTypes.func.isRequired,
  restoreModal: PropTypes.bool,
  showRestoreModal: PropTypes.func.isRequired,
  generateInvoiceModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setGenerateInvoiceModal: PropTypes.func.isRequired,
  userInfoModal: PropTypes.bool,
  showShareProposalModal: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  setShowShareProposalModal: PropTypes.func.isRequired,
  moveModal: PropTypes.bool,
  showMoveModal: PropTypes.func.isRequired,
};

export default DashboardModals;
