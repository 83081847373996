import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path
      fill="#05034D"
      d="M6.7,7.3H2C1.6,7.3,1.3,7,1.3,6.7V2c0-0.4,0.3-0.7,0.7-0.7h4.7C7,1.3,7.3,1.6,7.3,2v4.7C7.3,7,7,7.3,6.7,7.3z M2.7,6H6V2.7H2.7V6z"
    />
    <path
      fill="#05034D"
      d="M14,7.3H9.3C9,7.3,8.7,7,8.7,6.7V2c0-0.4,0.3-0.7,0.7-0.7H14c0.4,0,0.7,0.3,0.7,0.7v4.7C14.7,7,14.4,7.3,14,7.3 z M10,6h3.3V2.7H10V6z"
    />
    <path
      fill="#05034D"
      d="M14,14.7H9.3c-0.4,0-0.7-0.3-0.7-0.7V9.3C8.7,9,9,8.7,9.3,8.7H14c0.4,0,0.7,0.3,0.7,0.7V14 C14.7,14.4,14.4,14.7,14,14.7z M10,13.3h3.3V10H10V13.3z"
    />
    <path
      fill="#05034D"
      d="M6.7,14.7H2c-0.4,0-0.7-0.3-0.7-0.7V9.3C1.3,9,1.6,8.7,2,8.7h4.7C7,8.7,7.3,9,7.3,9.3V14 C7.3,14.4,7,14.7,6.7,14.7z M2.7,13.3H6V10H2.7V13.3z"
    />
  </svg>
);

const MoreSqaureIcon = (props) => <Icon component={svg} {...props} />;

export default MoreSqaureIcon;
