import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import getFragmentFromSelection from 'draft-js/lib/getFragmentFromSelection';
import copy from 'copy-to-clipboard';
import { useLazyQuery } from '@apollo/client';
import { Dropdown, Menu, Row, Col, Input, Tooltip, Radio, Button, message } from 'antd';
import { EditorState, Modifier, SelectionState, ContentState } from 'draft-js';

import ExpandIcon from 'components/Icons/ExpandIcon';
// import DeExpandIcon from 'components/Icons/DeExpandIcon';
// import RefreshIcon from 'components/Icons/RefreshIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';
import BulbIcon from 'components/Icons/BulbIcon';
// import RectangleLoaderIcon from 'components/Icons/RectangleLoaderIcon';
// import AIResetIcon from 'components/Icons/AIResetIcon';
// import KeepItIcon from 'components/Icons/KeepItIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import AIBotIcon from 'components/Icons/AIBotIcon';
import AIIcon from 'components/Icons/AIIcon';
import MoreIcon from 'components/Icons/MoreIcon';
import PlayIcon from 'components/Icons/PlayIcon';
import TickIcon from 'components/Icons/TickIcon';
import UndoThinIcon from 'components/Icons/UndoThinIcon';
import RedoThinIcon from 'components/Icons/RedoThinIcon';
import useOutsideClick from 'hooks/useOutsideClick';
import { AI_TONE_MENU, AI_LENGTH_MENU, AI_MORE_MENU } from 'constants/index';
import { OPEN_AI_RECOMMENDATIONS } from 'graphql/queries/proposalQueries';

const AIToolBar = ({
  id,
  style,
  askAI,
  editorState,
  dispatch,
  handleEditorChange,
  language,
  clearFormatting,
  aiLengthMenu,
  setAskAI,
  displayAIToolBar,
  displayAIToolBarCursor,
  editorRef,
  formatBackgroundSelectionColor,
  aiSaving,
  zoomValue,
}) => {
  const [aborterRef, setAbortRef] = useState(new AbortController());
  const [aiToolBarVisible, setAIToolBarVisible] = useState(false);
  const [recommendationList, setRecommendationList] = useState([]);
  const [finalizeSelection, setFinalizeSelection] = useState(false);
  const [selectedTones, setSelectedTones] = useState([]);
  const [selectedLength, setSelectedLength] = useState('');
  const [selectedMores, setSelectedMores] = useState([]);
  const [aiCommand, setAICommand] = useState('');
  const [aiToolBarOverflow, setAIToolBarOverflow] = useState('');
  const [undoRedoHistory, setUndoRedoHistory] = useState({
    previous: '',
    current: '',
    future: '',
  });

  const aiToolBarRef = useRef(null);

  const adjustAIToolBarPosition = () => {
    const editorWrapperElement = document.getElementById('editor-wrapper');
    const aiToolBarElement = document.getElementById('ai-toolbar-initial');
    let aiToolBarPosition = 'left';

    const editorWrapperRect = editorWrapperElement.getBoundingClientRect();
    const aiToolbarRect = aiToolBarElement.getBoundingClientRect();

    // Check if the div is overflowing on the right
    if (aiToolbarRect.right > editorWrapperRect.right || Math.floor(aiToolbarRect.right) === 1033) {
      aiToolBarPosition = 'right';
    }

    // Check if the div is overflowing on the bottom
    if (aiToolbarRect.bottom > editorWrapperRect.bottom) {
      if (aiToolBarPosition === 'right') {
        aiToolBarPosition = 'rightBottom';
      } else {
        aiToolBarPosition = 'bottom';
      }
    }

    setAIToolBarOverflow(aiToolBarPosition);
  };

  useEffect(() => {
    if (askAI) {
      adjustAIToolBarPosition();
    } else {
      setAICommand('');
    }
  }, [askAI]);

  // useEffect(() => {
  //   console.log(selectionState, askAI);
  //   if (selectionState && askAI) {
  //     const currentBlockKey = selectionState.getFocusKey()
  //     const selectionStart = selectionState.getStartOffset() || 0;
  //     const selectionEnd = selectionState.getEndOffset();
  //     console.log(selectionStart, selectionState, selectionStart, selectionEnd, '=-=-=');

  //     const newSelectionState = selectionState.merge({
  //       anchorKey: currentBlockKey,
  //       focusKey: currentBlockKey,
  //       focusOffset: selectionStart,
  //       anchorOffset: selectionEnd,
  //     })

  //     dispatch({ type: 'setEditorState', editorState: EditorState.forceSelection(editorState, newSelectionState) });
  //   }
  //   // else {
  //   //   dispatch({ type: 'setSelectionState', selectionState: '' });
  //   // }
  // }, [selectionState, askAI, dispatch]);

  const [
    loadRecommendations,
    { loading: isFetchingRecommendations, refetch: refetchRecommendations },
  ] = useLazyQuery(OPEN_AI_RECOMMENDATIONS, {
    fetchPolicy: 'network-only',
    context: {
      fetchOptions: {
        signal: aborterRef.signal,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRecommendationList(data?.openAIRecommendations || []);
    },
  });

  const fetchAIRecommendation = () => {
    setAIToolBarVisible('');
    const selected = getFragmentFromSelection(editorState);
    const selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';
    setRecommendationList([]);
    loadRecommendations({
      variables: {
        prompt: selectedText,
        tone: new Intl.ListFormat().format(selectedTones),
        recommendationSize: aiLengthMenu[selectedLength.key],
        command: aiCommand,
        enhance: selectedMores.includes('Enhanced Writing'),
        grammar: selectedMores.includes('Grammar Check'),
      },
    });
  };

  // useEffect(() => {
  //   if (selectedAIOption || recommendationSize) {
  //     const selected = getFragmentFromSelection(editorState);
  //     const selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';

  //     loadRecommendations({
  //       variables: {
  //         prompt: selectedText,
  //         tone: selectedAIOption?.props?.children[1],
  //         recommendationSize,
  //       },
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedAIOption, recommendationSize]);

  // const handleButtonClick = (aiToolBarMenuItem) => {
  //   const aiOption = (
  //     <>
  //       {aiToolBarMenuItem.icon}
  //       {aiToolBarMenuItem.label}
  //     </>
  //   );
  //   setSelectedAIOption(aiOption);
  // };

  const updateSelectedText = async (selectedText, type) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const start = selection.getStartOffset();
    let newEditorState = clearFormatting(editorState, false);

    const newSelectionState = new SelectionState({
      anchorKey: selection.anchorKey,
      anchorOffset: start,
      focusKey: selection.anchorKey,
      focusOffset: (selectedText?.trim()?.length || 0) + start,
      hasFocus: true,
    });

    const selected = getFragmentFromSelection(editorState);
    const oldSelectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';

    const currentSelection = new SelectionState({
      anchorKey: selection.anchorKey,
      anchorOffset: selection.anchorOffset,
      focusKey: selection.focusKey,
      focusOffset: (oldSelectedText?.trim()?.length || 0) + selection.anchorOffset,
      hasFocus: true,
    });

    const newContentState = Modifier.replaceText(
      contentState,
      currentSelection, // replaced this selection with selected text with trim if required
      selectedText.trim(),
      editorState.getCurrentInlineStyle()
    );

    newEditorState = EditorState.push(newEditorState, newContentState, 'replace-text', selection);

    let newNewEditorState = EditorState.forceSelection(
      newEditorState,
      newEditorState.getSelection().merge(newSelectionState)
    );

    // displayAIToolBar.current = '';
    // displayAIToolBarCursor.current = '';
    newNewEditorState = formatBackgroundSelectionColor(newNewEditorState, true);
    dispatch({
      type: 'setAISelectionState',
      aISelectionState: newEditorState.getSelection().merge(newSelectionState),
    });
    // dispatch({ type: 'setEditorState', editorState: newNewEditorState });
    dispatch({ type: 'setSelectionState', selectionState: '' });
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
    setRecommendationList([]);
    setSelectedTones([]);
    setSelectedLength('');
    setSelectedMores([]);
    setAskAI(false);

    if (!type) {
      setUndoRedoHistory({
        previous: oldSelectedText,
        current: selectedText,
        future: '',
      });
    } else if (type === 'undo') {
      setUndoRedoHistory({
        previous: '',
        current: selectedText,
        future: oldSelectedText,
      });
    } else if (type === 'redo') {
      setUndoRedoHistory({
        previous: oldSelectedText,
        current: selectedText,
        future: '',
      });
    }

    console.log('75');

    aiSaving.current = true;
    // editorRef.current = newNewEditorState;
    handleEditorChange({ es: newNewEditorState, donotUpdateInlinePosition: true });
  };

  const handleUndoRedo = (e, text, type) => {
    e.stopPropagation();
    e.preventDefault();
    if (!text) {
      return;
    }
    updateSelectedText(text, 'undo');
  };

  const handleRefetchRecommendations = () => {
    const selected = getFragmentFromSelection(editorState);
    const selectedText = selected ? selected.map((x) => x.getText()).join('\n') : '';

    setFinalizeSelection(false);
    setAIToolBarVisible('');

    const refetchedData = refetchRecommendations?.({
      variables: {
        prompt: selectedText,
        tone: new Intl.ListFormat().format(selectedTones),
        recommendationSize: aiLengthMenu[selectedLength.key],
        command: aiCommand,
        enhance: selectedMores.includes('Enhanced Writing'),
        grammar: selectedMores.includes('Grammar Check'),
      },
    });

    setRecommendationList(refetchedData?.openAIRecommendations || []);
  };

  const resetRecommendations = () => {
    setFinalizeSelection(false);
    setUndoRedoHistory({
      previous: '',
      current: '',
      future: '',
    });
    dispatch({ type: 'setSelectionState', selectionState: '' });
    dispatch({ type: 'setEditorReadOnly', editorReadOnly: false });
  };

  const handleAIClickCondition = (event) => {
    console.log(event.target.className, 'event.target.className');

    return (
      !event?.target?.className?.match?.(/ant-dropdown-menu/i) &&
      !event?.target?.className?.match?.(/horizontal-divider/i) &&
      !event?.target?.className?.match?.(/vertical-divider/i) &&
      !event?.target?.className?.match?.(/ai-toolbar/i)
    );
  };

  useOutsideClick(aiToolBarRef, resetRecommendations, handleAIClickCondition);

  const selectOptions = (key, stateTypes, setState, label) => {
    const tone = stateTypes.includes(label);
    setRecommendationList([]);
    setState(tone ? stateTypes.filter((stateType) => stateType !== label) : [...stateTypes, label]);
  };

  const toneMenu = (
    <Menu
      className={`ai-toolbar-dropdown-menu${recommendationList.length ? '-list' : ''}`}
      multiple={true}
      selectedKeys={AI_TONE_MENU.filter((menu) => selectedTones.includes(menu.label)).map(
        (menu) => menu.key
      )}
      onClick={({ key, item }) =>
        selectOptions(key, selectedTones, setSelectedTones, item?.props?.children[1][1])
      }>
      {AI_TONE_MENU.map((aiToneMenuItem) => (
        <Menu.Item
          key={aiToneMenuItem.key}
          disabled={selectedTones.length === 3 && !selectedTones.includes(aiToneMenuItem.label)}>
          {aiToneMenuItem.icon}
          {aiToneMenuItem.label}
          {selectedTones.includes(aiToneMenuItem.label) ? (
            <TickIcon className="ai-toolbar-dropdown-menu-check-icon" />
          ) : (
            ''
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  const lengthMenu = (
    <Menu
      className={`ai-toolbar-dropdown-menu${recommendationList.length ? '-list' : ''}`}
      onClick={({ key }) => setSelectedLength(AI_LENGTH_MENU[key])}>
      {AI_LENGTH_MENU.map((aiLengthMenuItem, aiLengthMenuItemIndex) => (
        <Menu.Item key={aiLengthMenuItem.key}>
          <Radio checked={selectedLength.label === aiLengthMenuItem.label} />
          <span>{aiLengthMenu[aiLengthMenuItemIndex]}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  const moreMenu = (
    <Menu
      className={`ai-toolbar-dropdown-menu${
        recommendationList.length ? '-list' : ''
      } ai-toolbar-dropdown-menu-more`}
      multiple={true}
      selectedKeys={AI_MORE_MENU.filter((menu) => selectedMores.includes(menu.label)).map(
        (menu) => menu.key
      )}
      onClick={({ key, item }) =>
        selectOptions(key, selectedMores, setSelectedMores, item?.props?.children[1][0])
      }>
      {AI_MORE_MENU.map((aiMoreMenuItem) => (
        <Menu.Item key={aiMoreMenuItem.key}>
          {aiMoreMenuItem.label}
          {selectedMores.includes(aiMoreMenuItem.label) ? <TickIcon /> : ''}
          {aiMoreMenuItem.icon}
        </Menu.Item>
      ))}
    </Menu>
  );

  let customAskAiStyle =
    aiToolBarOverflow === 'right' || aiToolBarOverflow === 'rightBottom'
      ? {
          top: style.bottom + 10,
          left: 0,
          right: style.right?.aiRight + 70,
        }
      : aiToolBarOverflow === 'bottom'
      ? {
          top: style.bottom - 50,
          left: style.left?.aiLeft ? style.left?.aiLeft + 70 : 0,
          right: style?.right?.aiRight ? style.right?.aiRight + 70 : 0,
        }
      : // : !Object.keys(style).length || (style.left === 0 && style.bottom === 0 && style.top === 0) ?
        //   { top: id.match(/sub_title/)?.length ? 90 : 40, left: 0 }
        {
          top: id.match(/sub_title/)?.length
            ? 30
            : style.bottom + (id.match(/_title/)?.length ? 2 : 10),
          // left: style.right ? style.right + 70 : 0, //id.match(/sub_title/)?.length ? 0 : style.left?.aiLeft ? style.left?.aiLeft : 0, // for alignment on left
          // right: style.right ? style.right : 0,
          left: style.left?.aiLeft || 0,
        };

  let customAiStyle = {
    top: id.match(/sub_title/)?.length ? 30 : style.bottom + (id.match(/_title/)?.length ? 2 : 10),
    left: style.left?.aiLeft || 0,
  };

  const handleAiToolBarVisibility = (toolbarSelected) => {
    setAIToolBarVisible(
      !aiToolBarVisible
        ? toolbarSelected
        : aiToolBarVisible !== toolbarSelected
        ? toolbarSelected
        : ''
    );
  };

  const copyRecommendation = (recommendation) => {
    displayAIToolBar.current = '';
    displayAIToolBarCursor.current = '';
    copy(recommendation);
    setAIToolBarVisible('');
    message.success('Copied to Clipboard');
  };

  const stopRecommendationQuery = () => {
    aborterRef.abort();
    setAbortRef(new AbortController());
    setAIToolBarVisible('');
  };

  const aIInputKeyPress = (event) => {
    if (event.key === 'Enter' && event.shiftKey) {
      // Do Nothing;
    } else if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      fetchAIRecommendation();
      setAICommand(aiCommand);
    }
  };

  const generateButtonDisabled = displayAIToolBarCursor.current
    ? !aiCommand
    : !selectedTones.length && !selectedLength && !aiCommand && !selectedMores.length;
  const selection = editorState?.getSelection();
  const anchorKey = selection?.getAnchorKey();
  const currentContent = editorState?.getCurrentContent();
  const currentBlock = currentContent?.getBlockForKey(anchorKey);

  if (currentBlock) {
    const contentState = ContentState.createFromBlockArray([currentBlock]);
    const ent = currentBlock.getEntityAt(0);

    if (ent) {
      const entity = contentState.getEntity(ent);
      const type = entity?.type;
      const data = entity?.data;

      if (data && data.data && (type === 'table' || type === 'testimonial' || type === 'form')) {
        return null;
      }
    }
  }

  if (zoomValue !== 100) {
    if (askAI) {
      customAskAiStyle = {
        top: customAskAiStyle.top / (zoomValue / 100),
        left: customAskAiStyle.left / (zoomValue / 100),
      };
    } else {
      customAiStyle = {
        top: customAiStyle.top / (zoomValue / 100),
        left: customAiStyle.left / (zoomValue / 100),
      };
    }
  }

  return (
    <div
      style={askAI ? customAskAiStyle : customAiStyle}
      ref={aiToolBarRef}
      id="ai-toolbar"
      className={`ai-toolbar ${
        finalizeSelection ? 'ai-toolbar-extended' : askAI ? 'ai-toolbar-ask-ai' : ''
      }`}
      onMouseDown={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}>
      {!askAI ? (
        <Row
          className={`ai-toolbar-bot ${aiSaving.current ? 'disabled' : ''}`}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            !aiSaving.current && setAskAI(true);
          }}>
          <Tooltip title="Ask AI">
            <Col className="ask-ai-icon-container">
              <AIIcon />
            </Col>
            <Col className="ask-ai-icon-test">AI</Col>
          </Tooltip>
          {undoRedoHistory.current ? (
            <span className="ask-ai-icon-undo-redo-container">
              <Col>
                <Tooltip title="Undo">
                  <UndoThinIcon
                    className={!undoRedoHistory.previous && 'disabled'}
                    onClick={(e) => handleUndoRedo(e, undoRedoHistory.previous, 'undo')}
                  />
                </Tooltip>
                <Tooltip title="Redo">
                  <RedoThinIcon
                    className={!undoRedoHistory.future && 'disabled'}
                    onClick={(e) => handleUndoRedo(e, undoRedoHistory.future, 'redo')}
                  />
                </Tooltip>
              </Col>
            </span>
          ) : null}
        </Row>
      ) : (
        <Col id="ai-toolbar-initial" className="ai-toolbar-initial">
          <Row className={isFetchingRecommendations ? 'ai-toolbar-input-ask-ai' : ''}>
            <Input.Group className="ai-toolbar-input-group-ask-ai">
              <Col>
                <AIBotIcon />
              </Col>
              <Col>
                <Input.TextArea
                  className="ai-toolbar-input-text-area"
                  placeholder="Ask AI to write text for..."
                  onMouseDown={(e) => e.stopPropagation()}
                  onChange={(e) => setAICommand(e.target.value)}
                  onKeyPress={aIInputKeyPress}
                  value={aiCommand}
                  autoFocus
                />
              </Col>
              <Col>
                <Tooltip
                  title="Generate"
                  onClick={!generateButtonDisabled ? fetchAIRecommendation : () => {}}>
                  <PlayIcon
                    className={generateButtonDisabled ? 'disabled' : ''}
                    style={{ color: 'rgba(0,0,0,.45)' }}
                  />
                </Tooltip>
              </Col>
            </Input.Group>
          </Row>
          <Row className="ai-toolbar-options">
            <Row justify="space-between">
              <Col>
                <Dropdown
                  overlay={toneMenu}
                  overlayClassName={`ai-toolbar-dropdown ${
                    language === 'hebrew' ? 'language-hebrew' : ''
                  }`}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placement={style.right ? 'bottomRight' : 'bottomLeft'}
                  visible={aiToolBarVisible === 'tones'}
                  onVisibleChange={(e) => handleAiToolBarVisibility('tones')}
                  onMouseDown={(e) => e.preventDefault()}
                  trigger={['click']}>
                  <div className="ai-toolbar-dropdown-placeholder">
                    <BulbIcon />
                    <Row className="ai-toolbar-icon-text">
                      <Col>Tone&nbsp;</Col>
                      {selectedTones.length ? (
                        <Col>
                          <span>({selectedTones.length})</span>
                          <CloseIcon
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedTones([]);
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <DropdownIcon className="ai-toolbar-dropdown-icon" />
                  </div>
                  {/* )} */}
                </Dropdown>
              </Col>
              <Col>
                <Dropdown
                  overlay={lengthMenu}
                  overlayClassName={`ai-toolbar-dropdown ${
                    language === 'hebrew' ? 'language-hebrew' : ''
                  }`}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placement={style.right ? 'bottomRight' : 'bottomLeft'}
                  visible={aiToolBarVisible === 'lengths'}
                  onVisibleChange={(e) => handleAiToolBarVisibility('lengths')}
                  trigger={['click']}>
                  <div className="ai-toolbar-dropdown-placeholder">
                    <ExpandIcon />
                    <Row className="ai-toolbar-icon-text">
                      <Col>Length&nbsp;</Col>
                      {`  `}
                      {selectedLength ? (
                        <Col>
                          <CloseIcon
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedLength('');
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                    <DropdownIcon className="ai-toolbar-dropdown-icon" />
                  </div>
                </Dropdown>
              </Col>
              <Col>
                <Dropdown
                  overlay={moreMenu}
                  overlayClassName={`ai-toolbar-dropdown ${
                    language === 'hebrew' ? 'language-hebrew' : ''
                  }`}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placement={style.right ? 'bottomRight' : 'bottomLeft'}
                  visible={aiToolBarVisible === 'mores'}
                  onVisibleChange={(e) => handleAiToolBarVisibility('mores')}
                  trigger={['click']}>
                  <div className="ai-toolbar-dropdown-placeholder">
                    <MoreIcon />
                    <Row className="ai-toolbar-icon-text">
                      <Col>More&nbsp;</Col>
                      {selectedMores.length ? (
                        <Col>
                          <span>({selectedMores.length})</span>
                          <CloseIcon
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedMores([]);
                            }}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </div>
                </Dropdown>
              </Col>
            </Row>
            {/* <Row flex={1}>0/10</Row> maybe used later when with have request limits */}
          </Row>

          {isFetchingRecommendations ? (
            <Row justify="space-between" className="ai-toolbar-generating">
              <Row align="middle">
                <AIBotIcon />
                <Row className="ai-toolbar-icon-text"> AI is writing...</Row>
              </Row>
              <Row>
                <Button onClick={stopRecommendationQuery}>Stop</Button>
              </Row>
            </Row>
          ) : null}

          {recommendationList.length ? (
            <Col className="ai-toolbar-suggestion-container">
              <Row justify="space-between" className="ai-toolbar-suggestion-header">
                <Row align="middle">
                  <Col className="ai-toolbar-icon-text">Suggestions</Col>
                </Row>
                <Row>
                  <Button onClick={handleRefetchRecommendations}>Regenerate</Button>
                </Row>
              </Row>
              <Row className="ai-toolbar-suggestion-list">
                {recommendationList.map((recommendationListItem) => (
                  <Row className="ai-toolbar-suggestion-body">
                    <Row className="ai-toolbar-suggestion-content">
                      {recommendationListItem.text}
                    </Row>
                    <Row justify="space-between" className="ai-toolbar-suggestion-footer">
                      <Row align="middle">{selectedTones?.join(', ')}</Row>
                      <Row align="middle" className="ai-toolbar-suggestion-options">
                        <Button onClick={() => copyRecommendation(recommendationListItem.text)}>
                          COPY
                        </Button>
                        <Button onClick={() => updateSelectedText(recommendationListItem.text)}>
                          INSERT
                        </Button>
                      </Row>
                    </Row>
                  </Row>
                ))}
              </Row>
            </Col>
          ) : null}
        </Col>
      )}
    </div>
  );
};

AIToolBar.defaultProps = {
  bodyFont: '',
  zoomValue: 100,
  askAI: false,
  displayAIToolBar: { current: '' },
  displayAIToolBarCursor: { current: '' },
  aiSaving: { current: false },
};

AIToolBar.propTypes = {
  style: PropTypes.instanceOf(Object).isRequired,
  aiLengthMenu: PropTypes.instanceOf(Array),
  setAskAI: PropTypes.func,
  displayAIToolBar: PropTypes.instanceOf(Object),
  displayAIToolBarCursor: PropTypes.instanceOf(Object),
  aiSaving: PropTypes.instanceOf(Object),
};

export default AIToolBar;
