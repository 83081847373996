import React from 'react';
import Icon from '@ant-design/icons';

const svg = (props) => {
  return (
    <svg className="direction-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <g className="group-right">
        <path d="M3.8,6.1h9.7L12,7.5c-0.4,0.4-0.4,1.2,0,1.6c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.6-0.1,0.8-0.3L17,5.7 c0.4-0.4,0.4-1.2,0-1.6l-3.4-3.4c-0.4-0.4-1.2-0.4-1.6,0s-0.4,1.2,0,1.6l1.5,1.5H3.8c-0.6,0-1.1,0.5-1.1,1.1S3.2,6.1,3.8,6.1z" />
      </g>
      <g className="group-left">
        <path d="M16.2,13.9H6.5L8,12.5c0.4-0.4,0.4-1.2,0-1.6c-0.4-0.4-1.2-0.4-1.6,0L3,14.3c-0.4,0.4-0.4,1.2,0,1.6l3.4,3.4 c0.2,0.2,0.5,0.3,0.8,0.3s0.6-0.1,0.8-0.3c0.4-0.4,0.4-1.2,0-1.6l-1.5-1.5h9.7c0.6,0,1.1-0.5,1.1-1.1 C17.3,14.4,16.8,13.9,16.2,13.9z" />
      </g>
    </svg>
  );
};

const DirectionalIcon = (props) => <Icon component={svg} {...props} />;

export default DirectionalIcon;
