import React, { useState, useEffect } from 'react';
import { Modal, Button, Upload, message, Progress, Row, Col } from 'antd';
import PropTypes from 'prop-types';

import { useMutation, useReactiveVar } from '@apollo/client';
import { userVar } from 'graphql/cache';
import { ADD_LIBRARY_COVER, ADD_LIBRARY_MEDIA } from 'graphql/mutations/contentLibraryMutations';
import { resizeImageForS3 } from 'utils/uploadToS3';

import Popconfirm from 'components/PopConfirm';

import Uploader from 'components/Uploader';
import UploadCloudIcon from 'components/Icons/UploadCloudIcon';
import ArrowTop from 'components/Icons/ArrowTop';
import CloseIcon from 'components/Icons/CloseIcon';

import './UploadContentLibraryItem.scss';

const { Dragger } = Upload;

const UploadContentLibraryItem = ({
  cancelUploadModal,
  selectedCoverType,
  showUploadModal,
  selectedFolderKey,
  onSuccess,
  type,
}) => {
  const [fileList, setFileList] = useState([]);

  const [upload, uploadStatus] = Uploader();

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        if (fileList.length > 0 && showUploadModal) {
          makeEscapeModal(true);
        }
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const user = useReactiveVar(userVar);
  const [addContentCover] = useMutation(ADD_LIBRARY_COVER);
  const [addContentMedia] = useMutation(ADD_LIBRARY_MEDIA);

  const onCancelModal = () => {
    setFileList([]);
    cancelUploadModal();
  };

  const onUpload = async (files) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks

    // const userCovers = covers.filter(
    //   cover => cover.uploadedBy === 'user' && cover.uid === user._id
    // );
    //
    // // const { ADMIN } = Meteor.settings.public;
    // const ADMIN = '';

    // if (contentLibraryItems >= 5 && ADMIN.indexOf(user._id) === -1) {
    //   return message.error('You can upload upto 5 covers only.');
    // }
    //

    // if (!selectedFolderKey) {
    //   return message.error('Select folder before uploading');
    // }

    const size =
      selectedCoverType === 'full-page'
        ? { width: 900, height: 1250 }
        : { width: 900, height: 675 };
    let filteredAndResizedFiles;
    try {
      filteredAndResizedFiles = await Promise.all(
        files.flatMap(async (file) => {
          return await resizeImageForS3({ file, size, path: type });
        })
      );
    } catch (e) {
      return message.error(e.message);
    }

    if (!filteredAndResizedFiles.length) return;
    const urls = await upload(filteredAndResizedFiles, `${type}/${user._id}`);
    await Promise.all(
      urls.map(async ({ url, filename, filetype }) => {
        if (type === 'covers') {
          const cover = {
            img: url,
            alt: url.substring(url.lastIndexOf('/') + 1),
            uploadedBy: 'user',
            uid: user._id,
            type: selectedCoverType === 'full-page' ? 'full-page' : 'top',
            createdAt: new Date(),
          };

          if (user && user.teamId) {
            cover.auid = user.teamId;
          }

          await addContentCover({
            variables: {
              cover,
              folderKey: selectedFolderKey,
            },
          });

          message.success(
            `${
              selectedCoverType === 'full-page' ? 'FULL PAGE COVER' : 'TOP COVER'
            } cover uploaded successfully`
          );
        } else if (type === 'media') {
          const media = {
            src: url,
            uploadedBy: 'user',
            uid: user._id,
            type: filetype,
            name: filename,
            createdAt: new Date(),
          };

          if (user && user.teamId) {
            media.auid = user.teamId;
          }

          await addContentMedia({
            variables: {
              media,
              folderKey: selectedFolderKey,
            },
          });

          message.success(`Media uploaded successfully`);
        }
      })
    );
    onCancelModal();

    if (onSuccess) {
      onSuccess(urls);
    }
  };

  const handleUpload = async () => {
    await onUpload(fileList);
  };

  const props = {
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file, files) => {
      setFileList([...fileList, ...files]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Modal
        className="confirm-modal upload-content-library-item-modal"
        footer={null}
        visible={showUploadModal}
        onCancel={() => {
          fileList.length > 0 ? setShowModal(true) : onCancelModal;
        }}
        width={720}
        closeIcon={<CloseIcon className="close-icon" onClick={onCancelModal} />}
        centered>
        {uploadStatus.status === 'uploading' ? (
          <>
            <h3 style={{ fontSize: '24px', fontWight: 600, textAlign: 'center' }}>Uploading</h3>
            <Progress
              percent={uploadStatus.percent}
              status="active"
              showInfo={false}
              strokeWidth={3}
              strokeColor="#F03F3B"
            />
          </>
        ) : (
          <div className="select-image">
            <h3 className="title">
              {type === 'covers'
                ? `UPLOAD ${selectedCoverType === 'full-page' ? 'FULL PAGE COVER' : 'COVER PHOTO'}`
                : 'MEDIA'}
            </h3>

            <Dragger
              accept={
                'image/x-png,image/*,image/png,image/bmp,image/jpeg,image/svg+xml,.jpg, .jpeg, .png' +
                (type === 'media' ? ', .pdf, .doc, .docx, .gif, .xls, .xlsx' : '')
              }
              {...props}>
              <Col className="select-image-uploader">
                <UploadCloudIcon />
                <p className="select-image-title">
                  {fileList
                    ? `Total number of files ${fileList.length}`
                    : `Start by adding your files`}
                </p>
                <p className="select-image-info">
                  Drag &amp; drop your files or upload from your computer
                </p>
                <p className="select-image-details">Maximum {type === 'covers' ? 1.5 : 3}MB</p>
                {type === 'covers' ? (
                  <span className="image-size">
                    Recommended image sizes - Small: 465px height x 900px width <br />
                    Large: 1250px height x 900px width
                  </span>
                ) : (
                  <span className="image-size">
                    Recommended image size - 465px height x 900px width
                  </span>
                )}
                <Row className="select-image-upload-text">
                  <Button
                    className="large-btn transparent-btn"
                    icon={<ArrowTop className="select-image-upload-icon" />}>
                    {type === 'covers'
                      ? `UPLOAD ${
                          selectedCoverType === 'full-page' ? 'FULL PAGE COVER' : 'COVER PHOTO'
                        }`
                      : 'UPLOAD MEDIA'}
                  </Button>
                </Row>
              </Col>
            </Dragger>

            <div className="button-wrapper">
              <Button
                key="back"
                className="large-btn secondary-btn"
                onClick={handleUpload}
                loading={uploadStatus.status === 'uploading'}
                disabled={fileList.length === 0}>
                {uploadStatus.status === 'uploading' ? 'Uploading' : 'Start Upload'}
              </Button>
              <Button className="large-btn grey-btn" onClick={onCancelModal}>
                CANCEL
              </Button>
            </div>
          </div>
        )}
      </Modal>
      <>
        {showModal && fileList.length > 0 && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
            }}
            onConfirm={onCancelModal}
            footer={null}
          />
        )}
      </>
    </>
  );
};

UploadContentLibraryItem.defaultProps = {
  selectedCoverType: 'full-page',
  showUploadModal: false,
};
UploadContentLibraryItem.propTypes = {
  selectedCoverType: PropTypes.string,
  cancelUploadModal: PropTypes.func.isRequired,
  // covers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showUploadModal: PropTypes.bool,
};

export default UploadContentLibraryItem;
