import React, { useState, useEffect } from 'react';
import { Modal, Button, Divider, message } from 'antd';
import PropTypes from 'prop-types';

import Popconfirm from 'components/PopConfirm';
import CloseIcon from 'components/Icons/CloseIcon';
import FileCopier from 'components/FileCopier';
import Uploader from 'components/Uploader';
import { SelectImages, GridImages, ContentLibrary } from './components';
import { resizeImageForS3 } from 'utils/uploadToS3';

import './GalleryEditModal.scss';

const GalleryEditModal = ({
  visible,
  onCancel,
  onConfirm,
  galleryEdit,
  dispatch,
  prop,
  savingRichEditorModal,
}) => {
  const [upload, uploadStatus] = Uploader();
  const [copy, copying] = FileCopier();
  const [showProgress, toggleShowProgress] = useState(false);
  const [imageName, setImageName] = useState('');
  const [selectImage, setSelectImage] = useState(!galleryEdit?.v.length ? true : false);
  const [libraryModal, toggleLibraryModal] = useState(false);

  const [selectedItems, setSeletedItem] = useState({});

  const [showPropModal, setShowPropModal] = useState(visible);
  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBack, setShowBack] = useState(false);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  useEffect(() => {
    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal); // eslint-disable-next-line
  }, []);

  const dropImage = (files) => {
    // const files = file;
    toggleShowProgress(true);
    console.log('resizing...');

    const size = { width: 300, height: 105 };

    files.forEach(async (file, fileIndex) => {
      setImageName(
        files.length > 1
          ? `Uploading image ${fileIndex} of ${files.length}`
          : `Uploading ${file.name}`
      );

      try {
        const reSizedImage = await resizeImageForS3({
          file: file,
          path: 'sections',
          size,
        });

        console.log('uploading...', file);

        const { url } = await upload(reSizedImage, `props/${prop._id}/section`);
        const stateCopy = galleryEdit?.v || [];

        const newState = {
          i: `${stateCopy.length ? Number(stateCopy[stateCopy.length - 1].i) + 1 : 1}`,
          x: (stateCopy.length || 0) * 2.5,
          y: 0,
          w: 5,
          h: 4,
          image: url,
        };

        stateCopy.push(newState);

        const tempGalleryEdit = galleryEdit;
        tempGalleryEdit.v = stateCopy;

        dispatch({ type: 'setGalleryEdit', galleryEdit: tempGalleryEdit });
      } catch (err) {
        message.error(err.message);
        setImageName('');
        toggleShowProgress(false);
      }
      toggleShowProgress(false);
    });
  };

  const onLayoutChange = (layout, oldItem, newItem) => {
    const tempGalleryEdit = galleryEdit;
    const layoutList = tempGalleryEdit.v;

    tempGalleryEdit.v = layoutList.map((item) => {
      if (item.i === newItem.i) {
        return {
          i: newItem.i,
          x: newItem.x,
          y: newItem.y,
          w: newItem.w,
          h: newItem.h,
          image: item.image,
        };
      }
      return item;
    });
    dispatch({ type: 'setGalleryEdit', galleryEdit: tempGalleryEdit });
  };

  const onRemoveImage = (index) => {
    const tempGalleryEdit = galleryEdit;
    const stateCopy = tempGalleryEdit?.v || [];

    stateCopy.splice(index, 1);

    tempGalleryEdit.v = stateCopy;
    dispatch({ type: 'setGalleryEdit', galleryEdit: tempGalleryEdit });
  };

  const onAddFromLib = async (images) => {
    for (const image of images) {
      const stateCopy = galleryEdit?.v || [];

      const newState = {
        i: `${stateCopy.length ? Number(stateCopy[stateCopy.length - 1].i) + 1 : 1}`,
        x: (stateCopy.length || 0) * 2.5,
        y: 0,
        w: 5,
        h: 4,
        image: await copy({ fromUrl: image.src, prefix: `props/${prop._id}` }),
      };

      stateCopy.push(newState);

      const tempGalleryEdit = galleryEdit;
      tempGalleryEdit.v = stateCopy;

      dispatch({ type: 'setGalleryEdit', galleryEdit: tempGalleryEdit });
    }
    toggleLibraryModal(false);
    setSeletedItem({});
  };

  const handleSave = () => {
    if (selectImage) {
      setSelectImage(false);
    } else if (libraryModal) {
      onAddFromLib(Object.values(selectedItems));
    } else {
      onConfirm();
    }
  };

  const addFromLib = () => {
    setSelectImage(false);
    toggleLibraryModal(true);
  };

  const images = galleryEdit?.v || [];

  return (
    <>
      <Modal
        visible={showPropModal}
        centered
        wrapClassName={` gallery-modal 
          ${selectImage ? 'select-box' : libraryModal ? 'library-modal' : 'edit-gallery'}`}
        title={
          <>
            <h3 className="title">
              {showBack && (
                <Button className="medium-btn grey-btn" onClick={() => setShowBack(false)}>
                  Back
                </Button>
              )}
              <span>
                {selectImage ? 'Select Images' : libraryModal ? 'Library' : 'Edit Gallery'}
              </span>
            </h3>
            <Divider />
          </>
        }
        closeIcon={<CloseIcon />}
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={!savingRichEditorModal}
        closable={!savingRichEditorModal}
        onOk={onConfirm}
        footer={[
          <Button
            key="cancel"
            className="large-btn grey-btn"
            onClick={() => {
              if (libraryModal) {
                toggleLibraryModal(false);
              } else {
                onCancel();
              }
            }}>
            CANCEL
          </Button>,
          <Button
            key="confirm"
            className="large-btn secondary-btn"
            onClick={handleSave}
            disabled={savingRichEditorModal}
            loading={savingRichEditorModal}>
            {selectImage || libraryModal
              ? 'ADD TO GALLERY'
              : savingRichEditorModal
              ? 'SAVING'
              : 'SAVE'}
          </Button>,
        ]}>
        {selectImage ? (
          <SelectImages
            images={images}
            dropImage={dropImage}
            uploadStatus={uploadStatus}
            showProgress={showProgress}
            imageName={imageName}
            setSelectImage={setSelectImage}
            addFromLib={addFromLib}
          />
        ) : libraryModal ? (
          <ContentLibrary
            selectedItems={selectedItems}
            setSeletedItem={setSeletedItem}
            copying={copying}
            showBack={showBack}
            setShowBack={setShowBack}
          />
        ) : (
          <GridImages
            images={images}
            onLayoutChange={onLayoutChange}
            onRemoveImage={onRemoveImage}
            setSelectImage={setSelectImage}
            addFromLib={addFromLib}
          />
        )}
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Continue'}
            confirmText={'Yes, Exit'}
            onCancel={() => {
              setShowModal(false);
              setShowPropModal(true);
            }}
            onConfirm={onCancel}
            footer={null}
          />
        )}
      </>
    </>
  );
};

GalleryEditModal.defaultProps = {
  savingRichEditorModal: false,
};

GalleryEditModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  galleryEdit: PropTypes.instanceOf(Object).isRequired,
  dispatch: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
};

export default GalleryEditModal;
