import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { PageHeader, Button } from 'antd';

import { CloseIcon } from 'components/Icons';
import './TrailEndHeader.scss';

const TrailEndHeader = ({ everPayed, isTeamMember }) => {
  const history = useHistory();
  const [active, setActive] = useState(true);

  return (
    <PageHeader className={`trial-end-header ${active ? 'active' : 'inactive'}`}>
      {/* display message only for mobile */}
      <p className="below-lg">{everPayed ? 'Subscription ended 😔' : 'Trial has ended 😔'}</p>
      {/* display message only for desktop */}
      <p className="above-lg">
        {everPayed
          ? 'Your subscription has ended 😔 Renew now to start using Prospero again!'
          : 'Your trial has ended 😔 No worries! You can still use PROSPERO with our paid plans.'}
        {isTeamMember && ' Let your account owner know.'} 😄
      </p>
      <div className="button-wrapper">
        {!isTeamMember && (
          <Button className="medium-btn secondary-btn" onClick={() => history.push('/plans')}>
            Upgrade <span className="above-md">Now</span>
          </Button>
        )}
        <CloseIcon className="below-md" onClick={() => setActive(false)} />
      </div>
    </PageHeader>
  );
};

TrailEndHeader.defaultProps = {
  everPayed: false,
  isTeamMember: false,
};

TrailEndHeader.propTypes = {
  everPayed: PropTypes.bool,
  isTeamMember: PropTypes.bool,
};

export default TrailEndHeader;
