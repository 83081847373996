/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Select, Empty } from 'antd';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { getProposalTitle, getSectionTitle } from 'helpers/proposal';
import { GET_IMPORT_PROPOSALS, GET_IMPORT_PROPOSAL } from 'graphql/queries/proposalQueries';
import { getUserId, proposalsImportVar } from 'graphql/cache';
import Loader from 'components/Loader';
import CloseIcon from 'components/Icons/CloseIcon';

import './ImportSectionModal.scss';

const { Option } = Select;

const ImportSectionModal = ({ visible, onCancel, onConfirm, proposal }) => {
  const [form] = Form.useForm();

  const [selectedProposal, setSelectedProposal] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [proposalList, setProposalList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [sortedList, setSortedList] = useState(proposalsImportVar() || []);

  const changeSections = ({ proposalSelected }) => {
    if (proposalSelected && proposalSelected.draft) {
      const sections = _.compact(
        _.map(proposalSelected.draft.sectionorder, (s, sectionIndex) => {
          if (s === 'milestones') {
            // Dont allowing import of old milestones
            return null;
          }
          if (s === 'pricing') {
            // Dont allowing import of old pricing
            return null;
          }
          const stitle = getSectionTitle({
            proposal: proposalSelected,
            sectionName: s,
            sectionIndex,
          });
          if (!stitle || !stitle.trim()) {
            return null;
          }
          return {
            v: s,
            t: stitle,
            c: [proposalSelected?.language?.toLowerCase() || 'english'],
          };
        })
      );

      setSectionList(sections);
      setSelectedSection(sections[0]);
    }
  };

  const [loadProposal, { loading: isFetchingProposal }] = useLazyQuery(GET_IMPORT_PROPOSAL, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.fetchProposal) {
        const proposal = data.fetchProposal;
        const ptitle = getProposalTitle({ proposal });
        if (!ptitle || !ptitle.trim()) {
          return null;
        }
        setSelectedProposal({
          v: proposal._id,
          draft: proposal.draft,
          t: ptitle,
          language: proposal?.language?.toLowerCase(),
          c: [proposal?.language?.toLowerCase() || 'english'],
        });
        changeSections({ proposalSelected: proposal });
      }
    },
  });

  const [loadProposals, { loading: isFetchingProposals }] = useLazyQuery(GET_IMPORT_PROPOSALS, {
    variables: {
      uid: getUserId(),
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.fetchProposals) {
        const proposals = data.fetchProposals.proposals;
        let tempSortedList = proposals.slice().sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        setSortedList(tempSortedList);
        proposalsImportVar(tempSortedList);
      }
    },
  });

  useEffect(() => {
    !sortedList?.length && loadProposals();
  }, []);

  useEffect(() => {
    if (sortedList.length && !proposalList.length) {
      const updatedProposalList = _.compact(
        _.map(sortedList, (p) => {
          if (p.version < 3) {
            return null;
          }
          // Dont select current proposal
          if (p._id === proposal._id) {
            return null;
          }
          if (!p.draft) {
            return null;
          }
          const ptitle = getProposalTitle({ proposal: p });
          if (!ptitle || !ptitle.trim()) {
            return null;
          }
          return {
            v: p._id,
            draft: p.draft,
            t: ptitle,
            language: p?.language?.toLowerCase(),
            c: [p?.language?.toLowerCase() || 'english'],
          };
        })
      );

      setProposalList(updatedProposalList);
    }
  }, [sortedList]);

  const onChangeProposal = (value) => {
    const changedProposal = proposalList.find((item) => item.v === value);
    setSelectedProposal(changedProposal);
    loadProposal({
      variables: {
        _id: changedProposal.v,
      },
    });
  };

  const onChangeSection = (value) => {
    const changedSection = sectionList.find((item) => item.v === value);
    setSelectedSection(changedSection);
  };

  if (isFetchingProposals) {
    return <Loader overlay />;
  }

  return (
    <Modal
      className="import-section-modal"
      visible={visible}
      centered
      okText="CONTINUE"
      closeIcon={<CloseIcon className="close-icon" />}
      cancelText="CANCEL"
      onCancel={onCancel}
      footer={null}>
      <h3 className="title">Import Section</h3>
      <div className="divider" />
      <div className="body">
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            proposalName: selectedProposal ? selectedProposal.v : null,
            sectionName: selectedSection ? selectedSection.v : null,
          }}>
          <Form.Item name="proposalName" label="PROPOSAL NAME">
            <Select onChange={onChangeProposal} placeholder="Select a proposal">
              {proposalList.map((item) => {
                return (
                  <Option key={item.v} value={item.v}>
                    {item.t}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name="sectionName" label="SECTION NAME">
            <Select
              maxTagPlaceholder={selectedProposal ? 'Select Section' : 'Select Proposal'}
              onChange={onChangeSection}
              loading={isFetchingProposal}>
              {sectionList.length ? (
                sectionList.map((item) => {
                  return (
                    <Option key={item.v} value={item.v}>
                      {item.t}
                    </Option>
                  );
                })
              ) : (
                <Option>
                  {' '}
                  <Empty description={'Select Proposal'} />
                </Option>
              )}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <div className="button-wrapper">
        <Button
          className="large-btn secondary-btn"
          onClick={() => {
            onConfirm({ importedSection: selectedProposal.draft[selectedSection.v] });
          }}>
          IMPORT
        </Button>
        <Button className="large-btn grey-btn" onClick={onCancel}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

ImportSectionModal.defaultProps = {
  visible: false,
};
ImportSectionModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  proposal: PropTypes.shape({
    _id: PropTypes.string.isRequired,
  }).isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ImportSectionModal;
