import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useMutation } from '@apollo/client';
import { notification, Button, Modal, Row, Select, Col, Avatar } from 'antd';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getUserId } from 'graphql/cache';
import { DISCONNECT_WIX } from 'graphql/mutations/integrationMutation';
import { getUserShortName } from 'helpers/users';
import IntegrationItem from '../IntegrationItem';
import images from 'constants/images';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';
import ConnectedUserIcon from 'components/Icons/ConnectedUserIcon';
import EclipseIcon from 'components/Icons/EclipseIcon';
import CircleTickIcon from 'components/Icons/CircleTickIcon';
import DropdownIcon from 'components/Icons/DropdownIcon';

import './WixIntegration.scss';

const { Option } = Select;

const WixIntegration = ({
  data,
  isModalItem,
  currencies,
  proposal,
  saveProposal,
  userRole,
  handleRefetchUser,
}) => {
  const history = useHistory();

  const [integrationData, setIntegrationData] = useState(data);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const [loading, setLoading] = useState();
  const [selectedDomain, setSelectedDomain] = useState(data?.[0]);
  const [selectedDisconnectDomain, setSelectedDisconnectDomain] = useState();

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
      setSelectedDomain(data?.[0]);
    }
  }, [data]); //eslint-disable-line

  useEffect(() => {
    const wixStatus = utils.getQueryStringValue('wix');
    if (wixStatus === 'connected') {
      if (!data?.length && !showConnectModal) {
        setShowConnectModal(true);
      }
      history.push('/integration');
      handleRefetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, handleRefetchUser, history, showConnectModal, utils.getQueryStringValue('wix')]);

  const handleDisconnectWindow = (metaSiteId) => {
    setLoading('');
    setShowDisconnectModal(false);
    const windowTab = window.open(
      `https://manage.wix.com/dashboard/${metaSiteId}/manage-installed-apps`,
      ''
    );
    const timer = setInterval(function () {
      if (windowTab?.closed) {
        clearInterval(timer);
        handleRefetchUser();
        setSelectedDisconnectDomain('');
      }
    }, 1000);
  };

  const [disconnectWixSite, { loading: loadingDisconnect }] = useMutation(DISCONNECT_WIX, {
    skip: !getUserId() || !window.location.href,
    onCompleted: ({ disconnectWix }) => handleDisconnectWindow(disconnectWix),
    onError: () => {
      setLoading('');
      handleRefetchUser();
      notification.error({
        message: 'Wix Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  const connectToWix = () => {
    setLoading('connecting');
    const wixState = uuidv4();
    sessionStorage.setItem('wix-state', wixState);
    sessionStorage.setItem('wix-web-connection', true);
    const windowTab = window.open(
      `https://www.wix.com/installer/install?appId=${process.env.REACT_APP_WIX_APP_ID}&state=${wixState}&redirectUrl=${process.env.REACT_APP_WIX_REDIRECT_URI}`,
      ''
    );

    const timer = setInterval(function () {
      if (windowTab?.closed) {
        clearInterval(timer);
        setLoading('');
        sessionStorage.setItem('wix-web-connection', false);
        history.push('/integration?wix=connected');
      }
    }, 1000);
  };

  const handleClick = (connectionType) => {
    if (isModalItem) {
      if (selectedDomain?.instanceId) {
        if (connectionType) {
          setLoading('connecting');
          let wixInfo = {};
          saveProposal({
            wix: wixInfo,
          });
          setLoading('connected');
        } else {
          notification.error({
            message: 'Wix connection Failed',
          });
        }
      } else {
        setLoading('disconnecting');
        saveProposal({
          wix: '',
        });
        setLoading('disconnected');
      }
    } else {
      if (!selectedDomain || !selectedDomain?.instanceId) {
        connectToWix();
      } else {
        setShowDisconnectModal(true);
      }
    }
  };

  const handleConnectModalClick = () => {
    setShowConnectModal(false);
    history.push('/dashboard');
  };

  const handleSelectDomain = (instance) => {
    if (instance === 'connect-new-wix-account') {
      connectToWix();
    } else {
      const tempSelectedDomain = integrationData.find((domain) => domain.instanceId === instance);
      setSelectedDomain(tempSelectedDomain || '');
    }
  };

  const logoSubtitle = () => {
    return integrationData?.length ? (
      <Select
        value={`${selectedDomain?.name} ${
          selectedDomain?.url ? '(' + selectedDomain?.name + ')' : ''
        }`}
        suffixIcon={<DropdownIcon />}
        placeholder={'Select Payment Type...'}
        onChange={handleSelectDomain}
        dropdownClassName="wix-connect-select-dropdown">
        {integrationData?.map((integrationDomain, index) => (
          <Option
            key={index}
            value={integrationDomain.instanceId}
            className="item-brand-name-select-option">
            <Row className="item-select-row">
              <Col>
                {integrationDomain.logo ? (
                  <Avatar src={integrationDomain.logo} size={35} />
                ) : (
                  <Avatar size={35}>{getUserShortName({ name: integrationDomain.name })}</Avatar>
                )}
                <span className="item-name"> {integrationDomain.name}</span>
              </Col>
              <Col>
                <CircleTickIcon />
              </Col>
            </Row>
          </Option>
        ))}

        <Option
          key="connect-new-wix-account"
          value="connect-new-wix-account"
          className="item-brand-name-select-option-new">
          <Row className="item-select-row">
            <Button className="primary">Add More</Button>
          </Row>
        </Option>
      </Select>
    ) : (
      ''
    );
  };

  const handleDisconnectModalClick = (type, metaSiteId) => {
    if (type === 'cancel') {
      setShowDisconnectModal(false);
    } else if (type) {
      if (metaSiteId) {
        handleDisconnectWindow(metaSiteId);
      } else {
        setSelectedDisconnectDomain(type);
        disconnectWixSite({
          variables: {
            instanceId: type,
          },
        });
      }
    }
  };

  return (
    <>
      <IntegrationItem
        name="wix"
        description="Sync customer data, products & services from Wix"
        integrationLink="https://support.goprospero.com/article/show/154221-how-to-integrate-wix-with-prospero"
        status={
          loading || // this can be connecting or disconnecting
          (selectedDomain?.instanceId && !isModalItem
            ? 'connected'
            : proposal?.wix && isModalItem
            ? 'connected'
            : 'disconnected')
        }
        image={images.WIX_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        currencies={currencies}
        proposal={proposal}
        userRole={userRole}
        logoSubtitle={logoSubtitle()}
      />

      {showConnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal wix-connect-modal'}
          centered
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <Row className="wix-connect-modal-logo-container">
            <EclipseIcon className="wix-connect-modal-logo-background" />
            <ConnectedUserIcon className="logo" />
          </Row>

          <div className="title">Wix Is Now Connected!</div>

          <div className="divider" />

          <div className="body">
            We synced all your services, products and prices with our proposals app
          </div>

          <div className="button-wrapper">
            <Button className="button cancel" onClick={handleConnectModalClick}>
              LET'S START
            </Button>
          </div>
        </Modal>
      )}

      {showDisconnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal wix-disconnect-modal'}
          centered
          visible={showDisconnectModal}
          onCancel={() => setShowDisconnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <div className="title">Manage Wix Websites</div>

          <div className="divider" />

          <div className="body">
            {integrationData.map((data, dataIndex) => (
              <Row key={data.instanceId + dataIndex}>
                <span>
                  {data.logo ? (
                    <Avatar src={data.logo} size={35} />
                  ) : (
                    <Avatar size={35}>{getUserShortName({ name: data.name })}</Avatar>
                  )}
                  {`${data.name} ${data.domain ? '(' + data.domain + ')' : ''}`}
                </span>
                <Button
                  className="wix-disconnect-modal-manage"
                  onClick={() => handleDisconnectModalClick(data.instanceId, data.metaSiteId)}
                  loading={selectedDisconnectDomain === data.instanceId && loadingDisconnect}>
                  Manage App
                </Button>
              </Row>
            ))}
          </div>

          <div className="button-wrapper">
            <Button className="button cancel" onClick={() => handleDisconnectModalClick('cancel')}>
              CANCEL
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

WixIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  currencies: [],
  proposal: '',
  saveProposal: () => {},
  userRole: '',
  handleRefetchUser: () => {},
};

WixIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  saveProposal: PropTypes.func,
  userRole: PropTypes.string,
  handleRefetchUser: PropTypes.func,
};

export default WixIntegration;
