import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.54688 3.75V15.3516"
      stroke="#2F2C88"
      stroke-width="1.42412"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.75 9.55078L15.3516 9.55078"
      stroke="#2F2C88"
      stroke-width="1.42412"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const PlusIcon = (props) => <Icon component={svg} {...props} />;

export default PlusIcon;
