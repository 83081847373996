import PropTypes from 'prop-types';
import './Drawer.scss';

const Drawer = ({ visible, setVisible, content, direction, className }) => {
  if (!visible) {
    return null;
  }
  return (
    <div className={`drawer ${className} ${direction} ${visible ? 'active' : 'inactive'}`}>
      <div className="closing-mask" onClick={() => setVisible(!visible)} />
      <div className="drawer-content">{content}</div>
    </div>
  );
};

Drawer.defaultProps = {
  visible: false,
  setVisible: () => {},
  content: null,
  direction: 'bottom',
  className: '',
};

Drawer.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  content: PropTypes.node,
  direction: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  className: PropTypes.string,
};

export default Drawer;
