import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import Loader from 'components/Loader';

const DownloadingPDFModal = () => {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }

  return (
    <Modal
      className="simple-info-modal download-pdf-modal"
      centered
      visible
      closable={false}
      maskClosable={false}>
      <h3 className="title">Generating PDF</h3>
      <div className="divider" />

      <p className="body">
        <span>This might take a while</span>
      </p>

      <div className="spinner">
        <Loader />
      </div>

      <Button className="medium-btn grey-btn hide-btn" onClick={() => setVisible(false)}>
        HIDE
      </Button>
    </Modal>
  );
};

export default DownloadingPDFModal;
