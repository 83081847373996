/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.6668 13.3332C14.5565 13.3328 14.4475 13.3104 14.3459 13.2675C14.2443 13.2246 14.1523 13.162 14.0751 13.0832C13.9199 12.9271 13.8328 12.7159 13.8328 12.4957C13.8328 12.2756 13.9199 12.0644 14.0751 11.9082L16.8501 9.16657H8.40845C7.41389 9.16657 6.46006 9.56166 5.7568 10.2649C5.05354 10.9682 4.65845 11.922 4.65845 12.9166C4.65845 13.9111 5.05354 14.865 5.7568 15.5682C6.46006 16.2715 7.41389 16.6666 8.40845 16.6666H10.5001C10.7211 16.6666 10.9331 16.7544 11.0894 16.9106C11.2457 17.0669 11.3335 17.2789 11.3335 17.4999C11.3335 17.7209 11.2457 17.9329 11.0894 18.0892C10.9331 18.2454 10.7211 18.3332 10.5001 18.3332H8.40845C6.97186 18.3332 5.59411 17.7626 4.57829 16.7467C3.56247 15.7309 2.99178 14.3532 2.99178 12.9166C2.99178 11.48 3.56247 10.1022 4.57829 9.08641C5.59411 8.07058 6.97186 7.4999 8.40845 7.4999H16.8584L14.0834 4.75823C13.9457 4.5998 13.8729 4.39522 13.8794 4.18539C13.886 3.97556 13.9716 3.77594 14.1189 3.62645C14.2663 3.47695 14.4647 3.38859 14.6744 3.37904C14.8841 3.36948 15.0897 3.43943 15.2501 3.5749L19.4751 7.74157C19.5524 7.81944 19.6135 7.91178 19.6549 8.01331C19.6964 8.11484 19.7174 8.22356 19.7168 8.33324C19.7163 8.44349 19.694 8.55256 19.6511 8.65412C19.6082 8.75569 19.5455 8.84772 19.4668 8.9249L15.2501 13.0916C15.0949 13.2455 14.8854 13.3323 14.6668 13.3332Z"
      fill="#7876C1"
    />
  </svg>
);

const RedoThinIcon = (props) => <Icon component={svg} {...props} />;

export default RedoThinIcon;
