/* eslint-disable */
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Checkbox, Tooltip, Row, Popover, Button } from 'antd';

import { InfoIcon, ChevronRight, CloseIcon } from 'components/Icons';

const AllOptionsDrawer = ({
  onClosePane,
  toggleDiscount,
  dis,
  toggleTax,
  clickTaxBtn,
  taxBtn,
  tax,
  toggleQuantity,
  quantity,
  toggleSelectable,
  selectable,
  toggleRoundedTotal,
  roundTotal,
  toggleSubtotal,
  showSubTotal,
  isWizard,
  popOverContent,
  setVisible,
  discountAvailable,
  toggleSubtotalBeforeTax,
  chosen,
  stab,
  toggleTotal,
  showTotal,
  toggleStructured,
  structured,
  showDrawerTotals,
}) => {
  return (
    <>
      <div className="tabList-drawer">
        <div className="ant-modal-mask" onClick={onClosePane}></div>
        <div className="content">
          <div className="drawer-header">
            <h2>All Options</h2>
            <Button className="medium-btn grey-btn tabList-drawer-close" onClick={onClosePane}>
              <CloseIcon />
            </Button>
          </div>
          <div className="body">
            <div className="tabList-checkboxes">
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={() => toggleDiscount()} checked={dis}>
                  Discount
                </Checkbox>
                <Tooltip title="Show discount amount (percentage or fixed) on the Total Price">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox
                  onClick={() => {
                    toggleTax();
                    clickTaxBtn(taxBtn);
                  }}
                  checked={tax}>
                  Tax
                </Checkbox>
                <Tooltip title="Show tax amount (percentage or fixed) on the Total Price">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleQuantity} checked={quantity}>
                  Quantity
                </Checkbox>
                <Tooltip title="Display the number of your monthly or yearly payments">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              {/* <Row className="tabList-checkbox-item">
                          <Checkbox onChange={onChange}>Total per line</Checkbox>
                          <Tooltip title={""}>
                            <InfoIcon className="checkbox-list-info-icon" />
                          </Tooltip>
                        </Row> */}
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleSelectable} checked={selectable}>
                  {selectable === 'locked'
                    ? 'Disabled Checkboxes'
                    : selectable
                    ? 'Enabled Checkboxes'
                    : 'Checkboxes'}
                </Checkbox>
                <Tooltip
                  title={
                    selectable === 'locked'
                      ? `The prospect can't tick/untick them`
                      : selectable
                      ? `The prospect can choose the deliverables`
                      : `The prospect won't choose the deliverables`
                  }>
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleRoundedTotal} checked={roundTotal}>
                  Rounded total
                </Checkbox>
                <Tooltip title="Display the rounded total price instead of showing fractional price.">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleSubtotal()} checked={showSubTotal}>
                  SubTotal
                </Checkbox>
                <Tooltip title="Total price before tax or discount">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              {isWizard && (
                <div className="drawer-popover">
                  <Popover
                    overlayClassName="totals-popover"
                    content={popOverContent}
                    trigger="none"
                    placement="left"
                    visible={showDrawerTotals}
                    onVisibleChange={(value) => value && setVisible(value)}
                  />
                </div>
              )}
              <Row
                className={`tabList-checkbox-item ${
                  !discountAvailable || !tax ? 'is-disabled' : ''
                }`}>
                <Checkbox
                  onClick={toggleSubtotalBeforeTax()}
                  checked={tax && !!chosen[stab]?.subtotalBeforeTax}>
                  Total before tax
                </Checkbox>
                <Tooltip title="Total price before tax (discount price included)">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleTotal()} checked={showTotal}>
                  Total payment
                </Checkbox>
                <Tooltip title="Total price after discount & tax">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>
              <Row className="tabList-checkbox-item">
                <Checkbox onClick={toggleStructured()} checked={structured}>
                  Structured
                </Checkbox>
                <Tooltip title="Change the Pricing table column titles of Deliverable, Quantity & Price">
                  <InfoIcon className="checkbox-list-info-icon" />
                </Tooltip>
              </Row>

              {!isWizard && (
                <div className="drawer-popover">
                  <Popover
                    overlayClassName="totals-popover"
                    content={popOverContent}
                    trigger="none"
                    placement="left"
                    visible={showDrawerTotals}
                    onVisibleChange={(value) => value && setVisible(value)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AllOptionsDrawer.propTypes = {
  onClosePane: PropTypes.func,
};

export default AllOptionsDrawer;
