import { Layout } from 'antd';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import images from 'constants/images';
import { PublishHeader, DraftHeader, HistoryHeader } from './components';

import './ProposalHeaderBar.scss';
import { useProposalContext } from 'context/proposal';

const { Header } = Layout;

const ProposalHeaderBar = ({
  mode,
  published,
  isPublishing,
  proposal,
  saveProposal,
  publishProposal,
  restoreProposal,
  openHistory,
  isSaving,
  openPublishView,
  copyPropsalLink,
  publishedProposalLink,
  user,
  isTemplate,
  isSection,
  updateScreen,
  screen,
  handleManualSave,
  disablePreview,
  configText,
  updateLoaderState,
  scrollToSection,
  path,
  setKeyboardModal,
  buttons,
  setProposalPublished,
  setCollapseContentTable,
  setCollapseSidemenu,
  backupPreviewId,
  backupPreviewLoading,
  restoring,
}) => {
  const history = useHistory();

  const { downloadPdf, setProp, propRef } = useProposalContext();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Header className="proposal-header">
      <NavLink
        to={isTemplate || isSection ? `/contentlibrary?tab=${isSection ? '4' : '1'}` : '/'}
        className="prospero-logo">
        <img src={images.LOGO_SMALL} alt="proposal-logo-small" />
      </NavLink>

      {mode === 'wizard' && (
        <div className="main-wrapper wizard">
          <div className="block block-1">{buttons}</div>
        </div>
      )}

      {mode === 'draft' && (
        <DraftHeader
          proposal={proposal}
          saveProposal={saveProposal}
          isSaving={isSaving}
          openPublishView={openPublishView}
          downloadPdf={downloadPdf}
          user={user}
          isSection={isSection}
          handleManualSave={handleManualSave}
          disablePreview={disablePreview}
          configText={configText}
          updateLoaderState={updateLoaderState}
          propRef={propRef}
          setProp={setProp}
          scrollToSection={scrollToSection}
          path={path}
          setKeyboardModal={setKeyboardModal}
          setCollapseContentTable={setCollapseContentTable}
          setCollapseSidemenu={setCollapseSidemenu}
          openHistory={openHistory}
        />
      )}

      {mode === 'history' && (
        <HistoryHeader
          handleBack={handleBack}
          backupPreviewId={backupPreviewId}
          backupPreviewLoading={backupPreviewLoading}
          restoreProposal={restoreProposal}
          restoring={restoring}
        />
      )}

      {mode === 'publish' && (
        <PublishHeader
          published={published}
          isPublishing={isPublishing}
          proposal={proposal}
          publishProposal={publishProposal}
          copyPropsalLink={copyPropsalLink}
          publishedProposalLink={publishedProposalLink}
          isTemplate={isTemplate}
          isSection={isSection}
          updateScreen={updateScreen}
          screen={screen}
          setProposalPublished={setProposalPublished}
        />
      )}
    </Header>
  );
};

ProposalHeaderBar.defaultProps = {
  mode: 'wizard',
  isSaving: false,
  isPublishing: false,
  openPublishView: () => {},
  saveProposal: () => {},
  publishProposal: () => {},
  copyPropsalLink: () => {},
  publishedProposalLink: () => {},
  updateScreen: () => {},
  screen: '',
  proposal: {},
  published: false,
  user: '',
  isTemplate: false,
  isSection: false,
  disablePreview: false,
  updateLoaderState: () => {},
  configText: () => {},
  setKeyboardModal: () => {},
  backupPreviewId: null,
  backupPreviewLoading: false,
};

ProposalHeaderBar.propTypes = {
  mode: PropTypes.oneOf(['wizard', 'draft', 'publish']),
  proposal: PropTypes.instanceOf(Object),
  saveProposal: PropTypes.func,
  isSaving: PropTypes.bool,
  isPublishing: PropTypes.bool,
  published: PropTypes.bool,
  isTemplate: PropTypes.bool,
  isSection: PropTypes.bool,
  openPublishView: PropTypes.func,
  publishProposal: PropTypes.func,
  copyPropsalLink: PropTypes.func,
  publishedProposalLink: PropTypes.func,
  downloadPdf: PropTypes.func,
  updateScreen: PropTypes.func,
  screen: PropTypes.string,
  user: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.string]),
  undoableStack: PropTypes.instanceOf(Object),
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  scrollToSection: PropTypes.func,
  updateLoaderState: PropTypes.func,
  configText: PropTypes.func,
  setKeyboardModal: PropTypes.func,
  buttons: PropTypes.node,
  backupPreviewId: PropTypes.string,
  setBackupPreviewId: PropTypes.func,
  backupPreviewLoading: PropTypes.bool,
};
export default ProposalHeaderBar;
