/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="line-md:email">
      <g id="Group">
        <path
          id="Vector"
          d="M3.33333 4.66663H16.6667C16.8508 4.66663 17 4.81586 17 4.99996V15C17 15.1841 16.8508 15.3333 16.6667 15.3333H3.33333C3.14924 15.3333 3 15.1841 3 15V4.99996C3 4.81586 3.14924 4.66663 3.33333 4.66663Z"
          fill="white"
          stroke="#05034D"
        />
        <g id="Vector_2">
          <mask id="path-2-inside-1_14043_26178" fill="white">
            <path d="M2.5 5.1366L10 9.99996L17.5 5.1366" />
          </mask>
          <path
            d="M10 9.99996L9.45593 10.839L10 11.1918L10.5441 10.839L10 9.99996ZM1.95593 5.97563L9.45593 10.839L10.5441 9.16092L3.04407 4.29756L1.95593 5.97563ZM10.5441 10.839L18.0441 5.97563L16.9559 4.29756L9.45593 9.16092L10.5441 10.839Z"
            fill="#05034D"
            mask="url(#path-2-inside-1_14043_26178)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const ProposalsEmailIcon = (props) => <Icon component={svg} {...props} />;

export default ProposalsEmailIcon;
