import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import Path from 'routes/path';

import '../../../Modals/SimpleModalInfo.scss';
import './IsEditingModal.scss';

const IsEditingModal = ({
  isEditingModal,
  isEditing,
  takeOverProposal,
  isClient,
  wixEditor,
  templateWixEditor,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      className="simple-info-modal is-editing-modal"
      centered
      visible
      footer={null}
      closeIcon={() => false}
      onCancel={() => {
        if (wixEditor || templateWixEditor) {
          window?.proposalApi?.navigateToProposals();
        } else {
          return false;
        }
      }}
      maskClosable={false}>
      <h3 className="title">This proposal is being edited</h3>
      <div className="divider" />

      <p className="body">
        {isClient ? (
          <span>
            Please wait until the new changes are published before you try again to review and sign
            the proposal.
          </span>
        ) : isEditingModal === 'user' ? (
          <span>
            This proposal is currently open for editing in another browser or tab. You can take
            control of the editing process or go back to the dashboard.
          </span>
        ) : (
          <span>
            This proposal is being edited by <strong>{isEditing?.name}</strong>. You can takeover or
            preview it
          </span>
        )}
      </p>

      {!isClient && !wixEditor && !templateWixEditor && (
        <div className="button-wrapper">
          <Button
            className="large-btn secondary-btn"
            loading={loading}
            onClick={() => {
              setLoading(true);
              takeOverProposal();
            }}>
            Take Over
          </Button>
          <Button className="large-btn grey-btn" onClick={() => history.push(Path.DASHBOARD)}>
            BACK
          </Button>
        </div>
      )}
      {(wixEditor || templateWixEditor) && (
        <div className="button-wrapper">
          <Button className="large-btn secondary-btn" onClick={takeOverProposal}>
            Take Over
          </Button>
          <Button
            className="large-btn grey-btn"
            onClick={() => window?.proposalApi?.navigateToProposals()}>
            BACK
          </Button>
        </div>
      )}
    </Modal>
  );
};

IsEditingModal.defaultProps = {
  isEditing: null,
};

IsEditingModal.propTypes = {
  isEditing: PropTypes.instanceOf(Object),
  takeOverProposal: PropTypes.func.isRequired,
};

export default IsEditingModal;
