import React from 'react';
import PropTypes from 'prop-types';

import { Button, Layout, Menu, Popover } from 'antd';

import ArrowRightIcon from 'components/Icons/ArrowRightIcon';
import AngleRightIcon from 'components/Icons/AngleRightIcon';
import CheckIcon from 'components/Icons/CheckIcon';
import ArrowLeftIcon from 'components/Icons/ArrowLeftIcon';
import WixSaveCloseIcon from 'components/Icons/WixSaveCloseIcon';
import WixDropdownIcon from 'components/Icons/WixDropdownIcon';
import DeviceToggler from 'components/DeviceToggler';
// import WixSaveTemplateIcon from 'components/Icons/WixSaveTemplateIcon';

import './ProposalWixHeaderBar.scss';

const { Header } = Layout;

const ProposalWixHeaderBar = ({
  mode,
  isPublishing,
  publishProposal,
  isSaving,
  openPublishView,
  updateScreen,
  screen,
  disablePreview,
  status,
  addItem,
  proposal,
  propRef,
  templateWixEditor,
  templateUserWixPreview,
}) => {
  const publishButtonMenu = (
    <Menu className="publish-button-menu wixDropdown-menu">
      <Menu.Item
        className="menu-item"
        key="save"
        onClick={() =>
          window?.proposalApi?.onSave(window.proposalId || window.wixTemplateId) &
          window?.proposalApi?.navigateToProposals()
        }>
        <WixSaveCloseIcon />
        <div>
          <span className="publish-button-menu-title">Save & Close</span>
          <br />
          <span className="publish-button-menu-subtitle">All changes will be saved</span>
        </div>
      </Menu.Item>
      {/* <Menu.Item
        className="menu-item"
        key="addToTemplates"
        onClick={() => {
          addItem({
            variables: {
              fromKey: '',
              id: '',
              key: '',
              item_id: proposal?._id || propRef.current?._id || '',
              type: 'proposal',
              createTemplate: true,
            },
          });
        }}>
        <WixSaveTemplateIcon />
        <div>
          <span className="publish-button-menu-title">Add to your templates</span>
          <br />
          <span className="publish-button-menu-subtitle">
            Reuse this proposal when <br />
            saving as a template
          </span>
        </div>
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Header
      className="proposal-header wixHeader"
      style={status === 'empty' ? { display: 'flex', alignItems: 'center' } : {}}>
      <div className="nav-wrapper">
        {templateWixEditor || templateUserWixPreview ? (
          <a
            className="wix_backToProposalsBtn"
            onClick={() => window?.proposalApi?.navigateToProposals()}>
            <span>Close</span>
          </a>
        ) : (
          <a
            className="wix_backToProposalsBtn"
            onClick={() => window?.proposalApi?.navigateToProposals()}>
            <ArrowLeftIcon />
            <span>
              Back<span className="above-lg"> to Proposals</span>
            </span>
          </a>
        )}

        {status !== 'empty' && (
          <div className="header-stepper">
            <div className="stepper-wrapper">
              <div className="stepper medium">
                <div
                  className="stepper-item"
                  onClick={() =>
                    window?.proposalApi?.edit(window.proposalId || window.wixTemplateId)
                  }>
                  <div className="item-wrap complete">
                    <CheckIcon className="icon-done" />
                  </div>
                  <span className="stepper-text">
                    <span>Set details</span>
                  </span>
                  <AngleRightIcon className="stepper-divider" />
                </div>
                <div
                  className={`stepper-item ${mode === 'draft' ? 'active' : ''}`}
                  onClick={() => openPublishView(false)}>
                  <div className={`item-wrap ${mode === 'publish' ? 'complete' : ''}`}>
                    {mode === 'publish' ? <CheckIcon className="icon-done" /> : '2. '}
                  </div>
                  <span className="stepper-text">Design</span>
                  <AngleRightIcon className="stepper-divider" />
                </div>
                <div
                  className={`stepper-item ${mode === 'publish' ? 'active' : 'disabled'}`}
                  onClick={() => openPublishView(true)}>
                  <div className="item-wrap">3. </div>
                  <span className="stepper-text">
                    {templateWixEditor || templateUserWixPreview
                      ? 'Preview & Save'
                      : 'Preview & share'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        {mode === 'draft' && (
          <React.Fragment>
            <div className="header-item">
              {!templateWixEditor ? (
                <>
                  <div className="wix-dropdown">
                    <Popover
                      placement="bottom"
                      content={publishButtonMenu}
                      trigger="click"
                      overlayClassName="editor-color-picker">
                      <button className="wixDropdownButton" aria-disabled="false">
                        <span className="dropdown-icon">
                          <WixDropdownIcon />
                        </span>
                      </button>
                    </Popover>
                  </div>

                  <Button
                    className="wixPreview-button"
                    onClick={() => disablePreview !== 'saving-layout' && openPublishView(true)}
                    disabled={isSaving}>
                    <span className="link-text">Preview</span>
                    <ArrowRightIcon />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="wix-template-save-button"
                    onClick={() =>
                      disablePreview !== 'saving-layout' &&
                      window?.proposalApi?.onSave(window.wixTemplateId)
                    }
                    disabled={isSaving}>
                    <span className="link-text">Save Changes</span>
                  </Button>
                  <Button
                    className="wixPreview-button"
                    onClick={() => disablePreview !== 'saving-layout' && openPublishView(true)}
                    disabled={isSaving}>
                    <span className="link-text">Next</span>
                    <ArrowRightIcon />
                  </Button>
                </>
              )}
            </div>
          </React.Fragment>
        )}

        {mode === 'publish' && (
          <React.Fragment>
            <div className="header-item">
              <DeviceToggler screen={screen} updateScreen={updateScreen} isWix={true} />

              {!templateUserWixPreview && (
                <div className="wix-dropdown">
                  <Popover
                    placement="bottom"
                    content={publishButtonMenu}
                    trigger="click"
                    overlayClassName="editor-color-picker">
                    <button className="wixDropdownButton" aria-disabled="false">
                      <span className="dropdown-icon">
                        <WixDropdownIcon />
                      </span>
                    </button>
                  </Popover>
                </div>
              )}

              {templateUserWixPreview ? (
                <Button
                  className="wixPreview-button"
                  onClick={() => window?.proposalApi?.onSave(window.wixTemplateId)}
                  disabled={isSaving}
                  loading={isSaving}>
                  <span className="link-text">Save</span>
                </Button>
              ) : (
                <Button
                  className="wixPreview-button"
                  onClick={publishProposal}
                  disabled={isPublishing}
                  loading={isPublishing}>
                  <span className="link-text">Share</span>
                </Button>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </Header>
  );
};

ProposalWixHeaderBar.defaultProps = {
  mode: 'wizard',
  isSaving: false,
  isPublishing: false,
  openPublishView: () => {},
  publishProposal: () => {},
  updateScreen: () => {},
  screen: '',
  disablePreview: false,
  status: '',
  templateWixEditor: false,
};

ProposalWixHeaderBar.propTypes = {
  mode: PropTypes.oneOf(['wizard', 'draft', 'publish']),
  isSaving: PropTypes.bool,
  isPublishing: PropTypes.bool,
  openPublishView: PropTypes.func,
  publishProposal: PropTypes.func,
  updateScreen: PropTypes.func,
  screen: PropTypes.string,
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  status: PropTypes.string,
  proposal: PropTypes.instanceOf(Object),
  propRef: PropTypes.instanceOf(Object),
  templateWixEditor: PropTypes.bool,
};

export default ProposalWixHeaderBar;
