import React, { useState } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import BackIcon from 'components/Icons/BackIcon';

const HistoryHeader = ({
  handleBack,
  backupPreviewId,
  backupPreviewLoading,
  restoreProposal,
  restoring,
}) => {
  const [restored, setRestored] = useState(null);

  const onRestore = () => {
    setRestored(backupPreviewId);
    restoreProposal(backupPreviewId);
  };

  return (
    <div className="main-wrapper history">
      <div className="block block-1">
        <Button className="medium-btn grey-btn" onClick={handleBack} icon={<BackIcon />}>
          <span className="back-text">BACK</span>
        </Button>

        {
          //  show restore button for
          //   1. backupPreviewId is set (backup id is required for restore)
          //   2. backupPreviewId is not = 'live' (live proposal can't be restored)
          backupPreviewId && backupPreviewId !== 'live' && (
            <Button
              className="medium-btn secondary-btn"
              loading={restoring.state === 'restoring'}
              disabled={backupPreviewLoading || restored === backupPreviewId} // (since already restored so we hide the button)
              onClick={onRestore}>
              RESTORE
            </Button>
          )
        }
      </div>
    </div>
  );
};

HistoryHeader.propTypes = {
  handleBack: PropTypes.func,
  backupPreviewId: PropTypes.string,
  backupPreviewLoading: PropTypes.bool,
  restoreProposal: PropTypes.func,
};

export default HistoryHeader;
