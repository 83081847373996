import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Row, Col, Divider } from 'antd';

import DeleteTableRowColumnIcon from 'components/Icons/DeleteTableRowColumnIcon';
import Button from 'components/Button';

const RemoveColumn = ({
  hoveredColumn,
  columnindex,
  deleteColumn,
  columnDeleteVisibleChange,
  setColumnDeleteVisibleChange,
  setHoveredDeleteColumn,
  setRowDeleteVisibleChange,
}) => {
  return (
    <Popover
      content={
        <Col className="table-delete-popover">
          <h3 className="ant-popover-title">Delete Column {columnindex + 1}?</h3>
          <Divider />
          <Row className="button-wrapper">
            <Button
              className="medium-btn secondary-btn"
              text="Delete"
              onClick={() => {
                deleteColumn(columnindex);
                setColumnDeleteVisibleChange('');
              }}
            />
            <Button
              className="medium-btn grey-btn"
              text="Cancel"
              onClick={() => setColumnDeleteVisibleChange('')}
            />
          </Row>
        </Col>
      }
      overlayClassName="Prosprich-editor-components-popover"
      trigger="click"
      visible={columnDeleteVisibleChange === columnindex}
      onVisibleChange={() => {
        setColumnDeleteVisibleChange(columnindex);
        setRowDeleteVisibleChange(false);
      }}>
      <DeleteTableRowColumnIcon
        className="table-column-action"
        onMouseOver={() => setHoveredDeleteColumn(columnindex)}
        onMouseLeave={() => setHoveredDeleteColumn('')}
        style={
          hoveredColumn === `td-${columnindex}` || columnDeleteVisibleChange === columnindex
            ? {}
            : { opacity: 0 }
        }
      />
    </Popover>
  );
};

RemoveColumn.defaultProps = {
  columnDeleteVisibleChange: '',
};

RemoveColumn.propTypes = {
  hoveredColumn: PropTypes.string.isRequired,
  columnindex: PropTypes.number.isRequired,
  deleteColumn: PropTypes.func.isRequired,
  columnDeleteVisibleChange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setColumnDeleteVisibleChange: PropTypes.func.isRequired,
  setHoveredDeleteColumn: PropTypes.func.isRequired,
  setRowDeleteVisibleChange: PropTypes.func.isRequired,
};

export default RemoveColumn;
