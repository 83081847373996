import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import { EditorState, RichUtils, Modifier, SelectionState } from 'draft-js';

import Input from 'components/Input';
import {
  InputLinkIcon,
  InputTextIcon,
  LinkCopyIcon,
  UnLinkIcon,
  LinkEditIcon,
  RedirectIcon,
} from 'components/Icons';
import { isValidUrlFormat, getLinkInputStyle, copySelectedLink } from 'utils/hyperlink';

import './HyperLinkModals.scss';

const HyperLinkModals = ({
  state,
  dispatch,
  numberOfColumns,
  columnIndex,
  handleEditorChange,
  setShouldCheckUrl,
  promptForLink,
  isValidUrl,
  setIsValidUrl,
  editor,
  editorRef,
}) => {
  const linkOptionsRef = useRef(null);
  const linkInputRef = useRef();
  const linkInputTextRef = useRef();
  const clickedLink = useRef(null);

  const acceptNewSelection = () => {
    const oldSelection = state.editorState.getSelection();
    const emptySelection = SelectionState.createEmpty(oldSelection.getAnchorKey()).set(
      'hasFocus',
      false
    );
    const newEditorState = EditorState.acceptSelection(state.editorState, emptySelection);
    dispatch({ type: 'setEditorState', editorState: newEditorState });
    closeHyperlinkModals();
  };

  const handleTextLinkInsert = (e) => {
    e.preventDefault();

    const { editorState } = state;
    const contentState = editorState.getCurrentContent();
    const selection = state.selectionState || editorState.getSelection();
    const text = state.linkTextValue.trim();
    let { linkUrlValue: urlValue } = state;

    if (
      urlValue.indexOf('http') !== 0 &&
      urlValue.indexOf('tel') === -1 &&
      urlValue.indexOf('mailto') === -1
    ) {
      urlValue = `http://${urlValue}`;
    }

    // Create new content with text and whitespace
    const newContent = Modifier.insertText(contentState, selection, text.length ? `${text} ` : '');

    // Create new content with link entity
    const newContentWithEntity = newContent.createEntity('LINK', 'MUTABLE', {
      url: urlValue,
    });
    const entityKey = newContentWithEntity.getLastCreatedEntityKey();
    const currentInlineStyle = editorState.getCurrentInlineStyle();

    // Create new selection with the inserted text and link entity
    const anchorOffset = selection.getAnchorOffset();
    const newSelection = new SelectionState({
      anchorKey: selection.getAnchorKey(),
      anchorOffset,
      focusKey: selection.getAnchorKey(),
      focusOffset: anchorOffset + text.length,
    });

    // Apply link entity to the inserted text
    let newContentWithLink = Modifier.applyEntity(newContentWithEntity, newSelection, entityKey);

    currentInlineStyle.forEach((style) => {
      newContentWithLink = Modifier.applyInlineStyle(newContentWithLink, newSelection, style);
    });

    // Create new editor state with modified content
    const withLinkAndWhiteSpace = EditorState.push(
      editorState,
      newContentWithLink,
      'insert-characters'
    );

    // Set cursor right after the inserted link
    const withProperCursor = EditorState.forceSelection(
      withLinkAndWhiteSpace,
      newContent.getSelectionAfter()
    );

    handleEditorChange({ es: withProperCursor });
    closeHyperlinkModals();
    setShouldCheckUrl(urlValue);
  };

  const saveLink = (e) => {
    e.preventDefault();
    closeHyperlinkModals();
    dispatch({ type: 'setSelectionState', selectionState: '' });

    if (!isValidUrl) {
      return;
    }

    let { linkUrlValue: urlValue } = state;
    let { editorState } = state;

    urlValue = urlValue.trim();
    if (
      urlValue.indexOf('http') !== 0 &&
      urlValue.indexOf('tel') === -1 &&
      urlValue.indexOf('mailto') === -1
    ) {
      urlValue = `http://${urlValue}`;
    }

    // remove link from selected text
    const selection = editorState.getSelection();

    if (!selection.isCollapsed()) {
      editorState = RichUtils.toggleLink(editorState, selection, null);
    }

    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url: urlValue,
    });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const es = RichUtils.toggleLink(
      editorState,
      state.selectionState || editorState.getSelection(),
      entityKey
    );

    handleEditorChange({ es });
    setShouldCheckUrl(urlValue);
  };

  const unLinkSelection = () => {
    const { editorState, linkTextValue } = state;
    const contentState = editorState.getCurrentContent();
    const selection = editorState.getSelection();

    if (linkTextValue && linkTextValue.length > 0) {
      const blockKey = selection.getStartKey();
      const block = contentState.getBlockForKey(blockKey);
      const blockText = block.getText();

      // Find all occurrences of the link text in the block
      const linkTextOccurrences = [];
      let index = blockText.indexOf(linkTextValue);
      while (index !== -1) {
        linkTextOccurrences.push(index);
        index = blockText.indexOf(linkTextValue, index + 1);
      }

      if (linkTextOccurrences.length > 0) {
        // Find the start and end offsets of the selected text range containing the link text
        const startOffset = linkTextOccurrences.find(
          (offset) => offset <= selection.getStartOffset()
        );
        const endOffset = linkTextOccurrences.find(
          (offset) => offset + linkTextValue.length >= selection.getEndOffset()
        );

        // Create a new ContentState without the LINK entity for the selected text range
        const newContentState = Modifier.applyEntity(
          contentState,
          selection.merge({
            anchorOffset: startOffset,
            focusOffset: endOffset + linkTextValue.length,
          }),
          null
        );

        // Create a new EditorState with the updated ContentState
        const newEditorState = EditorState.push(editorState, newContentState, 'apply-entity');

        // Update the editorState with the newEditorState
        handleEditorChange({ es: newEditorState });
      }
    }
    acceptNewSelection();
  };

  // Handle updating existing link
  const handleUpdateLink = (e) => {
    e.preventDefault();

    const { editorState, linkUrlValue, linkTextValue, selectionState } = state;
    const contentState = editorState.getCurrentContent();
    const selection = selectionState || editorState.getSelection();
    const blockKey = selection.getStartKey();
    const block = contentState.getBlockForKey(blockKey);
    const entityKey = block.getEntityAt(selection.getStartOffset());
    const linkTextValueTrimmed = linkTextValue.trim();

    if (!entityKey) {
      return;
    }

    const linkInstance = contentState.getEntity(entityKey);

    if (linkInstance && linkInstance.getType() === 'LINK') {
      const updatedData = {
        ...linkInstance.getData(),
        url: linkUrlValue,
        text: linkTextValueTrimmed,
      };

      const updatedContentState = contentState.replaceEntityData(entityKey, updatedData);

      let startOffset = null;
      let endOffset = null;

      block.findEntityRanges(
        (character) => character.getEntity() === entityKey,
        (start, end) => {
          startOffset = start;
          endOffset = end;
        }
      );

      if (startOffset === null || endOffset === null) {
        return;
      }

      const currentInlineStyle = block.getInlineStyleAt(startOffset);

      const newContentState = Modifier.replaceText(
        updatedContentState,
        selection.merge({
          anchorOffset: startOffset,
          focusOffset: endOffset,
        }),
        linkTextValueTrimmed,
        currentInlineStyle,
        entityKey
      );

      const newEditorState = EditorState.push(editorState, newContentState, 'update-block');

      const newSelection = selection.merge({
        anchorOffset: startOffset + linkTextValueTrimmed.length,
        focusOffset: startOffset + linkTextValueTrimmed.length,
      });

      const withProperCursor = EditorState.forceSelection(newEditorState, newSelection);

      handleEditorChange({ es: withProperCursor });
      dispatch({ type: 'setEditorState', editorState: withProperCursor });
    }
    acceptNewSelection();
    setShouldCheckUrl(linkUrlValue);
  };

  const closeHyperlinkModals = () => {
    dispatch({ type: 'setSelectionState', selectionState: '' });
    dispatch({ type: 'setLinkUrlValue', linkUrlValue: '' });
    dispatch({ type: 'setLinkTextValue', linkTextValue: '' });
    dispatch({ type: 'setDisplayLink', displayLink: false });

    setIsValidUrl(false);
  };

  const handleUrlChange = (e) => {
    const inputValue = e.target.value;
    dispatch({ type: 'setLinkUrlValue', linkUrlValue: inputValue });
    setIsValidUrl(isValidUrlFormat(inputValue));
  };

  useEffect(() => {
    const handleLinkClick = (e) => {
      const linkElement = e.target.closest('.data-link');
      if (linkElement) {
        const url = linkElement.getAttribute('data-href');
        const text = linkElement.getAttribute('data-text');
        dispatch({ type: 'setLinkUrlValue', linkUrlValue: url });
        dispatch({ type: 'setLinkTextValue', linkTextValue: text });
        dispatch({ type: 'setDisplayLink', displayLink: 'link-options' });

        clickedLink.current = linkElement;

        // Move the cursor to the beginning of the link text
        const { editorState } = state;
        const contentState = editorState.getCurrentContent();
        const linkBlockKey = linkElement.getAttribute('data-block');
        const linkBlock = contentState.getBlockForKey(linkBlockKey);
        const linkTextLength = linkBlock.getLength();
        const selection = SelectionState.createEmpty(linkBlockKey).set('hasFocus', true);
        const newSelection = selection.merge({
          anchorOffset: 0,
          focusOffset: linkTextLength,
        });
        const newEditorState = EditorState.moveFocusToEnd(editorState).setSelection(newSelection);

        // Insert the link text at the beginning of the link
        const insertedText = `${text} `;
        const contentStateWithInsertedText = Modifier.insertText(
          newEditorState.getCurrentContent(),
          newSelection,
          insertedText
        );
        const newEditorStateWithInsertedText = EditorState.push(
          newEditorState,
          contentStateWithInsertedText,
          'insert-characters'
        );

        handleEditorChange({ es: newEditorStateWithInsertedText });
      }
    };

    const editorContainer = editor.current;
    if (editorContainer?.addEventListener) {
      editorContainer.addEventListener('click', handleLinkClick);
    }

    return () => {
      // Clean up event listeners when the component unmounts
      if (editorContainer?.removeEventListener) {
        editorContainer.removeEventListener('click', handleLinkClick);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const closeOnEsc = (e) => {
      if (e.key === 'Escape') {
        closeHyperlinkModals();
      }
    };

    const handleClickOutside = (event) => {
      if (linkOptionsRef.current && !linkOptionsRef.current.contains(event.target)) {
        if (state.displayLink) {
          closeHyperlinkModals();
        }
      }
    };

    if (state.displayLink) {
      document.addEventListener('keydown', closeOnEsc);
      setTimeout(() => {
        document.addEventListener('mousedown', handleClickOutside);
      }, 200);

      if (state.displayLink === 'fresh-link') {
        if (linkInputRef?.current) {
          linkInputRef.current.focus();
        }
      }
    }

    return () => {
      document.removeEventListener('keydown', closeOnEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.displayLink]);

  if (!state.displayLink) {
    return null;
  }

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (isValidUrl) {
        if (state.displayLink === 'fresh-link') {
          // save link
          saveLink(e);
        } else if (state.displayLink === 'fresh-link-text' && state?.linkTextValue?.trim().length) {
          // save text and link
          handleTextLinkInsert(e);
        } else if (
          state.displayLink === 'update-link-text' &&
          state?.linkTextValue?.trim().length
        ) {
          // update text and link
          handleUpdateLink(e);
        }
      }
    } else if (e.key === 'Escape') {
      closeHyperlinkModals();
    }
  };

  const inputProps = {
    containerclass: 'link-input',
    className: 'has-icon',
    icon: (
      <Tooltip
        overlayClassName="link-input-icon"
        title="Enter a URL (e.g. https://yourlink.com), phone number (e.g. tel:+123456789), or email link (e.g. mailto:email@yourlink.com)">
        <InputLinkIcon />
      </Tooltip>
    ),
    placeholder: 'https://yourlink.com, tel:+123456789, mailto:email@yourlink.com',
    onKeyDown: onKeyDown,
    onChange: handleUrlChange,
    onClick: (e) => e.stopPropagation(),
    autoFocus: true,
  };

  if (state.displayLink === 'fresh-link') {
    // this will open when we want to insert link on selected text
    return (
      <div
        ref={linkOptionsRef}
        style={getLinkInputStyle(state, numberOfColumns, columnIndex)}
        className="link-input-box">
        <Input {...inputProps} ref={linkInputRef} id="urlInput" />
        <Button
          size="small"
          className="secondary-btn small-btn link-save-btn"
          onClick={saveLink}
          disabled={!isValidUrl}>
          Save
        </Button>
      </div>
    );
  }

  if (state.displayLink === 'fresh-link-text') {
    // this will open when we want to insert link along with text
    return (
      <div
        ref={linkOptionsRef}
        style={getLinkInputStyle(state, numberOfColumns, columnIndex)}
        className="link-input-box link-text-and-url">
        <div className="content">
          <Input
            {...inputProps}
            ref={linkInputTextRef}
            icon={<InputTextIcon />}
            placeholder="Add your text"
            onChange={(e) =>
              dispatch({
                type: 'setLinkTextValue',
                linkTextValue: e.target.value,
              })
            }
            id="urlTextInput"
          />
          <Input {...inputProps} id="urlInput1" />
        </div>
        <Button
          size="small"
          className="secondary-btn small-btn link-save-btn"
          type="primary"
          onClick={handleTextLinkInsert}
          disabled={!isValidUrl || !state?.linkTextValue?.trim().length}>
          Save
        </Button>
      </div>
    );
  }

  if (state.displayLink === 'link-options') {
    // this will open when we click on link
    const { editorState, selectionState } = state;
    const contentState = editorState.getCurrentContent();
    const selection = selectionState || editorState.getSelection();
    const blockKey = selection.getStartKey();
    const entityKey = contentState
      ?.getBlockForKey(blockKey)
      ?.getEntityAt(selection?.getStartOffset());

    if (!entityKey) {
      closeHyperlinkModals();
      return null;
    }
    return (
      <div
        ref={linkOptionsRef}
        style={getLinkInputStyle(state, numberOfColumns, columnIndex)}
        className="link-input-box link-options"
        onMouseDown={(e) => e.preventDefault()}>
        <div className="content">
          <h3>{state.linkTextValue}</h3>
          <a href={state.linkUrlValue} target="_blank" rel="noreferrer">
            {state.linkUrlValue.length > 60
              ? `${state.linkUrlValue.substring(0, 60)}...`
              : state.linkUrlValue}{' '}
            <RedirectIcon />
          </a>
        </div>
        <div className="buttons">
          <Button
            size="small"
            className="button"
            onClick={() =>
              promptForLink('update-link-text', state.linkTextValue, state.linkUrlValue)
            }>
            <LinkEditIcon />
          </Button>
          <Button size="small" className="button" onClick={unLinkSelection}>
            <UnLinkIcon />
          </Button>
          <Button
            size="small"
            className="button"
            onClick={() => copySelectedLink(state.linkUrlValue)}>
            <LinkCopyIcon />
          </Button>
        </div>
      </div>
    );
  }

  if (state.displayLink === 'update-link-text') {
    // this will open when we want to edit link button
    return (
      <div
        ref={linkOptionsRef}
        style={getLinkInputStyle(state, numberOfColumns, columnIndex)}
        className="link-input-box link-text-and-url">
        <div className="content">
          <Input
            {...inputProps}
            ref={linkInputTextRef}
            icon={<InputTextIcon />}
            value={state.linkTextValue}
            placeholder="Add your text"
            onChange={(e) =>
              dispatch({
                type: 'setLinkTextValue',
                linkTextValue: e.target.value,
              })
            }
            id="urlTextInput2"
          />
          <Input {...inputProps} value={state.linkUrlValue} id="urlInput3" />
        </div>
        <Button
          size="small"
          className="secondary-btn small-btn link-save-btn"
          type="primary"
          onClick={handleUpdateLink}
          disabled={!isValidUrl || !state?.linkTextValue?.trim().length}>
          Save
        </Button>
      </div>
    );
  }

  return null;
};

export default HyperLinkModals;

HyperLinkModals.propTypes = {
  dispatch: PropTypes.func,
  state: PropTypes.object,
  handleEditorChange: PropTypes.func,
  setShouldCheckUrl: PropTypes.func,
  promptForLink: PropTypes.func,
};
