/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10.0007" r="6.66667" fill="#7876C1" />
    <path
      d="M10 17.5C5.85775 17.5 2.5 14.1422 2.5 10C2.5 5.85775 5.85775 2.5 10 2.5C14.1422 2.5 17.5 5.85775 17.5 10C17.5 14.1422 14.1422 17.5 10 17.5Z"
      fill="#C7BCE4"
    />
    <path
      d="M10.4167 10.0013L13.2739 13.3346V10.8346H15.4167V9.16797H13.2739V6.66797L10.4167 10.0013Z"
      fill="#7876C1"
    />
    <path
      d="M9.16675 10.0013L6.30961 13.3346V10.8346H4.16675V9.16797H6.30961V6.66797L9.16675 10.0013Z"
      fill="#7876C1"
    />
  </svg>
);

const DeExpandIcon = (props) => <Icon component={svg} {...props} />;

export default DeExpandIcon;
