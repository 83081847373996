import React, { useEffect, useState } from 'react';
import { notification, Modal, Row, Button } from 'antd';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import {
  CREATE_STRIPE_CONNECTED_ACCOUNT,
  DISCONNECT_STRIPE_CONNECTED_ACCOUNT,
} from 'graphql/mutations/integrationMutation';
import images from 'constants/images';
import utils from 'utils/utils';
import CloseIcon from 'components/Icons/CloseIcon';
import ConnectedUserIcon from 'components/Icons/ConnectedUserIcon';
import EclipseIcon from 'components/Icons/EclipseIcon';
import IntegrationItem from '../IntegrationItem';

const StripeIntegration = ({
  data,
  isModalItem,
  currencies,
  proposal,
  saveProposal,
  userRole,
  userEmail,
  handleRefetchUser,
}) => {
  const [integrationData, setIntegrationData] = useState(data);
  const [loading, setLoading] = useState();
  const [showConnectModal, setShowConnectModal] = useState(false);
  // const [enablePaymentStatus, setEnablePaymentStatus] = useState();

  const history = useHistory();

  useEffect(() => {
    if (data) {
      setIntegrationData(data);
    }
  }, [data]);

  // useEffect(() => {
  //   if (proposal) {
  //     if (!Object.keys(proposal?.stripe).includes('enablePayment')) {
  //       updateEnablePaymentStatus('beforeSign');
  //     }
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [proposal]);

  // const updateEnablePaymentStatus = (enableStatus) => {
  //   let stripeInfo = {
  //     type: 'stripe',
  //     enablePayment: enableStatus,
  //     country: integrationData?.country,
  //     currency: integrationData?.currency,
  //     isActive: integrationData?.isActive,
  //     stripe_user_id: integrationData?.stripe_user_id,
  //   };
  //   stripeInfo = Object.assign(stripeInfo, integrationData);
  //   saveProposal({
  //     stripe: stripeInfo,
  //   });
  //   setEnablePaymentStatus(enableStatus);
  // };

  const [disconnectStripe] = useMutation(DISCONNECT_STRIPE_CONNECTED_ACCOUNT, {
    onCompleted: () => {
      setLoading('disconnected');
      handleRefetchUser();
      notification.success({
        message: 'Stripe Disconnected',
        description: 'Your account has been disconnected from Stripe.',
      });
    },
    onError: async () => {
      setLoading('');
      setLoading('disconnected');
      handleRefetchUser();
      notification.error({
        message: 'Stripe Disconnected Failed',
        description: 'Your account failed to disconnect try again.',
      });
    },
  });

  const [createStripeConnectedAccount] = useMutation(CREATE_STRIPE_CONNECTED_ACCOUNT, {
    onCompleted: (data) => {
      setLoading('');
      const stripeState = sessionStorage.getItem('stripe-state');
      sessionStorage.removeItem('stripe-state');
      if (!stripeState) {
        setShowConnectModal(true);
      }
      history.push('/integration');
      handleRefetchUser();
      notification.success({
        message: 'Stripe Connected',
        description: 'Your Account has been successfully connected to Stripe.',
      });
    },
    onError: () => {
      setLoading('disconnected');
      history.push('/integration');
      notification.error({
        message: 'Stripe create account Failed',
        description: 'Failed to create to stripe',
      });
    },
  });

  const connectStripe = () => {
    const stripeNewState = uuidv4();
    sessionStorage.setItem('stripe-state', stripeNewState);

    const url = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&scope=read_write&redirect_uri=${process.env.REACT_APP_STRIPE_REDIRECT_URI}&state=${stripeNewState}&stripe_user[email]=${userEmail}`;
    window.open(url, '_self');
  };

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    if (location.pathname === '/stripe/integrations') {
      setLoading('connecting');
      const stripeState = sessionStorage.getItem('stripe-state');

      const code = utils.getQueryStringValue('code');
      const state = utils.getQueryStringValue('state');
      const error = utils.getQueryStringValue('error');

      if (code && (state === stripeState || !stripeState)) {
        createStripeConnectedAccount({
          variables: {
            code,
          },
        });
      } else if (error) {
        setLoading('');
        sessionStorage.removeItem('stripe-state');
        history.push('/integration');
        notification.error({
          message: 'Stripe create account Failed',
          description: 'Access Denied',
        });
      }
    }
  }, [createStripeConnectedAccount, data?.stripe_user_id, history]);

  const handleClick = (paymentType) => {
    if (isModalItem) {
      if (
        (integrationData?.stripe_user_id && integrationData?.isActive && !proposal?.stripe) ||
        (paymentType === 'custom payment link' && proposal?.stripe?.type !== 'custom payment link')
      ) {
        if (paymentType) {
          setLoading('connecting');
          let stripeInfo = { type: paymentType }; // , enablePayment: enablePay };
          if (paymentType === 'stripe') {
            stripeInfo = Object.assign(stripeInfo, integrationData);
          }
          saveProposal({
            stripe: stripeInfo,
          });
          setLoading('connected');
        } else {
          notification.error({
            message: 'Payment connection Failed',
            description: 'Payment Type Required!',
          });
        }
      } else if (
        (!integrationData?.stripe_user_id && paymentType === 'stripe') ||
        (!integrationData?.isActive && paymentType === 'stripe')
      ) {
        notification.error({
          message: 'Payment connection Failed',
          description: 'Add Stripe from Integrations page!',
        });
      } else {
        setLoading('disconnecting');
        saveProposal({
          stripe: '',
        });
        setLoading('disconnected');
      }
    } else {
      if (!integrationData || !integrationData?.stripe_user_id || !integrationData.isActive) {
        setLoading('connecting');
        connectStripe();
      } else {
        setLoading('disconnecting');
        disconnectStripe();
      }
    }
  };

  return (
    <>
      <IntegrationItem
        name="stripe"
        integrationOptions={[
          {
            name: 'stripe',
            image: images.STRIPE_ICON,
          },
          {
            name: 'custom payment link',
          },
        ]}
        description="Request payment from clients via Stripe right after they signed your proposal"
        integrationLink="https://support.goprospero.com/article/show/140028-how-to-integrate-prospero-to-stripe"
        status={
          loading || // this can be connecting or disconnecting
          (proposal?.stripe?.type === 'custom payment link' && 'connected') ||
          (integrationData &&
          integrationData?.stripe_user_id &&
          integrationData.isActive &&
          !isModalItem
            ? 'connected'
            : proposal?.stripe?.stripe_user_id && proposal?.stripe?.isActive && isModalItem
            ? 'connected'
            : 'disconnected')
        }
        image={images.STRIPE_ICON}
        handleClick={handleClick}
        isModalItem={isModalItem}
        currencies={currencies}
        proposal={proposal}
        userRole={userRole}
        // updateEnablePaymentStatus={updateEnablePaymentStatus}
        // enablePaymentStatus={enablePaymentStatus}
        integrationData={integrationData}
        saveProposal={saveProposal}
      />
      {showConnectModal && (
        <Modal
          zIndex={1009}
          className={'confirm-modal wix-connect-modal'}
          centered
          visible={showConnectModal}
          onCancel={() => setShowConnectModal(false)}
          closeIcon={<CloseIcon className="close-icon" />}
          closable={true}
          showIcon={true}
          footer={null}>
          <Row className="wix-connect-modal-logo-container">
            <EclipseIcon className="wix-connect-modal-logo-background" />
            <ConnectedUserIcon className="logo" />
          </Row>

          <div className="title">Stripe Is Now Connected!</div>

          <div className="divider" />

          <div className="body">
            Request payment from clients via Stripe right after they signed your proposal
          </div>

          <div className="button-wrapper">
            <Button
              className="button cancel"
              onClick={() => window.open('https://dashboard.stripe.com/test/dashboard', '_self')}>
              Go to Stripe
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

StripeIntegration.defaultProps = {
  data: null,
  isModalItem: false,
  currencies: [],
  proposal: '',
  saveProposal: () => {},
  userRole: '',
  userEmail: '',
  handleRefetchUser: () => {},
};

StripeIntegration.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.instanceOf(Object),
    PropTypes.instanceOf(Array),
    PropTypes.instanceOf(null),
  ]),
  isModalItem: PropTypes.bool,
  currencies: PropTypes.arrayOf(PropTypes.shape({})),
  proposal: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
  saveProposal: PropTypes.func,
  userRole: PropTypes.string,
  userEmail: PropTypes.string,
  handleRefetchUser: PropTypes.func,
};

export default StripeIntegration;
