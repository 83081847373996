/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      fill="#ffffff"
      d="M16.7,19.2H9.2c-1.4,0-2.5-1.1-2.5-2.5V9.2c0-1.4,1.1-2.5,2.5-2.5h7.5c1.4,0,2.5,1.1,2.5,2.5v7.5 C19.2,18.1,18.1,19.2,16.7,19.2z M9.2,8.3c-0.5,0-0.8,0.4-0.8,0.8v7.5c0,0.5,0.4,0.8,0.8,0.8h7.5c0.5,0,0.8-0.4,0.8-0.8V9.2 c0-0.5-0.4-0.8-0.8-0.8H9.2z"
    />
    <path
      fill="#ffffff"
      d="M4.2,13.3H3.3c-0.7,0-1.3-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8V3.3c0-0.7,0.3-1.3,0.7-1.8 C2,1.1,2.7,0.8,3.3,0.8h7.5c0.7,0,1.3,0.3,1.8,0.7c0.5,0.5,0.7,1.1,0.7,1.8v0.8C13.3,4.6,13,5,12.5,5s-0.8-0.4-0.8-0.8V3.3 c0-0.2-0.1-0.4-0.2-0.6c-0.2-0.2-0.4-0.2-0.6-0.2H3.3c-0.2,0-0.4,0.1-0.6,0.2S2.5,3.1,2.5,3.3v7.5c0,0.2,0.1,0.4,0.2,0.6 s0.4,0.2,0.6,0.2h0.8C4.6,11.7,5,12,5,12.5S4.6,13.3,4.2,13.3z"
    />
  </svg>
);

const CopyIcon = (props) => <Icon component={svg} {...props} />;

export default CopyIcon;
