import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0" width="28" height="28" rx="4" fill="white" />
    <path
      d="M16 6C16 5.44772 15.5523 5 15 5H13C12.4477 5 12 5.44772 12 6V12H6C5.44772 12 5 12.4477 5 13V15C5 15.5523 5.44772 16 6 16H12V22C12 22.5523 12.4477 23 13 23H15C15.5523 23 16 22.5523 16 22V16H22C22.5523 16 23 15.5523 23 15V13C23 12.4477 22.5523 12 22 12H16V6Z"
      fill="white"
    />
  </svg>
);

const AddIcon = (props) => <Icon component={svg} {...props} />;

export default AddIcon;
