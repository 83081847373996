/* eslint-disable react/no-unknown-property */
import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33322 13.3332C6.44347 13.3328 6.55254 13.3104 6.6541 13.2675C6.75567 13.2246 6.8477 13.162 6.92488 13.0832C7.08009 12.9271 7.16721 12.7159 7.16721 12.4957C7.16721 12.2756 7.08009 12.0644 6.92488 11.9082L4.14988 9.16657H12.5915C13.5861 9.16657 14.5399 9.56166 15.2432 10.2649C15.9465 10.9682 16.3415 11.922 16.3415 12.9166C16.3415 13.9111 15.9465 14.865 15.2432 15.5682C14.5399 16.2715 13.5861 16.6666 12.5915 16.6666H10.4999C10.2789 16.6666 10.0669 16.7544 9.91063 16.9106C9.75435 17.0669 9.66655 17.2789 9.66655 17.4999C9.66655 17.7209 9.75435 17.9329 9.91063 18.0892C10.0669 18.2454 10.2789 18.3332 10.4999 18.3332H12.5915C14.0281 18.3332 15.4059 17.7626 16.4217 16.7467C17.4375 15.7309 18.0082 14.3532 18.0082 12.9166C18.0082 11.48 17.4375 10.1022 16.4217 9.08641C15.4059 8.07058 14.0281 7.4999 12.5915 7.4999H4.14155L6.91655 4.75823C7.05428 4.5998 7.12714 4.39522 7.12056 4.18539C7.11398 3.97556 7.02844 3.77594 6.88105 3.62645C6.73367 3.47695 6.53528 3.38859 6.32557 3.37904C6.11585 3.36948 5.91026 3.43943 5.74988 3.5749L1.52488 7.74157C1.44765 7.81944 1.38654 7.91178 1.34507 8.01331C1.3036 8.11484 1.28258 8.22356 1.28322 8.33324C1.28367 8.44349 1.30601 8.55256 1.34892 8.65412C1.39184 8.75569 1.45448 8.84772 1.53322 8.9249L5.74988 13.0916C5.9051 13.2455 6.1146 13.3323 6.33322 13.3332Z"
      fill="#7876C1"
    />
  </svg>
);

const UndoThinIcon = (props) => <Icon component={svg} {...props} />;

export default UndoThinIcon;
