import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

const UnPublishedProposalModal = ({ onCancel }) => {
  return (
    <Modal
      className="simple-info-modal"
      centered
      visible
      footer={null}
      closeIcon={null}
      onCancel={onCancel}>
      <h3 className="title">Proposal Unpublished</h3>
      <div className="divider" />

      <p className="body">Links to this Proposal will not load</p>

      <div className="button-wrapper">
        <Button className="large-btn grey-btn" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

UnPublishedProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default UnPublishedProposalModal;
