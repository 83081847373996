import { Button, Form, Input, Popover, Row, Col } from 'antd';
import React from 'react';
import { useState } from 'react';
import './RecipientModals.scss';
import PageHeader from 'components/PageHeader';
import * as yup from 'yup';
import { DeleteIcon } from 'components/Icons';
import Drawer from 'components/Drawer';
import useWindowDimensions from 'hooks/useWindowDimensions';

const addRecipientSchema = yup.object().shape({
  firstName: yup.string().notRequired(),
  lastName: yup.string().notRequired(),
  email: yup
    .string()
    .email('Invalid email address.')
    .required('Email is required.')
    .test('unique-email', 'Recipient email already exists in list.', function (value) {
      const { path, options } = this; // access current context
      const [currentIndex] = path.match(/\d+/) || []; // extract the index from the path
      const otherItems = options.context.recipients.filter((_, index) =>
        currentIndex ? index !== +currentIndex : true
      );
      return !otherItems.some((item) => item.email?.toLowerCase() === value?.toLowerCase());
    }),
});
const editRecipientSchema = yup.object().shape({ recipients: yup.array().of(addRecipientSchema) });

export const AddRecipientPopover = ({ onAddRecipient, recipients, onVisibleChange }) => {
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    onAddRecipient(values);
    onVisibleChange(false);
  };

  // Handle Enter key to submit the form
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      form.submit(); // Manually trigger form submission on Enter
    }
  };

  const yupAddSync = {
    async validator({ field }, value) {
      await addRecipientSchema.validateAt(field, { [field]: value }, { context: { recipients } });
    },
  };

  return (
    <div className="popover-wrapper">
      <PageHeader pageTitle={'Add Recipients'} />
      <Form form={form} className="add-recipient-form" onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col xs={12} md={7}>
            <Form.Item
              name="firstName"
              rules={[yupAddSync]}
              colon={false}
              label={
                <Row justify="space-between" className="form-label">
                  <span className="label">First Name</span>
                </Row>
              }>
              <Input onKeyDown={handleKeyPress} />
            </Form.Item>
          </Col>
          <Col xs={12} md={7}>
            <Form.Item
              name="lastName"
              rules={[yupAddSync]}
              colon={false}
              label={
                <Row justify="space-between" className="form-label">
                  <span className="label">Last Name</span>
                </Row>
              }>
              <Input onKeyDown={handleKeyPress} />
            </Form.Item>
          </Col>
          <Col xs={24} md={10}>
            <Form.Item
              name="email"
              required
              colon={false}
              rules={[yupAddSync]}
              className="email-input"
              label={
                <Row justify="space-between" className="form-label">
                  <span className="label">Email Address</span>
                </Row>
              }>
              <Input onKeyDown={handleKeyPress} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="footer">
          <Button className="medium-btn grey-btn" onClick={onVisibleChange}>
            CANCEL
          </Button>
          <Button className="medium-btn secondary-btn" onClick={() => form.submit()}>
            ADD
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export const EditRecipientPopover = ({ recipients, onEditRecipient, onVisibleChange }) => {
  const [form] = Form.useForm();

  const yupEditSync = {
    async validator({ field }, value) {
      const allValues = form.getFieldsValue();
      const keys = field.split('.');
      const lastKey = keys.pop(); // Get the last key
      const lastObject = keys.reduce((acc, part) => acc[part], allValues);
      if (lastObject && lastKey in lastObject) {
        lastObject[lastKey] = value;
      }
      await editRecipientSchema.validateAt(field, allValues, { context: allValues });
    },
  };

  const onFinish = (values) => {
    onEditRecipient(values.recipients);
    onVisibleChange(false);
  };

  return (
    <div className="popover-wrapper">
      <PageHeader pageTitle={'Edit Recipients'} />
      <Form
        style={{ flexDirection: 'column' }}
        form={form}
        className="add-recipient-form edit-recipient-form"
        initialValues={{ recipients }}
        onFinish={onFinish}
        autoComplete="off">
        <Form.List name="recipients">
          {(fields, { remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Row key={key} gutter={[12, 12]}>
                  <Col xs={12} md={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'firstName']}
                      fieldKey={[fieldKey, 'firstName']}
                      rules={[yupEditSync]}
                      colon={false}
                      label={
                        <Row justify="space-between" className="form-label">
                          <span className="label">First Name</span>
                        </Row>
                      }>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={12} md={7}>
                    <Form.Item
                      {...restField}
                      name={[name, 'lastName']}
                      fieldKey={[fieldKey, 'lastName']}
                      rules={[yupEditSync]}
                      colon={false}
                      label={
                        <Row justify="space-between" className="form-label">
                          <span className="label">Last Name</span>
                        </Row>
                      }>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={10} className="email-col">
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      fieldKey={[fieldKey, 'email']}
                      rules={[yupEditSync]}
                      colon={false}
                      dependencies={fields.map(({ name: fieldName }) => [
                        'recipients',
                        fieldName,
                        'email',
                      ])}
                      className="email-input"
                      label={
                        <Row justify="space-between" className="form-label">
                          <span className="label">Email Address</span>
                        </Row>
                      }>
                      <Input placeholder="Email" />
                    </Form.Item>
                    {fields.length > 1 && (
                      <DeleteIcon className="delete-icon" onClick={() => remove(name)} />
                    )}
                  </Col>
                </Row>
              ))}
            </>
          )}
        </Form.List>
        <Row className="footer">
          <Button className="medium-btn grey-btn" onClick={onVisibleChange}>
            CANCEL
          </Button>
          <Button className="medium-btn secondary-btn" htmlType="submit">
            SAVE
          </Button>
        </Row>
      </Form>
    </div>
  );
};

export const PopoverComponent = ({ icon, content = {}, trigger = 'click', disabled }) => {
  const [visible, setVisible] = useState(false);
  const { width } = useWindowDimensions();

  const _content = React.cloneElement(content, {
    onVisibleChange: () => setVisible(!visible),
    disabled,
  });
  const _icon = React.cloneElement(icon, { onClick: () => setVisible(!visible), disabled });

  // if smaller device render slider
  if (width < 768) {
    return (
      <>
        {icon && React.cloneElement(icon, { onClick: () => setVisible(!visible) })}
        <Drawer
          visible={visible}
          setVisible={setVisible}
          content={_content}
          direction="bottom"
          className="email-recipients-popover"
        />
      </>
    );
  }

  return (
    <Popover
      overlayClassName="email-recipients-popover"
      trigger={trigger}
      onVisibleChange={disabled ? () => null : (state) => setVisible(state)}
      visible={visible}
      content={visible ? _content : <></>}>
      {icon && _icon}
    </Popover>
  );
};
