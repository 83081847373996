import { useState, useEffect, useCallback } from 'react';
import { Layout, Form, Row, Col, Radio, Input, InputNumber, Slider, Tooltip } from 'antd';
import { useIntercom } from 'react-use-intercom';
import PropTypes from 'prop-types';
import _ from 'lodash';

import InfoIcon from 'components/Icons/InfoIcon';
import ResetItem from '../ResetItem/ResetItem';
import TitleDivider from '../TitleDivider/TitleDivider';

import './LayoutMenu.scss';
import utils from 'utils/utils';

const CONTACT_CALL_BUTTON = 'block';

const defaultState = {
  position: 'normal',
  cover: 'top',
  contact: 'block',
  coverTextAlignment: 'left',
  logoPosition: 'left',
};

const defaultSpacing = {
  spacing: '34.0px',
};

const getLayoutStructure = ({ isRtl, headerConfig, layout, layoutConfig }) => {
  const f = _.extend(
    {
      ...defaultState,
      coverTextAlignment: isRtl ? 'right' : 'left',
      logoPosition: isRtl ? 'right' : 'left',
    },
    headerConfig || layout || layoutConfig || {}
  );
  return f;
};

const LayoutMenu = ({ proposal, saveProposal, disablePreview, setDisablePreview }) => {
  const isRtl = utils.isRTLProposal(proposal);

  const { trackEvent } = useIntercom();

  // these are the lastest value to be respected
  // TODO these can be stored in proposal level instead of just in the side font menu
  const [cache, setCache] = useState({ count: {} });

  const [layoutConfig, setLayoutConfig] = useState({ ...defaultSpacing });
  const [layout, setLayout] = useState({ ...defaultState });
  const [layoutTemp, setLayoutTemp] = useState({});
  const [contactButton, setContactButton] = useState('block');
  const [callButton, setCallButton] = useState('block');
  const [proposalContactOptions, setProposalContactOptions] = useState([]);

  const saveLayout = ({ layout, contactButton, callButton, layoutConfig }) => {
    window.ga('send', 'event', 'edit', 'edit-layout', window.location.href);

    trackEvent('layout-updated');

    const updatedProposal = {};
    if (layoutConfig) {
      updatedProposal['draft.layoutConfig'] = layoutConfig;
    }
    if (layout) {
      updatedProposal['draft.header.headerConfig'] = layout;
      updatedProposal['draft.titleFont'] = layout;
    }
    if (contactButton) {
      updatedProposal.contactButton = contactButton;
    }
    if (callButton) {
      updatedProposal.callButton = callButton;
    }

    setDisablePreview('saving-layout');
    saveProposal(updatedProposal);
  };

  const handleEffect = (draftValue, key) => {
    // if draftValue is empty, return
    if (!draftValue) {
      return;
    }
    // if cache exists, return (those are lastest value in UI)
    if (cache?.[key]) {
      // if draftValue = cache
      if (_.isEqual(draftValue, cache[key])) {
        setCache((prev) => {
          const _cache = { ...prev };

          if (_cache.count?.[key] === 3) {
            // on 3rd hit remove cache
            delete _cache.count[key];
            delete _cache[key];
          } else {
            _cache.count[key] = (_cache.count[key] || 0) + 1;
          }

          return _cache;
        });
      }
      // updating values from draftValue might cause glitch in ui
      // as those are probably old values, wait untill cache is cleared
      return;
    }

    if (key === 'callButton') {
      setCallButton(draftValue || CONTACT_CALL_BUTTON);
    } else if (key === 'contactButton') {
      setContactButton(draftValue || CONTACT_CALL_BUTTON);
    } else if (key === 'headerConfig') {
      setLayout(getLayoutStructure({ isRtl, headerConfig: draftValue, layout }));
    } else if (key === 'contactOptions') {
      setProposalContactOptions(draftValue);
    } else if (key === 'layoutConfig') {
      setLayoutConfig(draftValue);
    }
  };

  const [showCustomSlider, setShowCustomSlider] = useState(false);
  const [customSpacingValue, setCustomSpacingValue] = useState(0);

  // const removeCache = (key) => {
  //   setCache((prev) => {
  //     const _cache = { ...prev };
  //     delete _cache[key];
  //     return _cache;
  //   });
  // };

  useEffect(() => {
    handleEffect(proposal?.draft?.header?.headerConfig, 'headerConfig');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.draft.header]);

  useEffect(() => {
    handleEffect(proposal?.contactButton, 'contactButton');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.contactButton]);

  useEffect(() => {
    handleEffect(proposal?.callButton, 'callButton');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.contactButton]);

  useEffect(() => {
    handleEffect(proposal?.contactOptions || [], 'contactOptions');

    // if (proposal?.contactOptions) {
    //   setProposalContactOptions(proposal.contactOptions || []);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.contactOptions]);

  useEffect(() => {
    handleEffect(proposal?.draft?.layoutConfig, 'layoutConfig');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal.draft.layoutConfig]);

  useEffect(() => {
    if (!['17.0px', '34.0px', '51.0px'].includes(layoutConfig.spacing)) {
      setCustomSpacingValue(parseInt(layoutConfig.spacing, 10));
      setShowCustomSlider(true);
    } else {
      setShowCustomSlider(false);
    }
  }, [layoutConfig.spacing]);

  const onItemChange = ({ type, value }) => {
    if (type === 'contact') {
      setContactButton(value);
      saveLayout({ contactButton: value });
      temporaryCache({ contactButton: value });
    } else if (type === 'call') {
      setCallButton(value);
      saveLayout({ callButton: value });
      temporaryCache({ callButton: value });
    } else if (type === 'spacing') {
      const newConfig = {
        ...layoutConfig,
        [type]: value,
      };
      saveLayout({ layoutConfig: newConfig });
      temporaryCache({ [type]: value });
    } else {
      const updatedLayout = {
        ...layout,
        [type]: value,
      };

      if (type === 'coverTextAlignment' && value === 'custom') {
        updatedLayout['subtitleFontSize'] = '24px';
      }

      temporaryCache({ [type]: value });
      setLayout(updatedLayout);
      saveLayout({ layout: updatedLayout });
    }
  };

  const temporaryCache = (cache) => {
    // store latest value in temp, else useffect will keep changing the value
    setLayoutTemp((prev) => ({ ...prev, ...cache }));
  };

  const onReset = () => {
    window.ga('send', 'event', 'edit', 'reset-layout', window.location.href);

    trackEvent('reset-layout');

    setContactButton('block');
    setCallButton('block');
    const { language } = proposal;
    const updatedLayout = {
      ...layout,
      position: 'normal',
      cover: 'top',
      contact: 'block',
      coverTextAlignment: language?.toLowerCase() === 'hebrew' ? 'right' : 'left',
      logoPosition: language?.toLowerCase() === 'hebrew' ? 'right' : 'left',
    };
    setLayout(updatedLayout);
    setLayoutConfig({ ...defaultSpacing });
    saveLayout({
      layout: updatedLayout,
      contactButton: 'block',
      callButton: 'block',
    });
  };

  //eslint-disable-next-line
  const debouncedSave = useCallback(
    _.debounce((tempProposalContactOptions) => {
      saveProposal({
        contactOptions: tempProposalContactOptions,
      });
    }, 1000),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onCustomSpacingChange = useCallback(
    _.debounce(({ type, value }) => {
      const newConfig = {
        ...layoutConfig,
        [type]: value,
      };
      saveLayout({ layoutConfig: newConfig });
      temporaryCache({ [type]: value });
    }, 800),
    []
  );

  const onChangeSlider = (value) => {
    if (Number.isNaN(value)) {
      return;
    }
    const spacingHere = value + 'px';
    onCustomSpacingChange({ type: 'spacing', value: spacingHere });
    setCustomSpacingValue(value);
  };

  const handleContactOptionsChange = (name, value) => {
    let tempProposalContactOptions;
    if (!name) {
      tempProposalContactOptions = proposalContactOptions?.map((contactOption) => {
        if (value.includes(contactOption.option)) {
          return { ...contactOption, enabled: true };
        } else {
          return { ...contactOption, enabled: false };
        }
      });
    } else {
      tempProposalContactOptions = proposalContactOptions?.map((contactOption) => {
        if (name === contactOption.option) {
          return { ...contactOption, value: value };
        } else {
          return { ...contactOption };
        }
      });
    }

    setProposalContactOptions(tempProposalContactOptions);
    debouncedSave(tempProposalContactOptions);
  };

  return (
    <Layout className="layout-menu">
      <TitleDivider className="divider" content="Section Title Position" />

      <Form layout="vertical">
        <Radio.Group
          style={{ width: '100%' }}
          value={layoutTemp?.position || layout.position}
          onChange={(e) => onItemChange({ type: 'position', value: e.target.value })}>
          <Row gutter={[12, 12]}>
            <Col md={12} lg={12} xl={12} title="Side Normal">
              <Form.Item colon={false}>
                <Radio value="normal">Side Normal</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} title="Side Small">
              <Form.Item colon={false}>
                <Radio value="large">Side Small</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="top">Top</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="top-middle">Top Middle</Radio>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item colon={false}>
                <Radio value="none">None</Radio>
              </Form.Item>
            </Col>
          </Row>
        </Radio.Group>
      </Form>

      <TitleDivider className="divider" content="Space Between Sections" />

      <Form layout="vertical">
        <Radio.Group
          style={{ width: '100%' }}
          value={layoutConfig.spacing}
          onChange={(e) => onItemChange({ type: 'spacing', value: e.target.value })}>
          <Row gutter={[12, 12]}>
            <Col md={12} lg={12} xl={12} title="Small">
              <Form.Item colon={false}>
                <Radio value="17.0px">Small</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} title="Medium">
              <Form.Item colon={false}>
                <Radio value="34.0px">Medium</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} title="Large">
              <Form.Item colon={false}>
                <Radio value="51.0px">Large</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12} title="Custom">
              <Form.Item colon={false}>
                <Radio
                  value={customSpacingValue + 'px'}
                  checked={showCustomSlider}
                  onClick={() => setShowCustomSlider(true)}>
                  Custom
                </Radio>
              </Form.Item>
            </Col>
          </Row>
        </Radio.Group>
        {showCustomSlider && (
          <Row className="slider-row">
            <Col span={18}>
              <Slider
                min={1}
                max={100}
                onChange={onChangeSlider}
                value={customSpacingValue}
                className="custom-spacing-slider"
              />
            </Col>
            <Col span={6}>
              <InputNumber
                min={1}
                max={100}
                value={customSpacingValue}
                className="custom-spacing-input"
                onChange={onChangeSlider}
                onInput={(e) => {
                  if (e.target.value > 100) {
                    e.target.value = 100;
                    setCustomSpacingValue(100);
                  }
                }}
                parser={(value) => (value ? value.replace(/[^\d]/g, '') : '')}
              />
            </Col>
          </Row>
        )}
      </Form>

      <TitleDivider className="divider" content="Cover Size" />

      <Form layout="vertical">
        <Radio.Group
          style={{ width: '100%' }}
          value={layoutTemp?.cover || layout.cover}
          onChange={(e) => onItemChange({ type: 'cover', value: e.target.value })}>
          <Row gutter={[12, 12]}>
            <Col md={12} lg={12} xl={12} title="Top First Page">
              <Form.Item colon={false}>
                <Radio value="top">Top First Page</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="full">Full Page</Radio>
              </Form.Item>
            </Col>
          </Row>
        </Radio.Group>
      </Form>

      <TitleDivider className="divider" content="Cover Text Alignment" />

      <Form layout="vertical">
        <Radio.Group
          style={{ width: '100%' }}
          value={layoutTemp?.coverTextAlignment || layout.coverTextAlignment}
          onChange={(e) => onItemChange({ type: 'coverTextAlignment', value: e.target.value })}>
          <Row gutter={[12, 12]}>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="left">Left</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="right">Right</Radio>
              </Form.Item>
            </Col>

            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="center">Center</Radio>
              </Form.Item>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <Form.Item colon={false}>
                <Radio value="custom">Custom</Radio>
              </Form.Item>
            </Col>
          </Row>
        </Radio.Group>

        {!proposalContactOptions?.length && (
          <>
            <TitleDivider className="divider" content="Contact Button Visibility" />
            <Form layout="vertical">
              <Radio.Group
                style={{ width: '100%' }}
                value={layoutTemp?.contactButton || contactButton}
                onChange={(e) => onItemChange({ type: 'contact', value: e.target.value })}>
                <Row gutter={[12, 12]}>
                  <Col md={12} lg={12} xl={12}>
                    <Form.Item colon={false}>
                      <Radio value="block">Visible</Radio>
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12}>
                    <Form.Item colon={false}>
                      <Radio value="none">Hidden</Radio>
                    </Form.Item>
                  </Col>
                </Row>
              </Radio.Group>
            </Form>

            <TitleDivider className="divider" content="Call Button Visibility" />
            <Form layout="vertical">
              <Radio.Group
                style={{ width: '100%' }}
                value={layoutTemp?.callButton || callButton}
                onChange={(e) => onItemChange({ type: 'call', value: e.target.value })}>
                <Row gutter={[12, 12]}>
                  <Col md={12} lg={12} xl={12}>
                    <Form.Item colon={false}>
                      <Radio value="block">Visible</Radio>
                    </Form.Item>
                  </Col>
                  <Col md={12} lg={12} xl={12}>
                    <Form.Item colon={false}>
                      <Radio value="none">Hidden</Radio>
                    </Form.Item>
                  </Col>
                </Row>
              </Radio.Group>
            </Form>
          </>
        )}

        {proposalContactOptions?.length
          ? proposalContactOptions.map((contactOption) => (
              <>
                <TitleDivider
                  className="divider"
                  content={`${contactOption.option} Button Visibility`}
                />
                <Form layout="vertical">
                  <Radio.Group
                    style={{ width: '100%' }}
                    value={contactOption.enabled}
                    onChange={(e) => {
                      const contactOptions = proposalContactOptions?.map((tempContactOption) => {
                        if (contactOption.option === tempContactOption.option) {
                          return { ...tempContactOption, enabled: e.target.value };
                        } else {
                          return { ...tempContactOption };
                        }
                      });

                      setProposalContactOptions(contactOptions);
                      setCache((prev) => ({ ...prev, contactOptions }));
                      saveProposal({ contactOptions });
                    }}>
                    <Row gutter={[12, 12]}>
                      {[
                        { label: 'Visible', value: true },
                        { label: 'Hidden', value: false },
                      ].map((item) => (
                        <RadioButton key={item.label} {...item} />
                      ))}
                    </Row>

                    {contactOption.enabled && (
                      <Row className="layout-menu-input-wrapper">
                        <h3 className="settings-label">
                          <span>
                            {contactOption.option === 'whatsapp'
                              ? 'WhatsApp'
                              : contactOption.option}{' '}
                            Info
                          </span>
                          {contactOption.option !== 'email' && (
                            <Tooltip
                              title={
                                <>
                                  Make sure to include the country code in the mobile number for{' '}
                                  <br />
                                  {contactOption.option === 'call'
                                    ? 'phone calls.'
                                    : 'WhatsApp messages.'}
                                </>
                              }
                              color="white">
                              <InfoIcon />
                            </Tooltip>
                          )}
                        </h3>
                        <Input
                          value={contactOption.value}
                          placeholder={
                            contactOption.option !== 'email'
                              ? 'e.g. +12163547758'
                              : contactOption.option
                          }
                          onChange={(e) =>
                            handleContactOptionsChange(contactOption.option, e.target.value)
                          }
                        />
                      </Row>
                    )}
                  </Radio.Group>
                </Form>
              </>
            ))
          : null}
      </Form>
      <ResetItem onReset={onReset} />
    </Layout>
  );
};

LayoutMenu.propTypes = {
  proposal: PropTypes.instanceOf(Object).isRequired,
  saveProposal: PropTypes.func.isRequired,
  disablePreview: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  setDisablePreview: PropTypes.func,
};
export default LayoutMenu;

function RadioButton({ label, value }) {
  return (
    <Col title={label} md={12} lg={12} xl={12}>
      <Form.Item colon={false}>
        <Radio value={value}>{label}</Radio>
      </Form.Item>
    </Col>
  );
}
