import React from 'react';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';

const ClientPaymentModal = ({ onCancel, onConfirm, clientPayableLabel, amount, makingPayment }) => {
  return (
    <Modal
      className="simple-info-modal client-payment-modal"
      centered
      visible
      footer={null}
      closeIcon={null}
      onCancel={onCancel}>
      <h3 className="title">{clientPayableLabel}</h3>
      <div className="divider" />

      <p className="body">Amount: {amount}</p>
      <div className="button-wrapper">
        <Button
          className="button confirm"
          type="primary"
          loading={makingPayment}
          onClick={onConfirm}>
          OK
        </Button>
        <Button className="button cancel" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

ClientPaymentModal.defaultProps = {
  paymentError: '',
  makingPayment: false,
};

ClientPaymentModal.propTypes = {
  makingPayment: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  clientPayableLabel: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default ClientPaymentModal;
