/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Tablist } from '../../../../modules';
import Popconfirm from 'components/PopConfirm';
import helpers from 'helpers/proposal';
import Button from 'components/Button';
import ResetIcon from 'components/Icons/ResetIcon';
import CloseIcon from 'components/Icons/CloseIcon';
import images from 'constants/images';

const SuperEditModal = ({
  prop,
  user,
  config,
  superEdit,
  visible,
  onCancel,
  onConfirm,
  removeItem,
  saveUser,
  savingRichEditorModal,
  wixSiteProperties,
}) => {
  const [superPowers, setSuperPowers] = useState({});
  const [superToText, setSuperToText] = useState({});
  const [superRegex, setSuperRegex] = useState('');
  const [tempProp, setTempProp] = useState([]);

  const [showPromptModal, setShowPromptModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showClearModal, setShowClearModal] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [action, setAction] = useState(null);
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);

  const handleKeyCommand = (e) => {
    if (e.key === 'Escape') {
      setShowModal(true);
      const isNotCombinedKey = !(e.ctrlKey || e.altKey || e.shiftKey);
      if (isNotCombinedKey) {
        makeEscapeModal(true);
      }
    }
  };

  const makeEscapeModal = (showModal) => {
    setShowPromptModal(showModal);
    setShowModal(showModal);
  };

  const simpleclone = (x, doParse) => {
    if (x && doParse) return JSON.parse(JSON.stringify(x));
    else return x;
  };

  useEffect(() => {
    let tempprop = {};
    try {
      tempprop = { milestones: simpleclone(prop.milestones, true) };
    } catch (error) {
      tempprop = { milestones: simpleclone(prop.milestones) };
    }

    try {
      if (superEdit.v && superEdit.v !== -44) {
        tempprop = JSON.parse(superEdit.v);
      }
    } catch (exx) {
      console.log(exx);
    }

    setTempProp(tempprop);
    setSuperPowers(JSON.parse(superEdit.v)?.superPowers || prop.superPowers);

    if (!showPromptModal) {
      window.addEventListener('keydown', handleKeyCommand, true);
    } else {
      window.removeEventListener('keydown', handleKeyCommand, true);
    }
    setShowPromptModal(!showPromptModal);
  }, []);

  useEffect(() => {
    let defaultSuperToText = {};
    const usersSuperToText = user?.config?.super;

    if (wixSiteProperties?.description) {
      defaultSuperToText['Wix site description'] = {
        name: wixSiteProperties.description,
        editable: false,
        logo: images.WIX_ICON,
      };
    }

    _.each(config['superpowers simple'].split('\n'), (l) => {
      defaultSuperToText[l.split(':')[0]] = {
        name: l.split(':')[1].trim(),
        editable: false,
      };
    });

    if (usersSuperToText) {
      defaultSuperToText = { ...defaultSuperToText, ...usersSuperToText };
    }

    if (superRegex) {
      const searchRegex = new RegExp(superRegex.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'i');
      const superKeys = Object.keys(defaultSuperToText).filter((superkey) =>
        searchRegex.test(superkey)
      );

      const newSuperToText = {};
      superKeys.forEach((key) => {
        newSuperToText[key] = defaultSuperToText[key];
      });

      defaultSuperToText = newSuperToText;
    }

    setSuperToText(defaultSuperToText);
  }, [config, superRegex, user?.config?.super, wixSiteProperties]);

  useEffect(() => {
    if (tempProp?.superPowers) {
      shouldDisabled(tempProp?.superPowers || null);
    }
  }, [tempProp]);

  const shouldDisabled = (superPowers) => {
    // disabled save button, if list length = 0
    setDisableSaveButton(!Object.keys(superPowers)?.length > 0);
  };

  const showClearButton = () => {
    setShowClearModal(true);
  };

  const resetChosen = () => {
    setShowClearModal(false);

    // will pass the action to tablist to empty the chosen list array
    setAction('reset-super-choosen');

    // reset data on this component
    setTempProp({ superPowers: {} });
    setSuperPowers({});
  };

  return (
    <>
      <Modal
        visible={visible}
        wrapClassName={`rich-editor-price-edit rich-editor-price-edit-${prop?.language?.toLowerCase()}`}
        title={
          <div className="rich-editor-price-edit-header">
            <h3 className="title">Edit Why Me</h3>
            <Divider />
          </div>
        }
        okText="OK"
        cancelText="CLOSE"
        onCancel={() => setShowModal(true)}
        cancelButtonProps={{ disabled: savingRichEditorModal }}
        maskClosable={false}
        closable={!savingRichEditorModal}
        onOk={() => onConfirm(tempProp)}
        closeIcon={<CloseIcon />}
        footer={[
          <Col className="rich-editor-modal-footer" key="rich-editor-modal-footer">
            <Button
              className="large-btn grey-btn"
              text={<span className="above-md">CLEAR</span>}
              icon={<ResetIcon />}
              onClick={showClearButton}
            />
            <Row className="main-buttons">
              <Button className="large-btn grey-btn" text="CLOSE" onClick={onCancel} />

              <Button
                className="large-btn secondary-btn rich-editor-modal-continue"
                text={savingRichEditorModal ? 'SAVING' : 'SAVE'}
                onClick={() => onConfirm(tempProp)}
                disabled={disableSaveButton || savingRichEditorModal}
                loading={savingRichEditorModal}
              />
            </Row>
          </Col>,
        ]}>
        <Tablist
          action={action}
          tabType="super"
          superVal
          tabs={false}
          config={config}
          prop={tempProp}
          setSuperRegex={setSuperRegex}
          prelist={Object.keys(superToText).map((superTextKey) => {
            return {
              name: superTextKey,
              editable: superToText[superTextKey].editable,
              logo: superToText[superTextKey].logo,
            };
          })}
          handleState={() => {}}
          removeItem={removeItem}
          transform={(t) => {
            return superToText[t]?.name;
          }}
          saveUser={saveUser}
          onChange={(chosen, orderSave) => {
            const tempprop = tempProp;
            const newsupers = helpers.objectPairs(
              _.map(chosen['OPTION 1'].list, (x, i) => {
                if (x.super === true || !x.super) {
                  x.super = { text: superToText[x.name].name };
                }
                x.super.order = i;
                x.super.description = x.description || '';
                return [x.name, x.super || true];
              })
            );

            if (!tempprop.superPowers || !_.isEqual(tempprop.superPowers, newsupers)) {
              console.log('will save superpowers as table change (draft)', newsupers);
              tempprop.superPowers = simpleclone(newsupers);
              const newchosen = {
                'OPTION 1': {
                  list: _.map(tempprop.superPowers, (v, k) => ({
                    name: k,
                    super: {
                      text:
                        v === true
                          ? superToText[k].name
                          : v && typeof v.text === 'string'
                          ? v.text
                          : '',
                      order: v.order || 0,
                      description: v.description,
                    },
                    description: v.description,
                  })).sort((a, b) => a.super.order - b.super.order),
                },
              };
              chosen['OPTION 1'].list = newchosen['OPTION 1'].list;
            } else if (orderSave) {
              tempprop.superPowers.chosen = chosen;
            }

            // reset the action
            if (action === 'reset-super-choosen') {
              setAction('');
            }
            setTempProp(tempprop);
            setSuperPowers(newsupers);

            // disabled save button
            shouldDisabled(newsupers);
          }}
          propsChosen={
            Object.keys(superPowers).length
              ? {
                  'OPTION 1': {
                    list: _.map(superPowers, (v, k) => ({
                      name: k,
                      super: {
                        text: v && typeof v.text === 'string' ? v.text : superToText[k].name,
                        order: v.order || 0,
                      },
                      description: v.description,
                    })).sort((a, b) => a.super.order - b.super.order),
                  },
                }
              : ''
          }
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
      </Modal>
      <>
        {showModal && (
          <Popconfirm
            visible={showModal}
            title={'Do you want to exit without saving?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={
              savingRichEditorModal ? 'Saving...' : !disableSaveButton ? 'Save Changes' : ''
            }
            onCancel={() => {
              setShowModal(false);
              onCancel();
            }}
            onConfirm={() => {
              !disableSaveButton && onConfirm(tempProp);
            }}
            footer={null}
          />
        )}
        {showClearModal && (
          <Popconfirm
            visible={showClearModal}
            title={'Do you want to clear everything?'}
            maskClosable={!savingRichEditorModal}
            closable={false}
            cancelText={'Cancel'}
            confirmText={'Clear'}
            onCancel={() => {
              setShowClearModal(false);
            }}
            onConfirm={resetChosen}
            footer={null}
          />
        )}
      </>
    </>
  );
};

SuperEditModal.defaultProps = {
  savingRichEditorModal: false,
};

SuperEditModal.propTypes = {
  prop: PropTypes.instanceOf(Object).isRequired,
  config: PropTypes.instanceOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  superEdit: PropTypes.instanceOf(Object).isRequired,
  removeItem: PropTypes.func.isRequired,
  saveUser: PropTypes.func.isRequired,
  savingRichEditorModal: PropTypes.bool,
};

export default SuperEditModal;
