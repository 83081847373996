import React from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';

import Path from 'routes/path';

import '../../../Modals/SimpleModalInfo.scss';
import './NoTemplateModal.scss';

const NoTemplateModal = ({ wixEditor, templateWixEditor }) => {
  const history = useHistory();
  return (
    <Modal
      className="simple-info-modal is-editing-modal"
      centered
      visible
      footer={null}
      closeIcon={() => false}
      onCancel={() => {
        if (wixEditor || templateWixEditor) {
          window?.proposalApi?.navigateToProposals();
        } else {
          return false;
        }
      }}
      maskClosable={false}>
      <h3 className="title">Linked template not found</h3>
      <div className="divider" />

      <p className="body">
        <span>
          The linked template for this proposal has been deleted and cannot be edited. You can
          create a new proposal with a different template anytime.
        </span>
      </p>

      {!wixEditor && !templateWixEditor && (
        <div className="button-wrapper">
          <Button className="button cancel" onClick={() => history.push(Path.DASHBOARD)}>
            BACK
          </Button>
        </div>
      )}
      {(wixEditor || templateWixEditor) && (
        <div className="button-wrapper">
          <Button
            className="button cancel"
            onClick={() => window?.proposalApi?.navigateToProposals()}>
            BACK
          </Button>
        </div>
      )}
    </Modal>
  );
};

NoTemplateModal.defaultProps = {};

NoTemplateModal.propTypes = {};

export default NoTemplateModal;
