import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="24" height="16" viewBox="0 0 24 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20,14c1.1,0,2-0.9,2-2l0-10c0-1.1-0.9-2-2-2H4C2.9,0,2,0.9,2,2v10c0,1.1,0.9,2,2,2H0v2h24v-2H20z M4,2h16v10H4 V2z"
      fill="#05034D"
    />
  </svg>
);

const DesktopIcon = (props) => <Icon component={svg} {...props} />;

export default DesktopIcon;
