import React from 'react';
import Icon from '@ant-design/icons';

const svg = () => (
  <svg width="27" height="33" viewBox="0 0 27 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="13.6617" cy="7.62691" rx="7.27884" ry="7.18307" fill="#E2E3E8" />
    <path
      d="M13.5003 18.8193C7.1357 18.8193 1.86495 23.7493 0.946449 30.1794C0.789461 31.2784 1.70466 32.1832 2.81483 32.1832H24.1858C25.296 32.1832 26.2112 31.2784 26.0542 30.1794C25.1357 23.7493 19.865 18.8193 13.5003 18.8193Z"
      fill="#E2E3E8"
    />
  </svg>
);

const DefaultUserIcon = (props) => <Icon component={svg} {...props} />;

export default DefaultUserIcon;
