import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';

import CloseIcon from 'components/Icons/CloseIcon';

const ThanksSignProposalModal = ({ onCancel, configText }) => {
  return (
    <Modal
      className="simple-info-modal"
      centered
      visible
      footer={null}
      closeIcon={<CloseIcon className="close-icon" />}
      onCancel={onCancel}>
      <h3 className="title">Thanks for Trying Prospero</h3>
      <div className="divider" />

      <p className="body">
        Both you and your client will get PDF copies once the proposal is signed by the client
      </p>

      <div className="button-wrapper">
        <Button className="button cancel" onClick={onCancel}>
          {configText('Close')}
        </Button>
      </div>
    </Modal>
  );
};

ThanksSignProposalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  configText: PropTypes.func.isRequired,
};

export default ThanksSignProposalModal;
